import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CompassLogo from '../../asset/img/ic_compass_w.svg'
import { WindowSize } from '../../util'

const FooterTotalContainer = styled.div`
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.theme.colors.black};
    padding-top: 70px;
    ${WindowSize.ipad} {
        height: 120px;
        padding-top: 0;
        justify-content: center;
    }
`

const FooterLogo = styled.img`
    width: 140px;
    height: 30px;
    cursor: pointer;
    object-fit: contain;
    user-select: none;
    ${WindowSize.ipad} {
        width: 100px;
        height: 20px;
    }
`

const FooterDescription = styled.div`
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin-top: 20px;
    ${WindowSize.ipad} {
        margin-top: 14px;
        font-size: 10px;
        line-height: 16px;
    }
`

const Footer = () => {
    const { t } = useTranslation()

    return (
        <FooterTotalContainer>
            <FooterLogo src={CompassLogo} />
            <FooterDescription>{t('footer.copyright')}</FooterDescription>
        </FooterTotalContainer>
    )
}

export default Footer