import {
  ADD_WALLET,
  ADD_KLAYTN_WALLET,
  ADD_METAMASK_WALLET,
  UPDATE_STATUS,
  UPDATE_KLAYTN_STATUS,
  UPDATE_METAMASK_STATUS,
  REMOVE_WALLET,
  REMOVE_KLAYTN_WALLET,
  REMOVE_METAMASK_WALLET,
  REMOVE_ALL_WALLETS, SET_CONNECTED_WALLETS, SET_KLAYTN_WALLET, SET_METAMASK_WALLET, EXPAND_WALLET,
} from './walletsAction';


const INITIAL_STATE = {
  connectedWallets: [],
  klaytnWallet: {
    address : '',
    networkName : '',
    networkId : '',
    caraStatus : '',
    balance : '',
    chain : '',
    primary : false,
    expanded : false,
    createdTime : '',
    updatedTime : ''
  },
  metamaskWallet: {
    address : '',
    networkName : '',
    networkId : '',
    caraStatus : '',
    balance : '',
    chain : '',
    primary : false,
    expanded: false,
    createdTime : '',
    updatedTime : ''
  }
}

export default function walletsReducer(state = INITIAL_STATE, action) {
  const { type, payload } = { ...action }
  switch(type) {
    case SET_CONNECTED_WALLETS: {
      return Object.assign({}, state, {
        ...state,
        connectedWallets: payload
      })
    }
    case SET_KLAYTN_WALLET: {
      return Object.assign({}, state, {
        ...state,
        klaytnWallet: payload
      })
    }
    case SET_METAMASK_WALLET: {
      return Object.assign({}, state, {
        ...state,
        metamaskWallet: payload
      })
    }
    case ADD_WALLET: {
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      let index = -1
      if (payload.chain === 'eth' || payload.chain === 'klay') {
        payload.address = payload.address.toLowerCase()
        index = connectedWallets.findIndex((wallet) => wallet.address.toLowerCase() === payload.address)
      }else {
        index = connectedWallets.findIndex((wallet) => wallet.address === payload.address)
      }

      if (index < 0) {
        connectedWallets.push(payload)
      }
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: [
          ...connectedWallets,
        ]
      })
    }
    case ADD_KLAYTN_WALLET: {
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (localStorage.getItem('klaytnWallet') !== null && localStorage.getItem('klaytnWallet') !== '') {
        const klayStorageWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
        let index = connectedWallets.findIndex((wallet) => wallet.address === klayStorageWallet.address)
        connectedWallets.splice(index, 1)
      }
      localStorage.setItem('klaytnWallet', JSON.stringify(payload))

      connectedWallets.push(payload)
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: [
          ...connectedWallets
        ],
        klaytnWallet: Object.assign({}, state.klaytnWallet, {
          ...payload
        })
      })
    }
    case ADD_METAMASK_WALLET: {
      //console.log(state)
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (localStorage.getItem('metamaskWallet') !== null && localStorage.getItem('metamaskWallet') !== '') {
        const metaStorageWallet = JSON.parse(localStorage.getItem('metamaskWallet'))
        let index = connectedWallets.findIndex((wallet) => wallet.address === metaStorageWallet.address)
        connectedWallets.splice(index, 1)
      }
      localStorage.setItem('metamaskWallet', JSON.stringify(payload))
      connectedWallets.push(payload)
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: [
          ...connectedWallets
        ],
        metamaskWallet: Object.assign({}, state.metamaskWallet, {
          ...payload
        })
      })
    }
    case UPDATE_STATUS: {
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (connectedWallets.length === 1) {
        connectedWallets[0] = payload
      } else {
        let index = connectedWallets.findIndex((wallet) => wallet.address === payload.address)
        connectedWallets = [ ...connectedWallets.slice(0, index), payload, ...connectedWallets.slice(index+1)]
      }
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: connectedWallets
      })
    }
    case UPDATE_KLAYTN_STATUS: {
      localStorage.setItem('klaytnWallet', JSON.stringify(payload))
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (connectedWallets.length === 1) {
        connectedWallets[0] = payload
      } else {
        let index = connectedWallets.findIndex((wallet) => wallet.address === payload.address)
        connectedWallets = [ ...connectedWallets.slice(0, index), payload, ...connectedWallets.slice(index+1)]
      }
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: connectedWallets,
        klaytnWallet: Object.assign({}, state.klaytnWallet, {
          ...state.klaytnWallet,
          caraStatus: payload.caraStatus
        })
      })
    }
    case UPDATE_METAMASK_STATUS: {
      localStorage.setItem('metamaskWallet', JSON.stringify(payload))
      let connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (connectedWallets.length === 1) {
        connectedWallets[0] = payload
      } else {
        let index = connectedWallets.findIndex((wallet) => wallet.address === payload.address)
        connectedWallets = [ ...connectedWallets.slice(0, index), payload, ...connectedWallets.slice(index+1)]
      }
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: connectedWallets,
        metamaskWallet: Object.assign({}, state.metamaskWallet, {
          ...state.metamaskWallet,
          caraStatus: payload.caraStatus
        })
      })
    }
    case REMOVE_WALLET: {
      const connectedWallets = state.connectedWallets.filter(wallet => wallet.address !== payload.address)
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: state.connectedWallets.filter(wallet => wallet.address !== payload.address)
      })
    }
    case REMOVE_KLAYTN_WALLET: {
      localStorage.setItem('klaytnWallet', '')
      const connectedWallets = state.connectedWallets.filter(wallet => wallet.address !== payload.address)
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: state.connectedWallets.filter(wallet => wallet.address !== payload.address),
        klaytnWallet: {
          address : '',
          networkName : '',
          networkId : '',
          caraStatus : '',
          balance : '',
          chain : '',
          primary : false
        }
      })
    }
    case REMOVE_METAMASK_WALLET: {
      localStorage.setItem('metamaskWallet', '')
      const connectedWallets = state.connectedWallets.filter(wallet => wallet.address !== payload.address)
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: state.connectedWallets.filter(wallet => wallet.address !== payload.address),
        metamaskWallet: {
          address : '',
          networkName : '',
          networkId : '',
          caraStatus : '',
          balance : '',
          chain : '',
          primary : false
        }
      })
    }
    case REMOVE_ALL_WALLETS: {
      localStorage.setItem('connectedWallets', '[]')
      localStorage.setItem('klaytnWallet', '')
      localStorage.setItem('metamaskWallet', '')
      return Object.assign({}, state, {
        ...state,
        INITIAL_STATE
      })
    }
    case EXPAND_WALLET: {
      const connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      if (connectedWallets.length > 0) {
        const index = connectedWallets.findIndex((wallet) => wallet.address === payload.address)
        connectedWallets[index].expanded = !connectedWallets[index].expanded
      }
      localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
      return Object.assign({}, state, {
        ...state,
        connectedWallets: connectedWallets
      })

    }
    default:
      return state
  }
}