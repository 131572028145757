import {useContext, useEffect, useState} from "react";
import QRImage from '../../asset/img/QRImage.png'
import styled, { keyframes, css } from 'styled-components'
import CompassLogo from '../../asset/img/ic_compass_w.svg'
import { WindowSize } from '../../util'
import {useTranslation} from "react-i18next";
import axios from "axios";
import {AuthContext} from "../../contexts";
import Switch from "@material-ui/core/Switch";

const PageContainer = styled.div({
    padding: '150px 170px',
    display: 'flex',
    width: '100%',
    [WindowSize.mobile]: {
        display: 'block',
        padding: '92px 24px',
    }
})

const QrContainer = styled.div({
    height: '300px',
    width: '300px',
    borderRadius: '12px',
    backgroundColor: '#653EFF',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    padding: '14px 21px',
    [WindowSize.mobile]: {
        height: '180px',
        width: '180px',
        borderRadius: '4px',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
})

const QrHeading = styled.div({
    fontSize: '19px',
    fontWeight: 'bold',
    lineHeight: '30px',
    [WindowSize.mobile]: {
        fontSize: '12px',
        lineHeight: '18px',
    }
})

const QrSubHeading = styled.div({
    marginTop: '2px',
    fontSize: '11px',
    lineHeight: '16px',
    [WindowSize.mobile]: {
        fontSize: '6px',
        lineHeight: '9px',
    }
})

const ImageContainer = styled.div({
    marginTop: '40px',
    width: '100%',
    padding: '0 68px',
    [WindowSize.mobile]: {
        marginTop: '20px',
        padding: '0 35px',
    }
})

const QrImage = styled.img({
    height: '120px',
    width: '120px',
    color: 'white',
    //marginTop: '40px',
    textAlign: 'center',
    [WindowSize.mobile]: {
        height: '72px',
        width: '72px',
    }
})

const QrFooter = styled.div({
    display: 'flex',
    width: '100%',
    marginTop: '40px',
    [WindowSize.mobile]: {
        marginTop: '24px',
    }
})

const QrFooterNumber = styled.div({
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'left',
    width: '50%',
    [WindowSize.mobile]: {
        fontSize: '6px',
        lineHeight: '9px',
    }
})

const QrFooterLogo = styled.img({
    height: '18px',
    width: '50%',
    paddingLeft: '40px',
    [WindowSize.mobile]: {
        height: '11px',
        paddingLeft: '24px',
    }
})

const NFTContainer = styled.div({
    marginLeft: '80px',
    width: '100%',
    fontFamily: 'Poppins',
    [WindowSize.mobile]: {
        marginLeft: '0',
        marginTop: '20px',
    }
})

const TitleContainer = styled.div({
    fontSize: '24px',
    lineHeight: '35px',
    [WindowSize.mobile]: {
        fontSize: '16px',
        lineHeight: '25px',
        wordWrap: 'break-word',
    }
})

const BlockContainer = styled.div({
    marginTop: '50px',
    color: '#868E96',
    [WindowSize.mobile]: {
        marginTop:  '30px',
    }
})

const SubHeadingContainer = styled.div((props) => ({
    display: props.switch ? 'flex' : 'block',
    fontSize: '18px',
    paddingBottom: '15px',
    width: '100%',
    borderBottom: '1px solid #868E96',
    [WindowSize.mobile]: {
        fontSize: '12px',
        fontWeight: 'bold',
        lineHeight: '18px',
        paddingBottom: '10px',
    }
}))

const SubHeadingText = styled.div({
    width: '70%'
})

const SwitchContainer = styled.div({
    width: '30%',
    textAlign: 'right'
})

const DetailRowContainer = styled.div({
    width: '100%',
    paddingTop: '20px',
    display: 'flex',
    fontSize: '15px',
    lineHeight: '23px',
    [WindowSize.mobile]: {
        paddingTop: '10px',
        fontSize: '10px',
        lineHeight: '16px',
    }
})

const DetailContainer = styled.div((props) => ({
    width: props.isKey ? '20%' : '80%',
    color: props.isBlack ? '#000' : props.isBlue ? '#4C6EF5' : props.isGreen? '#0CA678' : '#868E96',
    textAlign: 'left',
    [WindowSize.mobile]: {
        width: props.isKey ? '25%' : '75%',
        wordWrap: 'break-word',
    }
}))

const DEFAULT_NFT = {
    qrImage: '',
    id: '',
    created: '',
    wallet: ''
}

const DEFAULT_IDENTITY = {
    id: '',
    username: '',
    created: '',
    updated: '',
    email: '',
    country: '',
    address: '',
    firstname: '',
    lastname: '',
    kyc: false,
}

const DEFAULT_WALLET = {
    blockchain: '',
    address: '',
    caraStatus: '',
    caraPending: '',
    caraIndexed: '',
    id: '',
}

const IdentityCertificate = () => {
    const { t } = useTranslation()
    const [show, setShow] = useState(false)
    const { user, handleError } = useContext(AuthContext)
    const [identity, setIdentity] = useState(DEFAULT_IDENTITY)
    const [nft, setNft] = useState(DEFAULT_NFT)
    const [wallets, setWallets] = useState([])
    const [primaryWallet, setPrimaryWallet] = useState(DEFAULT_WALLET)
    const [qr, setQr] = useState('')
    const userStorage = JSON.parse(localStorage.getItem('user-data'))

    useEffect( () => {
        async function fetchData() {
            try {
                 //console.log("Token:"+localStorage.getItem('token'))
                const identityResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/nft/meta/${userStorage.tokenId}`, {},
                    { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                // console.log(identityResponse.data)
                let identity =DEFAULT_IDENTITY
                if (identityResponse.data) {
                    identity.id = show ? identityResponse.data.userId : identityResponse.data.userId.substring(0,1) + '************'
                    identity.email = show ? identityResponse.data.email : identityResponse.data.email.substring(0,1) + '******@******'
                    identity.firstname = show ? identityResponse.data.firstName : identityResponse.data.firstName.substring(0,1) + '************'
                    identity.lastname = show ? identityResponse.data.lastName : identityResponse.data.lastName.substring(0,1) + '************'
                    identity.address = show ? identityResponse.data.registeredAddress : identityResponse.data.registeredAddress.substring(0,1) + '*********************'
                    identity.country = show ? identityResponse.data.country : identityResponse.data.country.substring(0,1) + '*********'
                    identity.created = identityResponse.data.createdDate ? identityResponse.data.createdDate.split('T')[0] + ' ' + identityResponse.data.createdDate.split('T')[1].substring(0, 5) : ''
                    identity.updated = identityResponse.data.updatedDate ? identityResponse.data.updatedDate.split('T')[0] + ' ' + identityResponse.data.updatedDate.split('T')[1].substring(0, 5) : ''
                }
                setIdentity(identity)
            } catch (error) {
                handleError(error)
            }
        }
        async function fetchNFT() {
            try {
                const nftResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/nft/profile/${userStorage._id}`,
                    { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                // console.log(show)
                let nft = DEFAULT_NFT
                let qr = ""
                let wallets = []
                let id = identity
                if (nftResponse.data.nft) {
                    nft.wallet = nftResponse.data.nft.primaryWalletId
                    // nft.qrImage = nftResponse.data.nft.qrImage
                    qr = nftResponse.data.nft.qrImage
                    nft.id = nftResponse.data.nft.nftId
                    nft.created = nftResponse.data.nft.createdAt
                    id.kyc = nftResponse.data.user.isKycVerified
                    id.username = show ? nftResponse.data.user.username : nftResponse.data.user.username.substring(0,1) + '**********'
                }
                if (nftResponse.data.wallets.length > 0) {
                    for (let i =0;i<nftResponse.data.wallets.length;i++) {
                        let w= nftResponse.data.wallets[i]
                        //console.log("W:" + w)
                        if (w._id === nftResponse.data.nft.primaryWalletId) {
                            let primary = DEFAULT_WALLET
                            primary.caraStatus = w.caraStatus
                            primary.id = w._id
                            primary.caraPending = w.caraPending
                            primary.address = w.address
                            primary.blockchain = w.blockchain
                            primary.caraIndexed = w.caraIndexed
                            setPrimaryWallet(primary)
                        }
                        wallets.push({
                            address: w.address,
                            id: w._id,
                            caraStatus: w.caraStatus,
                            caraIndexed: w.caraIndexed,
                            caraPending: w.caraPending,
                            blockchain: w.blockchain
                        })
                        // console.log(wallets)
                    }
                }
                // console.log(wallets)
                setNft(nft)
                setIdentity(id)
                setQr(qr)
                setWallets(wallets)
            } catch (error) {
                handleError(error)
            }
        }

        async function fetchQR() {
            try {
                const qrResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/nft/qr-image/${nft.id}`, { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                // console.log("QR:" + qrResponse.data)
                let qr=""
                if (qrResponse.data) {
                    qr=qrResponse.data.url
                }
               setQr(qr)
            } catch (error) {
                handleError(error)
            }
        }

        fetchData().then(fetchNFT).then(fetchQR)
    }, [show])



    const handleChange = event => {
        setShow(event.target.checked);
    }

    return (
        <PageContainer>
            <QrContainer>
                <QrHeading>
                    {t('nft.identity')}
                </QrHeading>
                <QrSubHeading>
                    {t('nft.qr_subtext')}
                </QrSubHeading>
                <ImageContainer>
                    <QrImage src={qr} />
                </ImageContainer>

                <QrFooter>
                    <QrFooterNumber>
                        {nft.id}
                    </QrFooterNumber>
                    <QrFooterLogo src={CompassLogo} />
                </QrFooter>
            </QrContainer>
            <NFTContainer>
                <TitleContainer>
                    {nft.id? nft.id : ""}
                </TitleContainer>
                <BlockContainer>

                    <SubHeadingContainer switch={true}>
                        <SubHeadingText>
                            {t('nft.user.head')}
                        </SubHeadingText>
                        <SwitchContainer>
                            <Switch checked={show} onChange={handleChange} color="primary" name="checked" />
                        </SwitchContainer>


                    </SubHeadingContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.id')}</DetailContainer>
                        <DetailContainer>{identity.id}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.email')}</DetailContainer>
                        <DetailContainer>{identity.email}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('profile.legal_name')}</DetailContainer>
                        <DetailContainer>{identity.username}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.first_name')}</DetailContainer>
                        <DetailContainer>{identity.firstname}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.last_name')}</DetailContainer>
                        <DetailContainer>{identity.lastname}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.address')}</DetailContainer>
                        <DetailContainer>{identity.address}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.residence')}</DetailContainer>
                        <DetailContainer>{identity.country}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.created')}</DetailContainer>
                        <DetailContainer>{identity.created}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.updated')}</DetailContainer>
                        <DetailContainer>{identity.updated}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.user.kyc')}</DetailContainer>
                        <DetailContainer isBlue>{identity.kyc ? t('nft.user.verified') : "Not Verified"}</DetailContainer>
                    </DetailRowContainer>
                </BlockContainer>
                <BlockContainer>
                    <SubHeadingContainer>
                        {t('nft.details')}
                    </SubHeadingContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.nft_id')}</DetailContainer>
                        <DetailContainer isBlack>{nft.id}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.issued_wallet')}</DetailContainer>
                        <DetailContainer isBlack>{primaryWallet.address}</DetailContainer>
                    </DetailRowContainer>
                    <DetailRowContainer>
                        <DetailContainer isKey>{t('nft.issued_at')}</DetailContainer>
                        <DetailContainer>{nft.created}</DetailContainer>
                    </DetailRowContainer>
                </BlockContainer>
                <BlockContainer>
                    <SubHeadingContainer>
                        {t('nft.wallet_list')}
                    </SubHeadingContainer>
                    {
                        wallets.map((wallet, index) => {
                          return (
                              <DetailRowContainer>
                                  <DetailContainer>{wallet.address}</DetailContainer>
                                  {
                                      wallet.id === primaryWallet.id ?
                                          <DetailContainer isKey isBlue>{t('nft.issued')}</DetailContainer>
                                          :
                                          <DetailContainer isKey isGreen>{t('nft.user.verified')}</DetailContainer>
                                  }
                              </DetailRowContainer>
                          )
                        })
                    }
                </BlockContainer>

            </NFTContainer>
        </PageContainer>


    )
}
export default IdentityCertificate