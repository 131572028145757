import React from 'react';
import styled from 'styled-components';

const InputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelText = styled.div((props) => ({
    color: props. theme.colors.gray1,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '6px 0 8px',
    display: 'flex',
    flexDirection: 'row',
}))

const InputContainer = styled.div((props) => ({
    height: '41px',
    border: '1px solid',
    borderColor: props.valid ? props.theme.colors.gray3 : props.theme.colors.red,
    borderRadius: '3px',
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: props.disableInput ? props.theme.colors.gray3 : 'transparent',
    marginBottom: props.valid ? '10px' : '0',
}))

const InputDiv = styled.input((props) => ({
    color: props.theme.colors.gray1,
    fontFamily: 'Poppins',
    fontSize: '15px',
    lineHeight: '23px',
    border: 'none',
    outline: 'none',
    width: '100%',
    backgroundColor: 'transparent'
}))

const ErrorStatement = styled.div`
    color: ${props => props.theme.colors.warning};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
`

const InputField = ({ value, updateValue, type, placeholder, label, error, valid, disableInput }) => {

    return (
        <InputFieldContainer>
            <LabelText>
                {label}
            </LabelText>
            <InputContainer valid={valid} disableInput={disableInput}>
                <InputDiv
                    disabled={disableInput}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => updateValue(e.target.value)}
                />
            </InputContainer>
            {!valid && error.length > 0 && (
                <ErrorStatement>{error}</ErrorStatement>
            )}
        </InputFieldContainer>
    )
};

export default InputField;
