import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, KaikasContext } from '../../contexts';
import { useWindowSize } from '../../hooks/useWindowSize';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Container = styled.div(()=>({
  height: '100%',
  width: '100%',
  paddingLeft: '10%',
  paddingRight: '10%',
  //padding: '40%',
  // paddingLeft: '20%',
  textAlign: 'center',
  marginTop: '20%',

  fontFamily: 'Poppins',
}))

const PageTitle = styled(Container)(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '12px'
}))

const PageInstructions = styled.div(() => ({
  fontSize: '16px',
  marginBottom: '6px'
}))

const Button = styled.div(() => ({
  boxSizing: 'border-box',
  margin: '40px 10%',
  height: '49px',
  border: '1px solid #364FC7',
  borderRadius: '6px',
  backgroundColor: '#364FC7',
  color: '#FFFFFF',
  fontFamily: 'Poppins',
  fontSize: '16px',
  letterSpacing: '0',
  lineHeight: '25px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  userSelect: 'none'
}))

const ZignSecInstructions = () => {

  const [status, setStatus] = useState('failed')
  const { t } = useTranslation()
  const history = useHistory()

  const handleButtonClick = () => {
    window.open('/zignsec-verification')
    // history.push('dashboard')
  }

  useEffect(() => {
    const searchParams = window.location.search
    const params = new URLSearchParams(searchParams)
    if (params.get('status') === 'success') {
      setStatus('success')
    }
  }, []);

  return (
    <Container>
      <PageTitle>{t('zignsec.heading')}</PageTitle>
      <PageInstructions>{t('zignsec.instruction1')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction2')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction3')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction4')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction5')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction6')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction7')}</PageInstructions>
      <PageInstructions>{t('zignsec.instruction8')}</PageInstructions>
      <a href='/dashboard'>
        <Button onClick={ handleButtonClick }>{t('zignsec.button')}</Button>
      </a>

    </Container>
  )
};

export default ZignSecInstructions;