import { useState, useEffect, useRef, useContext } from 'react'
import caver from '../util/caver'
import BigNumber from 'bignumber.js'
import Axios from 'axios'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ERROR_RESPONSE, ERROR_TEXT } from '../util';
import { WalletActions } from '../reducers/wallets/walletsAction';
import { CheckKlaytnWalletStatus, CheckWalletStatus, DisconnectKlaytnWallet } from '../util/walletUtils';
import { useSelector } from 'react-redux';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';



const NEW_WALLET = {
  address : '',
  networkName : '',
  networkId : '',
  caraStatus : '',
  balance : '',
  chain : '',
  primary : false,
  expanded: false,
  createdTime : '',
  updatedTime : ''
}
// Hook
export const useWeb3Plugin = () => {


  const [walletAddrList, setWalletAddrList] = useState([])
  const [connecting, setConnecting] = useState(false)
  const [manualWallet, setManualWallet] = useState('')
  //const [walletAddr, setWalletAddr] = useState('')
  const klaytnWalletAddr = useSelector((state) => state.wallets.klaytnWallet.address)
  const metamaskWalletAddr = useSelector((state) => state.wallets.metamaskWallet.address)
  const [ethProvider, setEthProvider] = useState({})
  //const klaytnWallet = useSelector(state => state.wallets.klaytnWallet)
  //console.log(walletAddr)
  const [networkName, setNetworkName] = useState('')
  const [networkId, setNetworkId] = useState('')
  const [web3ConnectProvider, setWeb3ConnectProvider] = useState()
  const [walletBalance, setWalletBalance] = useState()
  const walletAddrRef = useRef(null)
  const networkNameRef = useRef(null)
  const networkIdRef = useRef(null)
  const walletBalanceRef = useRef(null)
  const web3ConnectProviderRef = useRef(null)
  const { klaytn, ethereum } = window
  const { provider, setProvider} = useState({})
  //console.log(ethereum)
  const { AddKlaytnWallet, RemoveKlaytnWallet, SetConnectedWallets, SetKlaytnWallet, AddMetamaskWallet, SetMetamaskWallet, RemoveMetamaskWallet, UpdateKlaytnWalletStatus, UpdateMetamaskWalletStatus, ExpandWallet, AddWallet, RemoveWallet, UpdateWalletStatus } = WalletActions()
  let location = useLocation()
  //walletAddrRef.current = walletAddr
  networkNameRef.current = networkName
  networkIdRef.current = networkId
  walletBalanceRef.current = walletBalance
  web3ConnectProviderRef.current = web3ConnectProvider
  const userStorage = JSON.parse(localStorage.getItem('user-data'))

  useEffect(() => {

    checkValid(klaytnWalletAddr).then()
  }, [klaytnWalletAddr])


  useEffect(() => {

    checkValid(metamaskWalletAddr).then()
  }, [metamaskWalletAddr])


  useEffect(() => {
    checkValid(manualWallet).then()
  }, [manualWallet])

  // useEffect(() => {
  //     async function enableKlaytn(){
  //         await klaytn.enable()
  //     }
  //     enableKlaytn().then(()=>{console.log("Enabled Klaytn")})
  // }, [])


  const checkValid = async (wallet) => {
    try {
      if (location.pathname === '/dashboard' && metamaskWalletAddr) {
        const validResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/wallet/verify/${wallet}`,
          { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
        if (validResponse.data && validResponse.data.walletIsValid) {
          toast.success('Wallet connected successfully', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast.error('This Wallet is associated with another user. Please connect your wallet.', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          })
        }
      }

    } catch (error) {
      await handleError(error)
    }


  }

  const handleError = async  (error) => {
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }


  }

  const addManualWallet = async (address, chain, name) => {
    try {
      const wallet = NEW_WALLET
      wallet.address = address
      wallet.chain = chain
      wallet.networkName = name
      wallet.caraStatus = await CheckWalletStatus(address, chain)
      setManualWallet(address)
      if (address && chain) {
        AddWallet(wallet)
      }
    } catch (error) {
      handleError(error).then()
    }
  }

  const connectMetamaskWallet = async () => {
    try {
      setConnecting(true)
      // if (walletAddr && walletAddr.length > 0) {
      //   setConnecting(false)
      //   return
      // }
      await enableMetamask().then(loadMetamaskAccountInfo)
    } catch (error) {

    } finally {
      setConnecting(false)
    }
  }

  const connectKlaytnWallet = async () => {
    try {

      setConnecting(true)
      // if (walletAddr && walletAddr.length > 0) {
      //   setConnecting(false)
      //   return
      // }
      await enableKlaytn().then(loadKlaytnAccountInfo)
    } catch (error) {
      console.log('main', error)
    } finally {
      setConnecting(false)
    }
  }

  const loadMetamaskAccountInfo = async() => {
    await enableMetamask()
    if (ethProvider) {
      try {
        // ethProvider
        //   .request({ method: 'eth_requestAccounts' })
        //   .then(setMetamaskAccountInfo)
        //   .catch((err) => {
        //     if (err.code === 4001) {
        //       // EIP-1193 userRejectedRequest error
        //       // If this happens, the user rejected the connection request.
        //       console.log('Please connect to MetaMask.');
        //     } else {
        //       console.error(err);
        //     }
        //   });
        await setMetamaskAccountInfo(ethProvider)
      } catch (error) {
        console.log(error)
        console.log('User denied account access')
      }
    }
  }

  const loadKlaytnAccountInfo = async () => {
    // const { klaytn } = window
    // console.log(klaytn)
    await enableKlaytn()
    if (klaytn) {
      try {
        // await klaytn.enable()
        await setKlaytnAccountInfo(klaytn)
        klaytn.on('accountsChanged', () => setKlaytnAccountInfo(klaytn))
      } catch (error) {
        console.log('User denied account access')
      }
    } else {
      console.log('Non-Kaikas browser detected. You should consider trying Kaikas!')
    }
  }

  const setKlaytnAccountInfo = async () => {
    try{
      if (klaytn === undefined) return
      const account = klaytn.selectedAddress
      let balance = 0
      if (account) {
        balance = (parseInt(await caver.klay.getBalance(account))/1000000000000000000).toString()
      }
      const klaytnWallet = NEW_WALLET
      klaytnWallet.address = account
      klaytnWallet.balance = balance
      klaytnWallet.chain = 'klay'
      klaytnWallet.networkId = klaytn.networkVersion
      if (klaytn.networkVersion === 1001) {
        klaytnWallet.networkName = 'Baobab Test Network'
        // sessionStorage.setItem('network', 'Baobab Test Network')
      } else if (klaytn.networkVersion === 8217) {
        klaytnWallet.networkName = 'Cypress Main Network'
        // sessionStorage.setItem('network', 'Cypress Main Network')
      }
      klaytnWallet.caraStatus = await CheckWalletStatus(account, 'klay')
      // this.setState({ network: klaytn.networkVersion })
      klaytn.on('accountsChanged', () => setKlaytnAccountInfo(klaytn.networkVersion))
      klaytn.on('networkChanged', () => setKlaytnAccountInfo(klaytn.networkVersion))
      if (localStorage.getItem('klaytnWallet') !== null && localStorage.getItem('klaytnWallet') !== '') {
        const klayStorageWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
        if (klayStorageWallet.address !== account) {
          AddKlaytnWallet(klaytnWallet)
        }
      } else {
        AddKlaytnWallet(klaytnWallet)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const checkEthNetwork = (chainId) => {
    switch (chainId) {
      case '0x1':
        return 'Ethereum Mainnet'
      case '0x3':
        return 'Ropsten Testnet'
      case '0x4':
        return 'Rinkeby Testnet'
      case '0x5':
        return 'Goerli Testnet'
      case '0x2a':
        return 'Kovan Testnet'
    }
  }

  const setMetamaskAccountInfo = async () => {
    let currentAccount = null

    if (ethProvider === undefined) return
    const web3 = new Web3(ethereum)
    currentAccount = ethereum.selectedAddress
    const metamaskWallet = NEW_WALLET
    let balance = 0
    if (currentAccount) {
      balance = (parseInt(web3.eth.getBalance(currentAccount).toString())/1000000000000000000).toString()
    }

    metamaskWallet.address = currentAccount
    metamaskWallet.chain = 'eth'
    metamaskWallet.networkName = checkEthNetwork(ethereum.chainId)
    metamaskWallet.networkId = ethereum.chainId
    metamaskWallet.caraStatus = await CheckWalletStatus(currentAccount, 'eth')
    metamaskWallet.balance = balance
    ethereum.on('accountsChanged', () => setMetamaskAccountInfo())
    ethereum.on('chainChanged', () => setMetamaskAccountInfo())
    if (localStorage.getItem('metamaskWallet') !== null && localStorage.getItem('metamaskWallet') !== '') {
      const metaStorageWallet = JSON.parse(localStorage.getItem('metamaskWallet'))
      if (metaStorageWallet.address !== currentAccount) {
        AddMetamaskWallet(metamaskWallet)
      }
    } else {
      AddMetamaskWallet(metamaskWallet)
    }

   // const accounts = ethereum.request({ method: 'eth_accounts' })
    //console.log(currentAccount)
  }

  const enableKlaytn = async () => {
    await klaytn.enable()
  }

  const enableMetamask = async () => {
    const provider = await detectEthereumProvider();
    await ethereum.enable()
    if (provider !== ethereum) {
      console.error('Do you have multiple wallets installed?')
    } else {
      setEthProvider(provider)
    }
  }



  const disconnectKlaytnWallet = async() => {
    const klaytnWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
    RemoveKlaytnWallet(klaytnWallet)
  }

  const disconnectMetamaskWallet = async() => {
    const metamaskWallet = JSON.parse(localStorage.getItem('metamaskWallet'))
    RemoveMetamaskWallet(metamaskWallet)
  }

  const setWalletsFromStorage = () => {
    if (localStorage.getItem('connectedWallets') !== null && localStorage.getItem('connectedWallets') !== ''){
      const connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
      SetConnectedWallets(connectedWallets)
    }
    if (localStorage.getItem('klaytnWallet') !== null && localStorage.getItem('klaytnWallet') !== '') {
      const klaytnWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
      SetKlaytnWallet(klaytnWallet)
    }
    if (localStorage.getItem('metamaskWallet') !== null && localStorage.getItem('metamaskWallet') !== '') {
      const metamaskWallet = JSON.parse(localStorage.getItem('metamaskWallet'))
      SetMetamaskWallet(metamaskWallet)
    }
  }

  const updateWalletStatus = async (wallet) => {
    if (wallet.chain === 'klay') {
      if (localStorage.getItem('klaytnWallet') !== null && localStorage.getItem('klaytnWallet') !== '') {
        const klaytnWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
        if (klaytnWallet.address.toLowerCase() === wallet.address.toLowerCase()) {
          klaytnWallet.caraStatus = wallet.caraStatus
          UpdateKlaytnWalletStatus(wallet)
        } else {
          UpdateWalletStatus(wallet)
        }

      } else {
       UpdateWalletStatus(wallet)
      }
    } else if (wallet.chain === 'eth') {
      if (localStorage.getItem('metamaskWallet') !== null && localStorage.getItem('metamaskWallet') !== '') {
        const metamaskWallet = JSON.parse(localStorage.getItem('metamaskWallet'))
        if (metamaskWallet.address.toLowerCase() === wallet.address.toLowerCase()) {
          metamaskWallet.caraStatus = wallet.caraStatus
          UpdateMetamaskWalletStatus(wallet)
        } else {
          UpdateWalletStatus(wallet)
        }
      } else {
        UpdateWalletStatus(wallet)
      }
    } else {
      UpdateWalletStatus(wallet)
    }
  }

  const expandWallet = (wallet) => {
    ExpandWallet(wallet)
  }

  const removeWallet = (wallet) => {
    RemoveWallet(wallet)
  }


  return {
    connecting,
    connectKlaytnWallet,
    disconnectKlaytnWallet,
    setWalletsFromStorage,
    connectMetamaskWallet,
    disconnectMetamaskWallet,
    updateWalletStatus,
    expandWallet,
    addManualWallet,
    removeWallet
  }
}