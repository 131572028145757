import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {useTranslation} from "react-i18next";

import { countries } from '../../util/country';
import { language } from '../../util/language';

const SelectFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelText = styled.div((props) => ({
    color: props.theme.colors.gray1,
    fontFamily: 'Poppins',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '21px',
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'row',
}))

const InputContainer = styled.div((props) => ({
    height: '41px',
    width: props.width ? props.width : '201px',
    border: '1px solid',
    borderColor: props.valid ? props.theme.colors.boxShadow : props.theme.colors.red,
    borderRadius: '3px',
    fontFamily: 'Poppins',
    fontSize: '15px',
    lineHeight: '23px',
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: props.valid ? '10px' : '0',
}))

const ErrorStatement = styled.div`
    color: ${props => props.theme.colors.warning};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
`

const useStyles = makeStyles((theme) => ({
    selectEmpty: {
          fontFamily: 'Poppins',
          fontSize: '15px',
          lineHeight: '23px',
          marginTop: 0,
          background: '${props => props.theme.colors.transparent}',
          width: '100%',
          "&::before": {
            border: 'none',
            transition: 'none',
            content: 'none',
            background: '${props => props.theme.colors.transparent}'
          },
          "&::after": {
            border: 'none',
            transition: 'none',
          },
          "&::hover": {
            border: 'none',
          },
          "&::focus": {
            border: 'none',
          },
          "&::active": {
            border: 'none',
          }
        },
}));

const SelectField = ({ value, updateValue, type, placeholder, label, error, valid, data, width }) => {
    const classes = useStyles();
    const { t } = useTranslation()
   // const data = label.includes(t('profile.language')) ? language : countries;

    return (
        <SelectFieldContainer>
            <LabelText>
                {label}
            </LabelText>
            <InputContainer valid={valid} width={width}>
                <Select
                    value={value}
                    onChange={(e) => updateValue(e.target.value)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                  {
                    label && label.includes(t('profile.language')) ?
                      <>
                      </>
                      :
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                  }

                    {data.map((item) => (
                      label && label.includes(t('profile.language')) ?
                        <MenuItem key={item.name} value={item.name}>{t('profile.'+item.i18n)}</MenuItem>
                        :
                        <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
                    ))}
                </Select>
            </InputContainer>
            {!valid && error.length > 0 && (
                <ErrorStatement>{error}</ErrorStatement>
            )}
        </SelectFieldContainer>
    )
};

export default SelectField;

