import React from 'react';
import styled from 'styled-components';

import DownImg from '../../asset/img/ic_arrow_down_b.svg'
import { tokenList } from '../../util';

const AssetSelectorContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.margin || '0'};
    height: 52px;
    border-radius: 52px;
    padding: 6px 14px 6px 6px;
    background: #FFF;
    width: 128px;
    cursor: pointer;
`

const AssetIcon = styled.img`
    width: 40px;
    height: 40px;
`

const TokenName = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin-left: auto;
    margin-right: 4px;
`

const DownIcon = styled.img`
    width: 20px;
    height: 20px;
`

const AssetSelector = ({ token, margin, onClick }) => {
    return (
        <AssetSelectorContainer margin={margin} onClick={onClick}>
            <AssetIcon src={tokenList[token].icon} />
            <TokenName>{token}</TokenName>
            <DownIcon src={DownImg} />
        </AssetSelectorContainer>
    )
};

export default AssetSelector;
