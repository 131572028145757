import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { useWindowSize } from '../../hooks/useWindowSize'
import {AuthContext, KaikasContext, MetamaskContext} from '../../contexts';
import axios from 'axios';

const ZignSecContainer = styled.div`
    //padding: 0 100px;
    position: absolute;
    top: ${props => props.isMobile ? '72px' : '80px'};
    left: 0;
    right: 0;
    // margin-top: 100px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 166px);
`

const FormContainer = styled.div`
    flex: 1;
`

const ZignSec = () => {
    const { updateUser, currentTheme } = useContext(AuthContext)
    // const { connectWallet } = useContext()
    const { isMobile, isTablet } = useWindowSize()
    const [url, setUrl] = useState('')
    const user = JSON.parse(localStorage.getItem('user-data'))

    useEffect(() => {
        const lastReferrer = (document.referrer || '').split('/').pop();
        if (lastReferrer === 'dashboard') {
            // connectWallet();
        }
        try {
            async function fetchZignSecURL() {
                const urlResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/zignsec-url?userId=${user._id}`, {
                    headers: {
                        'Content-Type': 'Application/json',
                        Authorization: `Bearer ${user.authToken}`
                    }
                })
                // console.log(kycResponse.data)
                let urlDetails = ''
                if (urlResponse.data && urlResponse.data.status === 1) {
                    urlDetails = urlResponse.data.sessionUrl
                }
                setUrl(urlDetails)
            }
            fetchZignSecURL().then()
        }catch (error) {

        }

    }, []);
    return (
        <ZignSecContainer isMobile={isMobile}>
            <FormContainer>
                <iframe
                    frameBorder="0" width="100%" height="100%" allowFullScreen
                    //src={`${process.env.REACT_APP_ZIGNSEC_BASE}webhook=${process.env.REACT_APP_ZIGNSEC_WEBHOOK}?id=${user._id}&redirect_success=${process.env.REACT_APP_FRONTEND_URL}/zignsec-complete?status=success&redirect_failure=${process.env.REACT_APP_FRONTEND_URL}/zignsec-complete?status=failed`} />
                    src={url} />
            </FormContainer>
        </ZignSecContainer>
    )
};

export default ZignSec;