import React, { useContext, useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components'
import {
  useHistory, useLocation,
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { AuthContext, KaikasContext, Web3PluginContext } from '../../contexts';

import CompassLogo from '../../asset/img/ic_compass_w.svg'
import CompassLogoB from '../../asset/img/ic_compass_p.svg'
import userIcon from '../../asset/img/ic_account.svg'
import DayLogo from '../../asset/img/ic_daymode.svg'
import NightLogo from '../../asset/img/ic_nightmode.svg'
import DownBlack from '../../asset/img/ic_arrow_down_b.svg'
import DownWhite from '../../asset/img/ic_arrow_down_w.svg'
import RightWhite from '../../asset/img/ic_arrow_right.svg'
import MenuWhite from '../../asset/img/ic_menu_w.svg'
import MenuBlack from '../../asset/img/ic_menu_b.svg'
import CloseWhite from '../../asset/img/ic_close_w.svg'
import WalletImg from '../../asset/img/wallet_icon.png'
import { truncate, WindowSize } from '../../util'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useTranslation } from 'react-i18next'

const HeaderTotalContainer = styled.div`
    display: ${props => props.disabled ? 'none' : 'block'};
    height: 80px;
    overflow-y: visible;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    ${WindowSize.ipad || WindowSize.mobile} {
        height: 72px;
    }
`

const HeaderContainer = styled.div`
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-bottom: 1px solid #DEE2E6;
    ${WindowSize.ipad || WindowSize.mobile} {
        height: 72px;
    }
`

const HeaderInsideContainer = styled.div`
    height: 80px;
    display: flex;
    background: transparent;
    flex-direction: row;
    align-items: center;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    ${WindowSize.ipad ||WindowSize.mobile} {
        height: 72px;
        padding: 0 24px;
    }
`

const MenuBtn = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    user-select: none;
`

const HeaderLogo = styled.img`
    width: 112px;
    height: 24px;
    margin-right: 25px;
    cursor: pointer;
    user-select: none;
    ${WindowSize.ipad} {
        height: 24px;
        width: 112px;
    }
`

const HeaderLeftBtn = styled.div`
    padding: 10px 20px;
    color: ${props => props.theme.colors.gray2};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    cursor: pointer;
    user-select: none;
    &:hover {
        color: ${props => props.whiteBg};
    }
`

const MiddleSeparator = styled.div`
    display: flex;
    flex: 1;
`

const HeaderRightTag = styled.div`
    padding: 10px 5px;
    color: ${props => props.disabled ? props.theme.colors.gray4 : props.hoverBg};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    cursor: pointer;
    user-select: none;
    &:hover {
        color: ${props => props.whiteBg};
    }
`

const HeaderRoundButton = styled.div((props) => ({
    height: '40px',
    width: '120px',
    marginLeft: '25px',
    borderRadius: '20px',
    backgroundColor: props.pinkColor,
    color: props.pinkBg,
    fontFamily: 'Poppins',
    fontSize: '11px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '17px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ['&:hover']: {
        backgroundColor: props.normal ? props.theme.colors.whiteHover: props.theme.colors.pinkHover
    }
}))

const HeaderRightBtn = styled.div((props) => ({
    border: props.border ? `1px solid ${props.theme.currentTheme === 'light' ? props.theme.colors.pink : props.theme.colors.white}` : 'none',
    backgroundColor: props.border ? props.theme.currentTheme === 'light' ? props.theme.colors.pink : props.theme.colors.white : 'none',
    borderRadius: '3px',
    height: '32px',
    padding: '7px 15px',
    color:  props.border ? props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.blueButton : props.theme.colors.blueButton,
    marginRight: props.rightMargin ? '10px' : '0',
    fontFamily: 'Poppins',
    fontSize: '12px',
    letterSpacing: '0',
    lineHeight: '18px',
    cursor: 'pointer',
    userSelect: 'none',
    [WindowSize.ipad]: {
        padding: '8px 8px',
        marginRight: props.rightMargin ? '4px' : '0',
        fontSize: '11px',
        lineHeight: '15px',
    },
}))

const DayNightImg = styled.img`
    height: 20px;
    width: 20px;
    margin: 0 22px 0 13px;
    cursor: pointer;
`

const AvatarContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-left: 22px;
`

const Avatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    // background-color: ${props => props.theme.colors.blueButton};
`

const DropDownIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 8px;
`

const WalletAddrContainer = styled.div`
    height: 32px;
    border-radius: 3px;
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.white2 : props.theme.colors.walletBg};
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-left: 8px;
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.black1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
`

const WalletIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 20px;
`

const MenuContainer = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    // height: 192px;
    width: 176px;
    border-radius: 6px;
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.walletBg};
    box-shadow: ${props => props.theme.currentTheme === 'light' ? '0 2px 12px 0 #DEE2E6' : 'none'};
    padding: 16px 0;
`

const MenuItem = styled.div`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.black1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 12px;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left;
    padding: 0 16px;
    cursor: pointer;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray3 : props.theme.colors.walletBg};
`

const slideInKeyframe = keyframes`
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(100vw, 0, 0);
        opacity: 1;
    }
`
const slideOutKeyframe = keyframes`
    0% {
        transform: translate3d(100vw, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`

const slideInAnimation = 
  css`
    ${slideInKeyframe} 1s;
  `
const slideOutAnimation = 
    css`
        ${slideOutKeyframe} 1s;
    `

const MobileMenuContainer = styled.div`
    position: fixed;
    left: -100vw;
    width: 100vw;
    top: 0;
    bottom: 0;
    background-color: black;
    transform: ${props => props.active > 1 ? 'translate3d(100vw, 0, 0)' : 'translate3d(0, 0, 0)'};
    animation: ${props => props.active === 0 ? 'none' : props.active > 1 ? slideInAnimation : slideOutAnimation};
    z-index: 20;
`

const HeaderButtonContainer = styled.div`
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
`

const HeaderButton = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
`

const HeaderSubButton = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.active ? '#FFFFFF' : '#868E96'};
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: ${props => props.first ? '10px' : 0};
    cursor: pointer;
    user-select: none;
`

const HeaderMenuRoundButton = styled.div((props) => ({
    height: '64px',
    marginTop: '40px',
    padding: '0 32px',
    borderRadius: '32px',
    backgroundColor: '#653EFF',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}))

const Header = ({ scrollPosition = 0 }) => {
    const { user, logoutUser, toggleTheme, currentTheme } = useContext(AuthContext)
    const { connectWallet, disconnectKlaytnWallet } = useContext(Web3PluginContext)
    const { t, i18n } = useTranslation()
    const wrapperRef = useRef(null)
    const [menuVisible, showMenu] = useState(false)
    const [mobileMenu, showMobileMenu] = useState(0)
    const [bgColor, setBgColor] = useState('#fff0')
    const [whiteBg, setWhiteBg] = useState('#FFF')
    const [hoverBg, setHoverBg] = useState('#E9ECEF')
    const [pinkBg, setPinkBg] = useState('#653EFF')
    const [pinkColor, setPinkColor] = useState('#653EFF')
    let history = useHistory()
    let location = useLocation()
    const { isMobile, isPad, isTablet } = useWindowSize()

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            // alert('You clicked outside of me!');
            // console.log("inside")
            // console.log(menuVisible)
            showMenu(false)
        }
    }

    const handleMenuBtn = (route) => {
        if (location.pathname === route) {
            // showMobileMenu(1)
        }
        history.push(route)
    }

    const handleLanguageSwitch = (lang) => {
        i18n.changeLanguage(lang)
    }

    useEffect(() => {
        if (mobileMenu > 1 || isMobile) {
            document.body.style.overflowX = 'hidden'
            document.body.style.overflowY = 'auto'
            document.body.backgroundColor = '#F8F9FA'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [mobileMenu, isMobile])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
    }, [])

    useEffect(() => {
        const min = isPad ? 500 : 560;
        const max = isPad ? 600 : 720;
        if (scrollPosition < min) {
            setBgColor('#fff0')
            setWhiteBg('#FFF')
            setHoverBg('#E9ECEF')
            setPinkBg('#653EFF')
            setPinkColor('#FFF')
        } else if (scrollPosition > max) {
            setBgColor('#FFFFFFFF')
            setWhiteBg('#495057')
            setHoverBg('#000')
            setPinkBg('#FFF')
            setPinkColor('#653EFF')
        } else {
            setBgColor(`rgba(255, 255, 255, ${1 - (max - scrollPosition) / 160})`)
            setWhiteBg(`rgba(${255 - (1 - (max - scrollPosition) / 160) * 182}, ${255 - (1 - (max - scrollPosition) / 160) * 182}, ${255 - (1 - (max - scrollPosition) / 160) * 182}, 1)`)
            setHoverBg(`rgba(${(max - scrollPosition) / 160 * 233}, ${(max - scrollPosition) / 160 * 233}, ${(max - scrollPosition) / 160 * 233}, 1)`)
            setPinkBg(`rgba(${(1 - (max - scrollPosition) / 160) * 154 + 101}, ${(1 - (max - scrollPosition) / 160) * 193 + 62}, 255, 1)`)
            setPinkColor(`rgba(${255 - (1 - (max - scrollPosition) / 160) * 154}, ${255 - (1 - (max - scrollPosition) / 160) * 193}, 255, 1)`)
        }
    }, [scrollPosition])

    return (
        <HeaderTotalContainer disabled={location.pathname.includes('zignsec-complete')} main={location.pathname === '/'}>
            <HeaderContainer style={{ backgroundColor: bgColor }}>
                <HeaderInsideContainer>
                    <HeaderLogo
                        onClick={() => handleMenuBtn(user.isVerified ? '/dashboard' : '/')}
                        src={(isPad ? scrollPosition > 600 : scrollPosition > 700) ? CompassLogoB : CompassLogoB}
                    />
                    {user.isVerified && (
                        <>
                            {!isMobile && (<HeaderLeftBtn onClick={() => history.push('/dashboard')}>{t('dashboard.title')}</HeaderLeftBtn>)}
                        </>
                    )}
                    <MiddleSeparator />
                    {(!user.isVerified && location.pathname !== '/sign-in' && location.pathname !== '/sign-up' && location.pathname !== '/verify' && location.pathname !== '/' && location.pathname !== '/reset-password') ? !isPad ? (
                        <>
                            <HeaderRightTag hoverBg={hoverBg} whiteBg={whiteBg} onClick={() => handleLanguageSwitch('en')}>ENG</HeaderRightTag>
                            <HeaderRightTag onClick={() => handleLanguageSwitch('ko')} hoverBg={hoverBg} whiteBg={whiteBg} disabled>KOR</HeaderRightTag>
                            <HeaderRoundButton onClick={() => history.push('/sign-in')} pinkColor={pinkColor} pinkBg={pinkBg} normal={scrollPosition < 640}>Get started</HeaderRoundButton>
                        </>
                    ) : (
                        <>
                            {/*<MenuBtn src={scrollPosition > 600 ? MenuBlack : MenuWhite} onClick={() => showMobileMenu(2)} />*/}
                        </>
                    ) : (
                        <>
                            {/*{walletAddr && walletAddr.length > 0 && walletBalance > 0 ? (*/}
                            {/*    <>*/}
                            {/*        {!isTablet && (<WalletAddrContainer>{`${walletBalance.substr(0, 6)}KLAY`}</WalletAddrContainer>)}*/}
                            {/*        {!isPad && (*/}
                            {/*            <WalletAddrContainer>*/}
                            {/*                <WalletIcon src={WalletImg} />*/}
                            {/*                {truncate(walletAddr, 14)}*/}
                            {/*            </WalletAddrContainer>*/}
                            {/*        )}*/}
                            {/*    </>*/}
                            {/*  ) : (<></>)}*/}

                            {/* <DayNightImg onClick={toggleTheme} src={currentTheme === 'light' ? NightLogo : DayLogo} /> */}
                            {
                                location.pathname !== '/' && location.pathname !== '/sign-in' && location.pathname !== '/sign-up' && location.pathname !== '/verify' && location.pathname !== '/reset-password' ?
                                  <AvatarContainer onClick={() => showMenu(!menuVisible)}  ref={wrapperRef}>
                                      <Avatar src={userIcon}></Avatar>
                                      <DropDownIcon src={currentTheme === 'light' ? DownBlack : DownWhite} />
                                      {menuVisible && (
                                        <MenuContainer>
                                            <MenuItem
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  showMenu(false)
                                                  history.push('/profile')
                                              }}
                                            >
                                                {t('general.settings')}
                                            </MenuItem>
                                            {
                                                user.nftIssued ?
                                                  <MenuItem
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        showMenu(false)
                                                        history.push('/identity-certificate')
                                                    }}
                                                  >
                                                      {t('general.nft')}
                                                  </MenuItem>
                                                  :
                                                  (
                                                    <>
                                                    </>
                                                  )
                                            }

                                            <MenuItem
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  showMenu(false)
                                                  if (!user.isKycVerified) {
                                                      history.push('/zignsec-instructions')
                                                  } else {
                                                      history.push('/kyc')
                                                  }
                                              }}
                                            >
                                                {t('nft.user.kyc')}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  showMenu(false)
                                                  history.push('/terms')
                                              }}
                                            >
                                                Privacy
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  showMenu(false)
                                                  disconnectKlaytnWallet()
                                              }}
                                            >
                                                {t('general.disconnect')}
                                            </MenuItem>
                                            <MenuItem
                                              onClick={(e) => {
                                                  logoutUser()
                                                  e.stopPropagation()
                                                  showMenu(false)
                                              }}
                                            >
                                                {t('general.sign_out')}
                                            </MenuItem>
                                        </MenuContainer>
                                      )}
                                  </AvatarContainer>
                                  :
                                  <>
                                  </>
                            }

                        </>
                    )}
                </HeaderInsideContainer>
            </HeaderContainer>
            {location.pathname !== '/' && (<Separator />)}
            <MobileMenuContainer active={mobileMenu}>
                <HeaderInsideContainer>
                    <HeaderLogo
                        onClick={() => handleMenuBtn(user.isVerified ? '/dashboard' : '/')}
                        src={CompassLogo}
                    />
                    <MiddleSeparator />
                    {/*<MenuBtn src={CloseWhite} onClick={() => showMobileMenu(1)} />*/}
                </HeaderInsideContainer>
                <HeaderButtonContainer>
                    <HeaderButton>
                        Language
                        <MenuBtn src={DownWhite} />
                    </HeaderButton>
                    <HeaderSubButton first active onClick={() => handleLanguageSwitch('en')}>English</HeaderSubButton>
                    <HeaderSubButton onClick={() => handleLanguageSwitch('ko')}>Korean</HeaderSubButton>
                </HeaderButtonContainer>
            </MobileMenuContainer>
        </HeaderTotalContainer>
    )
}

export default Header