import React, {useContext, useState, useRef, useEffect} from 'react';
import sha256 from 'crypto-js/sha256';
import styled, { css } from 'styled-components';
import { Spinner } from 'react-bootstrap'
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { AuthContext } from '../../contexts';
import HomeImg from '../../asset/img/img_signin.png'
import {codeValidator, emailValidator, ERROR_RESPONSE, ERROR_TEXT, passwordValidator, WindowSize} from '../../util';
import InputField from '../../component/InputField';
import SelectField from '../../component/SelectField';
import { toast } from 'react-toastify';
import {Tab, Tabs} from '@material-ui/core'
import {useTranslation} from "react-i18next";
import axios from "axios";
import { languageList } from '../../util/language';



const BoxContainer = styled.div(() => ({
    padding: '100px 100px 0',
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [WindowSize.tablet]: {
        flexDirection: 'column',
        padding: '92px 24px',
    }
}))

// const ProfileTabContainer = styled.div`
//     padding: '100px 100px 0',
//     maxWidth: '1100px',
//     marginLeft: 'auto',
//     marginRight: 'auto',
//     display: ${props => props.display == 0 ? 'block' : 'none'};
//     flexDirection: 'column',
// `;

// const SettingsTabContainer = styled.div`
//     padding: '100px 100px 0',
//     maxWidth: '1100px',
//     marginLeft: 'auto',
//     marginRight: 'auto',
//     display: ${props => props.display == 1 ? 'block' : 'none'};
//     flexDirection: 'column',
// `;

const FormContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '440px',
    alignSelf: 'center',
    [WindowSize.mobile]: {
        width: '100%',
    }
})

const InnerContainer = styled.div({
    paddingTop: '30px',
    fontFamily: 'Poppins'
})

const SectionTitle = styled.div((props) => ({
    color: props.theme.colors.gray2,
    paddingBottom: '14px',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '30px',
}))

const SubmitBtn = styled.div((props) => ({
    boxSizing: 'border-box',
    height: '40px',
    width: '113px',
    border: '1px solid #653EFF',
    borderRadius: '3px',
    backgroundColor: props.transparent ? props.theme.colors.white : props.theme.colors.pink,
    color: props.transparent ? props.theme.colors.blueHeader : props.theme.colors.white,
    fontFamily: 'Poppins',
    fontSize: '13px',
    letterSpacing: '0',
    lineHeight: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: '15px',
    marginTop: '30px',
}))

const InputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelText = styled.div((props) => ({
    color: props. theme.colors.gray1,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '6px 0 8px',
    display: 'flex',
    flexDirection: 'row',
}))

const InputContainer = styled.div((props) => ({
    height: '41px',
    border: '1px solid',
    borderColor: props.valid ? props.theme.colors.gray3 : props.theme.colors.red,
    borderRadius: '3px',
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: props.disableInput ? props.theme.colors.gray3 : 'transparent',
    marginBottom: props.valid ? '10px' : '0',
}))

const InputDiv = styled.input((props) => ({
    color: props.theme.colors.gray1,
    fontFamily: 'Poppins',
    fontSize: '15px',
    lineHeight: '23px',
    border: 'none',
    outline: 'none',
    width: '100%',
    backgroundColor: 'transparent'
}))

const ErrorStatement = styled.div`
    color: ${props => props.theme.colors.warning};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;`

// const BasisWidget = styled.div`
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
// `

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Profile = () => {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    // const [basisVisible, showBasisVisible] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [wallet, setWallet] = useState('')
    const [residentialCountry, setResidentialCountry] = useState('')
    const [language, setLanguage] = useState('English')
    const [citizenshipCountry, setCitizenshipCountry] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { user, handleError } = useContext(AuthContext)
    const [value, setValue] = useState(0)
    const [isProfile, setIsProfile] = useState(true)
    let history = useHistory()
    const { t, i18n } = useTranslation()
    const userRef = useRef(null)
    userRef.current = user
    let nameField = name

    useEffect(async()=>{
        try {
            const profile = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile/${user._id}`,
                { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${user.authToken}` }})
            if (profile && profile.data) {
                setEmail(profile.data.email)
                setName(profile.data.username)
                setWallet(profile.data.primaryWalletAddress)
                nameField = name
            }
        } catch(error) {
            await handleError(error)
        }

    }, [])

    const tabStyles = {
        default_tab:{
            color: '#868E96',
            fontFamily: 'Poppins',
            fontSize: '15px',
            letterSpacing: '0',
            lineHeight: '23px',
            padding: '10px 0',
            minWidth: '0',
            marginLeft: isProfile ? '15px' : '0',
            marginRight: isProfile ? '0' : '15px',
            textTransform: 'none',
            fontWeight: 'normal'
            //width: '33.3%',
            //backgroundColor: '#FFFFFF',
        },
        active_tab:{
            color: '#495057',
            fontFamily: 'Poppins',
            fontSize: '15px',
            letterSpacing: '0',
            lineHeight: '23px',
            borderBottom: '1px solid #4C6EF5',
            padding: '10px 0 9px 0',
            minWidth: '0',
            marginRight: isProfile ? '15px' : '0',
            marginLeft: isProfile ? '0' : '15px',
            textTransform: 'none',
            fontWeight: 'normal'
        }
    }

    const handleUpdate = async event => {
        if (!emailValidator(email)) {
            setValidated(false)
            toast.error(ERROR_TEXT['12'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }

        if (newPassword && !passwordValidator(newPassword)) {
            setValidated(false)
            toast.error(ERROR_TEXT['13'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (newPassword !== confirmPassword) {
            setValidated(false)
            toast.error(ERROR_TEXT['16'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (name.length === 0) {
            setValidated(false);
            return;
        }
        setValidated(true);
        setLoading(true)
        try {
            const names = (name || '').split(' ')
            let params = {
                currentPassword: password,
                newPassword: newPassword,
                email: email || '',
                username: name || '',
            }
            // updateUser(params)
            // showBasisVisible(true)
            const res = await Axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile/${user._id}`, {
                ...params,
            }, { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${user.authToken}` }});

            if (res && res.data) {
                toast.success("Update Success", {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(res.data.message || ERROR_TEXT['10'], {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false)
            }
        } catch (error) {
            await handleError(error)
        }
        setLoading(false)
    }

    const handleLanguageSwitch = () => {
        if (language === 'English'){
            i18n.changeLanguage('en')
        } else {
            i18n.changeLanguage('ko')
        }

    }

    const handleTabs = (e, val) => {
        if (val===0)
            setIsProfile(true);
        else
            setIsProfile(false);
        setValue(val);
    }
     
    return (
        <BoxContainer>
            <FormContainer>
                <Tabs value= {value} onChange= {handleTabs} indicatorColor={"primary"} textColor={"primary"}>
                    <Tab style={isProfile ? tabStyles.active_tab : tabStyles.default_tab} label={t('profile.prof_head')}/>
                    <Tab style={isProfile ? tabStyles.default_tab : tabStyles.active_tab} label={t('profile.settings')}/>
                </Tabs>
                
                {/*<TabPanel isProfile={isProfile}>{t('profile.prof_head')}</TabPanel>*/}
                <InnerContainer>

                    {isProfile ? (
                        <div>
                            <SectionTitle>{t('profile.prof_head')}</SectionTitle>
                            <InputField
                                label={t('profile.email')}
                                type="text"
                                placeholder="e.g. Joe@gmail.com"
                                valid={validated || emailValidator(email)}
                                disableInput={true}
                                error="Invalid email"
                                value={email}
                                updateValue={setEmail}
                            />
                            {
                                user.nftIssued ?
                                  <InputField
                                    label="Primary Wallet (NFT Issued)"
                                    type="text"
                                    placeholder="0x1234567890123456789"
                                    valid={true}
                                    disableInput={true}
                                    error="Invalid email"
                                    value={wallet}
                                    updateValue={setWallet}
                                  /> :
                                  ""
                            }

                            <InputField
                                label={t('profile.legal_name')}
                                type="text"
                                placeholder={t('profile.legal_name')}
                                //valid={validated || codeValidator(name)}
                                valid={true}
                                error="Invalid username"
                                value={name}
                                updateValue={setName}
                            />
                            <InputField
                                label={t('profile.old_pass')}
                                type="password"
                                placeholder=""
                                valid={validated || passwordValidator(password)}
                                error="Invalid password"
                                value={password}
                                updateValue={setPassword}
                            />

                            <InputField
                                label={t('profile.new_pass')}
                                type="password"
                                placeholder=""
                                valid={validated || passwordValidator(password)}
                                error="Invalid password"
                                value={newPassword}
                                updateValue={setNewPassword}
                            />
                            <InputField
                                label={t('profile.pass_confirm')}
                                type="password"
                                placeholder=""
                                valid={validated || (password === confirmPassword)}
                                error="Invalid password"
                                value={confirmPassword}
                                updateValue={setConfirmPassword}
                            />
                            <SubmitBtn onClick={handleUpdate}>{t('profile.update')}</SubmitBtn>
                        </div>
                    ) : (
                        <div>
                            <SectionTitle>{t('profile.settings')}</SectionTitle>
                            <SelectField
                                label={t('profile.language')}
                                data={languageList}
                                type="text"
                                placeholder=" "
                                valid={validated || language.length > 0}
                                error="Please select the language"
                                value={language === t('profile.eng') ? "English" : "Korean"}
                                updateValue={setLanguage}
                            />
                            {
                                isProfile ?
                                    (
                                        <SubmitBtn onClick={handleUpdate}>{t('profile.update')}</SubmitBtn>
                                    ) : (
                                        <SubmitBtn onClick={handleLanguageSwitch}>{t('profile.update')}</SubmitBtn>
                                    )
                            }
                        </div>
                    )}
                </InnerContainer>

                {/* <PageTitle>
                    KYC
                    <SignInBtn>
                        <SignInLink>Update</SignInLink>
                    </SignInBtn>
                </PageTitle>
                <PageDescription>Show information</PageDescription>
                
                <InputField
                    label="Email address"
                    type="text"
                    placeholder="e.g. Joe@gmail.com"
                    valid={validated || emailValidator(email)}
                    error="Invalid email"
                    value={email}
                    updateValue={setEmail}
                />

                <InputField
                    label="Old Password"
                    type="password"
                    placeholder=""
                    valid={validated || passwordValidator(password)}
                    error="Invalid password"
                    value={password}
                    updateValue={setPassword}
                />

                <InputField
                    label="New Password"
                    type="password"
                    placeholder=""
                    valid={validated || passwordValidator(password)}
                    error="Invalid password"
                    value={password}
                    updateValue={setPassword}
                />
                <InputField
                    label="Confirm New Password"
                    type="password"
                    placeholder="******"
                    valid={validated || (password === confirmPassword)}
                    error="Invalid password"
                    value={confirmPassword}
                    updateValue={setConfirmPassword}
                />
                <SubmitBtn onClick={handleSubmit}>Sign Up</SubmitBtn>
                <SubmitBtn transparent>Sign Up with Google</SubmitBtn> */}
            </FormContainer>
            {/* {basisVisible && (<BasisWidget id="bas-widget-container" />)} */}
            {/* {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )} */}
        </BoxContainer>
    )
};

export default Profile;