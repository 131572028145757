import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from 'react-router-dom'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './util/i18n';
import { Provider } from 'react-redux';
import store from './store';


// ReactDOM.render(
//   <Provider store={store}>
//     <Router>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById('root')
// );


import thunk from 'redux-thunk';
import { render } from 'react-dom';

import { createStore, applyMiddleware, compose } from 'redux';

import reducers from '../src/reducers';


(function Init() {
  function init() {
    const finalCreateStore = compose(
      applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : (f) => { return f; }
    )(createStore);
    const store = finalCreateStore(reducers);


    render(
      <Provider
        store={ store }
      >
        <Router>
          <App />
        </Router>
      </Provider>,
      document.getElementById('root')
    );
  }

  init()

}());


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
