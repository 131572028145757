import styled, { keyframes, css } from 'styled-components'
import { WindowSize } from '../../util'
import InputField from "../../component/InputField";
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts';

const PageContainer = styled.div({
    padding: '120px 33%',
    fontFamily: 'Poppins',
    [WindowSize.mobile]: {
        padding: '92px 24px',
    }
})

const PageTitle = styled.div({
    color: '#495057',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '30px',
})

const SubHeadingContainer = styled.div({
    display: 'flex',
    padding: '20px 0',
    borderBottom: '1px solid #D8D8D8',
    [WindowSize.mobile] : {
        padding: '10px 0'
    }
})

const BlockContainer = styled.div({
    width: '80%',
    display: 'block',
    textAlign: 'left'
})

const SubHeading = styled.div({
    fontSize: '14px',
    lineHeight: '21px',
    color: '#212529',
})

const SubText = styled.div({
    fontSize: '12px',
    lineHeight: '18px',
    color: '#868E96',
    marginTop: '3px',
})

const UpdateButton = styled.div({
    borderRadius: '3px',
    width: '20%',
    // float: 'right',
    backgroundColor: '#E9ECEF',
    padding: '10px 25px',
    color: '#343A40',
    cursor: 'pointer',
    fontSize: '13px',
    lineHeight: '20px',
    [WindowSize.mobile]: {
        padding: '10px 18px',
        fontSize: '10px',
        lineHeight: '17px',
    }
})

const ToggleContainer = styled.div({
    display: 'flex',
    padding: '20px 0',
    width: '100%',
})

const ToggleText = styled.div({
    color: '#868E96',
    fontSize: '14px',
    lineHeight: '21px',
})

const FormContainer = styled.div({

    padding: '10px 0',
})

const FormTitle = styled.div({
    color: '#495057',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '25px',
})


const DEFAULT_IDENTITY = {
    id: '',
    username: '',
    created: '',
    email: '',
    country: '',
    address: '',
    firstname: '',
    lastname: '',
    mobile: '',
    updated: '',
    kyc: false,
}

const KYC = () => {
    const { t } = useTranslation()
    const { user, handleError } = useContext(AuthContext)
    const [identity, setIdentity] = useState(DEFAULT_IDENTITY)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    const [mobile, setMobile] = useState('')
    const [created, setCreated] = useState('')
    const [updated, setUpdated] = useState('')
    const [address, setAddress] = useState('')
    const userStorage = JSON.parse(localStorage.getItem('user-data'))

    useEffect(()=>{
        async function fetchData() {
            const kycResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/kyc`, { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${user.authToken}` }})
            // console.log(kycResponse.data)
            if (kycResponse.data) {
                let firstname = kycResponse.data.verifiedFirstName
                setFirstname(firstname)
                let lastname = kycResponse.data.verifiedLastName
                setLastName(lastname)
                let email = user.email
                setEmail(email)
                let country = kycResponse.data.country
                setCountry(country)
                let mobile = kycResponse.data.verifiedPhoneNumber
                setMobile(mobile)
                let address = kycResponse.data.address
                setAddress(address)
                let completed = kycResponse.data.completedTime
                setUpdated(completed)
            }
        }
        fetchData().then()
    }, [])

    return (
        <PageContainer>
            <PageTitle>
                KYC
            </PageTitle>
            <SubHeadingContainer>
                <BlockContainer>
                    <SubHeading>
                        {t('kyc.info_update')}
                    </SubHeading>
                    <SubText>
                        {t('kyc.last_update')} : {updated ? updated.split('T')[0] : ''} {updated ? updated.split('T')[1].substring(0,5) : ''}
                    </SubText>
                </BlockContainer>
                <UpdateButton onClick={(e) => {
                    e.stopPropagation()
                    window.open('/zignsec-verification')
                }}>
                    {t('kyc.update')}
                </UpdateButton>
            </SubHeadingContainer>
            <ToggleContainer>
                {/*<ToggleText>*/}
                {/*    Show Information*/}
                {/*</ToggleText>*/}

            </ToggleContainer>
            <FormContainer>
                <FormTitle>
                    {t('kyc.details')}
                </FormTitle>

                <InputField
                    label={t('nft.user.first_name')}
                    placeholder={t('nft.user.first_name')}
                    value={firstname}
                    valid={true}
                    disableInput={true}
                    updateValue={setFirstname}
                />
                <InputField
                    label={t('nft.user.last_name')}
                    placeholder={t('nft.user.last_name')}
                    value={lastname}
                    valid={true}
                    disableInput={true}
                    updateValue={setLastName}
                />
                <InputField
                    label={t('kyc.email')}
                    placeholder={t('kyc.email')}
                    value={email}
                    valid={true}
                    disableInput={true}
                    updateValue={setEmail}
                />
                <InputField
                    label={t('nft.user.address')}
                    placeholder={t('nft.user.address')}
                    value={address}
                    valid={true}
                    disableInput={true}
                    updateValue={setAddress}
                />
                <InputField
                    label={t('kyc.country')}
                    placeholder={t('kyc.country')}
                    value={country}
                    valid={true}
                    disableInput={true}
                    updateValue={setCountry}
                />
                <InputField
                    label={t('kyc.mobile')}
                    placeholder={t('kyc.mobile')}
                    value={mobile}
                    valid={true}
                    disableInput={true}
                    updateValue={setMobile}
                />
            </FormContainer>
        </PageContainer>
    )
}

export default KYC