import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { AuthContext } from '../../contexts';

const NotFoundContainer = styled.div`
    padding: 0 100px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
`

const FormContainer = styled.div`
    flex: 1;
    flex-direction: column;
    text-align: center;
`

const PageTitle = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0;
`

const NotFound = () => {

    return (
        <NotFoundContainer>
            <FormContainer>
                <PageTitle>404</PageTitle>
                <PageTitle>Page Not Found</PageTitle>
            </FormContainer>
        </NotFoundContainer>
    )
};

export default NotFound;