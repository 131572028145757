import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from 'react-bootstrap'

import Header from '../../component/Header'
import { WindowSize } from '../../util'
import { useHistory } from 'react-router'
import Footer from '../../component/Footer'
import { useWindowSize } from '../../hooks/useWindowSize'

const ComingSoonContainer = styled.div(() => ({
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
}))

const ComingSoonInnerContainer = styled.div`
    padding: 0 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
`

const FormContainer = styled.div`
    flex: 1;
    flex-direction: column;
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 120px;
    ${WindowSize.ipad} {
        margin-top: 102px;
    }
`

const PageTitle = styled.div`
    color: #000000;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-top: 120px;
    ${WindowSize.ipad} {
        font-size: 25px;
        line-height: 38px;
        margin-top: 0;
    }
`

const PageDescription = styled.div`
    color: #343A40;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin: 20px 0 45px;
    ${WindowSize.ipad} {
        margin: 25px 0;
        font-size: 15px;
        line-height: 23px;
    }
`

const BackButton = styled.div`
    height: 40px;
    width: 120px;
    border-radius: 20px;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin: ${props => props.margin || '0'};
    cursor: pointer;
    &:hover {
        background-color: #CED4DA;
    }
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ComingSoon = () => {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const { isMobile, isPad, isTablet } = useWindowSize()

    return (
        <ComingSoonContainer>
            <ComingSoonInnerContainer>
                <FormContainer>
                    <PageTitle>Coming soon</PageTitle>
                    <PageDescription>
                        The service is being prepared.
                        {isPad ? (<br />) : "  "}
                        Please wait a little longer.
                    </PageDescription>
                    <BackButton onClick={() => history.push('/')}>Back</BackButton>
                </FormContainer>
            </ComingSoonInnerContainer>
            <Footer />
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </ComingSoonContainer>
    )
}

export default ComingSoon