import React, { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from 'react-bootstrap'

import Header from '../../component/Header'
import { WindowSize } from '../../util'

const KYCDefiContainer = styled.div(() => ({
    padding: '0 20px',
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
}))

const FormContainer = styled.div`
    flex: 1;
    flex-direction: column;
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 120px;
`

const PageTitle = styled.div`
    color: #000000;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 42px;
    text-align: center;
    margin-top: 120px;
`

const PageDescription = styled.div`
    color: #343A40;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin: 20px 0 45px;
`

const BackButton = styled.div`
    height: 40px;
    width: 120px;
    border-radius: 20px;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin: ${props => props.margin || '0'};
    cursor: pointer;
    &:hover {
        background-color: #CED4DA;
    }
`

const InputContainer = styled.div((props) => ({
    height: '64px',
    borderRadius: '6px',
    padding: '17px 30px 19px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '560px',
    marginBottom: props.valid ? '10px' : '0',
    marginTop: '25px',
    backgroundColor: props.theme.colors.white1,
    [WindowSize.ipad]: {
        width: '100%',
    }
}))

const InputDiv = styled.input`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    border: none;
    outline: none;
    width: 100%;
    background-color: #0000;
`

const ErrorStatement = styled.div`
    color: ${props => props.theme.colors.warning};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const KYCDefi = () => {
    const [loading, setLoading] = useState(false)

    return (
        <KYCDefiContainer>
            <Header scrollPosition={1000} />
            <FormContainer>
                <PageTitle>Coming soon</PageTitle>
                <PageDescription>The service is being prepared. Please wait a little longer.</PageDescription>
                <BackButton>Back</BackButton>
            </FormContainer>
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </KYCDefiContainer>
    )
}

export default KYCDefi