import { Dialog } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components'

import SearchImg from '../../asset/img/ic_search.svg'
import CloseImgB from '../../asset/img/ic_close_b.svg'
import CloseImgW from '../../asset/img/ic_close_w.svg'
import AssetImg from '../../asset/img/template_asset.png'
import { tokenList, WindowSize } from '../../util'
import {
  AddWalletButton,
  AddWalletDialogContainer,
  AssetDetailAmount,
  AssetDetailCol,
  AssetDetailName,
  AssetDetailSymbol,
  AssetIcon,
  ButtonContainer,
  CloseIcon,
  DialogHeader,
  DialogTitle,
  InputContainer,
  InputDiv,
  InputDivContainer,
  ListContainer,
  ListItem,
  SearchIcon,
  SelectDiv,
} from './style';
import SelectField from '../SelectField';
import { countries } from '../../util/country';
import { chains } from '../../util/chains';
import { toast } from 'react-toastify';
import { Web3PluginContext } from '../../contexts';




const AddWalletDialog = ({ visible = false, closeModal = () => {}, onSelect = () => {} }) => {

  const [chain, setChain] = useState('')
  const [code, setCode] = useState('')
  const [address, setAddress] = useState('')
  const { addManualWallet } = useContext(Web3PluginContext)

  const handleClose = () => {
    closeModal()
  }

  const handleAdd = () => {
    //onSelect(token)
    for (let i = 0; i<chains.length; i++) {
      if (chains[i].name === chain) {
        setCode(chains[i].code)
        let regex = new RegExp(chains[i].regex)
        if (regex.test(address)) {
          addManualWallet(address, chains[i].code, chains[i].name)
          setCode('')
          setAddress('')
          setChain('')

          closeModal()
        } else {
          toast.error('Invalid address for the specified type', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          setCode('')
          setAddress('')
          setChain('')
        }

        break
      }
    }

    //closeModal()
  }

  return (
    <Dialog onClose={handleClose} open={visible} PaperProps={{
      style: {
        maxWidth: '650px',
      },
    }}>
      <AddWalletDialogContainer>
        <DialogHeader>
          <DialogTitle>Add Wallet</DialogTitle>
          <CloseIcon onClick={handleClose} src={CloseImgB} />
        </DialogHeader>
        <InputContainer>
          <SelectDiv>
            <SelectField
              // label="Chain"
              data={chains}
              type="text"
              placeholder=" "
              width="135px"
              valid={true}
              error="Please select the chain"
              value={chain}
              updateValue={setChain}
            />
          </SelectDiv>
          <InputDivContainer>
            <InputDiv
              placeholder="Wallet Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </InputDivContainer>

        </InputContainer>
        <ButtonContainer>
          <AddWalletButton onClick={handleAdd}>Add</AddWalletButton>
        </ButtonContainer>
      </AddWalletDialogContainer>
    </Dialog>
  )
}

export default AddWalletDialog
