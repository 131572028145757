import axios from 'axios';
import { useSelector } from "react-redux";
import * as WA from '../reducers/wallets/walletsAction'
import Axios from 'axios';
import { toast } from 'react-toastify';
import { now } from 'moment';
import { WalletActions } from '../reducers/wallets/walletsAction';

export const CARA_STATUS = {
  0 : 'PENDING',
  1 : 'INDEXED',
  2 : 'RELEASED',
  3 : 'FAILED_TRY_AGAIN',
  4 : 'NOT_PRESENT',
  5 : 'FAILED'
}




export const CheckWalletStatus = async (address, chain) => {

  const userStorage = JSON.parse(localStorage.getItem('user-data'))

  // const klaytnWallet = useSelector(state => state.klaytnWallet)
  try {
    if (chain !== 'btc') {
      address = address.toLowerCase()
    }
    const walletInfo = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/wallet/wallet`, { address: address },
      { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
    if (walletInfo.data) {
      // console.log("inside wallet App : ", walletAddr)
      if (walletInfo.data.status === 0) {
        return CARA_STATUS['4']
      } else {
        if (walletInfo.data.caraPending) {
          return CARA_STATUS['0']
        } else if (walletInfo.data.caraState === 1) {
          return CARA_STATUS['2']
        } else if (walletInfo.data.caraState === 0) {
          return CARA_STATUS['3']
        } else {
          return CARA_STATUS['5']
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const CheckWalletStatusFromWallet = (wallet) => {
  if (wallet.caraPending) {
    return CARA_STATUS['0']
  } else if (wallet.caraState === 1) {
    return CARA_STATUS['2']
  } else if (wallet.caraIndexed) {
    return CARA_STATUS['3']
  }
}

export const SendWalletToCARA = async(wallet) => {
  try {
    const userStorage = JSON.parse(localStorage.getItem('user-data'))
   // const { UpdateMetamaskWalletStatus, UpdateKlaytnWalletStatus } = WalletActions()
    if (wallet.chain !== 'btc') {
      wallet.address = wallet.address.toLowerCase()
    }
    const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/check-wallet`, { address: wallet.address, chain: wallet.chain },
      { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
    if (res.data) {
      if (res.data.status === 0) {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      } else {
        wallet.createdTime = '00T00'
        wallet.updatedTime = '00T00'
        if (res.data.caraPending) {
          wallet.caraStatus = CARA_STATUS['0']
        } else if (res.data.caraStatus) {
          wallet.caraStatus = CARA_STATUS['2']
        } else if (res.data.caraIndexed) {
          wallet.caraStatus = CARA_STATUS['3']
        }
        return wallet
      }
      //setCaraLoading(false)
      // return
    }
  } catch (error) {
    // await handleError(error)
  }
}

export const DisconnectKlaytnWallet = () => {
  const { RemoveKlaytnWallet } = WalletActions()
  const klaytnWallet = JSON.parse(localStorage.getItem('klaytnWallet'))
  RemoveKlaytnWallet(klaytnWallet)
}

export const DisconnectAllWallets = () => {
  return WA.RemoveAllWallets()
}