import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components'
import { Spinner } from 'react-bootstrap'
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { AuthContext } from '../../contexts'

import SignInImg from '../../asset/img/img_signin.png'
import { emailValidator, ERROR_TEXT, passwordValidator, WindowSize } from '../../util'
import { toast } from 'react-toastify'

const SignInContainer = styled.div(() => ({
    padding: '100px 100px 0',
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [WindowSize.tablet]: {
        flexDirection: 'column',
        padding: '0 50px 50px',
    },
    [WindowSize.mobile]: {
        flexDirection: 'column',
        padding: '92px 20px 20px 20px'
    }
}))

const FormContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    width: '440px',
    alignSelf: 'center',
    [WindowSize.mobile]: {
        width: '100%',
    }
})

const PageTitle = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    position: relative;
`

const PageDescription = styled.div`
    color: ${props => props.theme.colors.gray2};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 12px 0 16px;
    text-align: center;
`

const LabelText = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 8px 0;
    display: flex;
    flex-direction: row;
`

const ForgotBtn = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    text-decoration: underline;
    margin-left: auto;
    cursor: pointer;
    user-select: none;
`

const InputContainer = styled.div`
    height: 49px;
    border: 1px solid ${props => props.valid ? props.theme.colors.gray3 : props.theme.colors.red};
    border-radius: 6px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${props => props.second ? '24px' : '10px'};
`

const SubmitBtn = styled.div`
    box-sizing: border-box;
    height: 49px;
    border: 1px solid ${props => props.theme.colors.blueButton};
    border-radius: 6px;
    background-color: ${props => props.transparent ? props.theme.colors.white : props.theme.colors.blueButton};
    color: ${props => props.transparent ? props.theme.colors.blueButton : props.theme.colors.white};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
`

const OrTag = styled.div`
    box-sizing: border-box;
    height: 42px;
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const InputDiv = styled.input`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    border: none;
    outline: none;
    width: 100%;
    background-color: #0000;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`
const SignInBtn = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px 0 5px 20px;
    margin-left: auto;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
`

const SignInLink = styled.div`
    color: ${props => props.theme.colors.blueHeader};
    margin-left: 5px;
`

const SignIn = () => {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    const { user, updateUser } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    let history = useHistory()
    const userStorage = JSON.parse(localStorage.getItem('user-data'))

    useEffect(() => {
      if (userStorage && userStorage.authToken && userStorage.authToken !== '') {
        history.push('/dashboard')
      }
      localStorage.setItem('connectedWallets', '[]')
      localStorage.setItem('klaytnWallet', '')
      localStorage.setItem('metamaskWallet', '')
      // sessionStorage.setItem('balance', '0')
      // sessionStorage.setItem('network', '')
    }, [])

    const handleSubmit = async () => {
        if (!emailValidator(email)) {
            setValidated(false)
            toast.error(ERROR_TEXT['12'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (!passwordValidator(password)) {
            setValidated(false)
            toast.error(ERROR_TEXT['13'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        setValidated(true)
        setLoading(true)
        try {
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/login`, {
                email,
                password
            }, { headers: { 'Content-Type': 'Application/json' } })
            if (res && res.data) {
                if (res.data.status === 0) {
                    toast.error(res.data.message || ERROR_TEXT['10'], {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined
                    });
                    setLoading(false)
                } else {
                    updateUser({
                        email
                    })
                    history.push('/verify')
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }

    const handleResetPassword = async () => {
        if (!emailValidator(email)) {
            toast.error(ERROR_TEXT['14'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        setLoading(true)
        try {
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/send-code`, {
                email: email,
            },
                { headers: { 'Content-Type': 'Application/json' }
            })

            if (res.data) {
                if (res.data.status === 0) {
                    toast.error(res.data.message || ERROR_TEXT['11'], {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                    setLoading(false)
                    return
                }
                updateUser({
                    email
                })
                history.push('/reset-password')
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (email.length > 0) {
                handleSubmit();
            }
        }
    }

    return (
        <SignInContainer>
            <FormContainer>
                <PageTitle>
                    Sign In
                    <SignInBtn onClick={() => history.push('/sign-up')}>
                        or
                        <SignInLink>Sign Up</SignInLink>
                    </SignInBtn>
                </PageTitle>
                <PageDescription>Enter your details below</PageDescription>
                <LabelText>Email address</LabelText>
                <InputContainer valid={validated || emailValidator(email)}>
                    <InputDiv
                        type="email"
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </InputContainer>
                <LabelText>
                    Password
                    <ForgotBtn onClick={() => handleResetPassword()}>Forgot your password?</ForgotBtn>
                </LabelText>
                <InputContainer second valid={validated || passwordValidator(password)}>
                    <InputDiv
                        type="password"
                        placeholder="xxxxxx"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={_handleKeyDown}
                    />
                </InputContainer>
                <SubmitBtn onClick={handleSubmit}>Sign In</SubmitBtn>
                {/*<OrTag>Or</OrTag>*/}
                {/*<SubmitBtn transparent>Sign In with Google</SubmitBtn>*/}
            </FormContainer>
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </SignInContainer>
    )
}

export default SignIn