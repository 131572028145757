import styled from 'styled-components'


const TermsContainer = styled.div(() => ({
  paddingLeft: '15%',
  paddingRight: '15%',
  fontFamily: 'Poppins',
  marginTop: '80px',
  paddingTop: '20px',
  backgroundColor: '#FFF'
}))


const TermsHeader = styled.div(() => ({
  display: 'block',
  textAlign: 'center',
  width: '100%',
  fontSize: '23px',
  fontWeight: 'bold',
  marginBottom: '24px'
}))

const TermsDescription = styled.div(() => ({
  display: 'block',
  width: '100%',
  fontSize: '16px',
  color: '#495057',
  lineHeight: '21px',
  marginBottom: '24px'
}))

const TermsList = styled(TermsDescription)(() => ({
  marginBottom: '0'
}))

const DescHeader = styled.div(() => ({
  fontSize: '20px',
  fontWeight: 'bold',
  width: '100%',
  marginBottom: '24px',
  float: 'left',
  //textAlign: 'left'
}))

const DescTable= styled.table(() => ({
  width:'100%',
  marginBottom: '24px',
  border: '1px solid',
  borderCollapse: 'collapse'
}))

const TableHeader = styled.tr(() => ({
  backgroundColor: '#868E96',
}))

const TableRow = styled.tr(() => ({
  width: '100%',
  border: '1px solid'
}))

const TableColumn = styled.td(() => ({
  border: '1px solid'
}))




const Terms =() => {
  return(
    <TermsContainer>
      <TermsHeader>
        UPPSALA PTE. LTD. 개인정보 처리방침
      </TermsHeader>
      <TermsDescription>
        이 방침은 UPPSALA PTE. LTD.(이하 "회사")가 운영하는 컴패스 프로토콜 서비스(이하 "서비스")와 관련하여 수집 및
        이용하는 개인정보에 관한 방침입니다. 회사는 개인정보 보호법 제30 조에 따라 정보주체의 개인정보를 보호하고 이와 관련한
        고충을 신속하고 원활하게 처리하기 위해 다음과 같이 개인정보 처리지침을 수립, 공개합니다.
      </TermsDescription>
      <DescHeader>
        1. 개인정보의 처리목적
      </DescHeader>
      <TermsDescription>
        회사는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다. 이용목적이 변경되는
        경우에는 개인정보보호법 제18 조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      </TermsDescription>

      <TermsList>
        가. 고객 가입 의사 확인
      </TermsList>
      <TermsList>
        나. 고객이 제공한 정보를 통한 본인 식별·인증
      </TermsList>
      <TermsList>
        다. 회원자격 유지·관리
      </TermsList>
      <TermsList>
        라. 물품 또는 서비스 공급에 따른 금액 결제
      </TermsList>
      <TermsList>
        마. 물품 또는 서비스의 공급·배송
      </TermsList>
      <TermsDescription>
        바. 수사기관 협조 요청 시 관련 정보 제공 등
      </TermsDescription>
      <DescHeader>
        2. 처리하는 개인정보 항목
      </DescHeader>
      <TermsDescription>
        회사는 다음의 개인정보 항목을 처리하고 있습니다.
      </TermsDescription>
      <DescTable>
        <TableHeader>
          <TableColumn>
            구분
          </TableColumn>
          <TableColumn>
            수집목적
          </TableColumn>
          <TableColumn>
            수집항목
          </TableColumn>
          <TableColumn>
            비고(수집 근거)
          </TableColumn>
        </TableHeader>
        <TableRow>
          <TableColumn>
            회원가입 시
          </TableColumn>
          <TableColumn>
            서비스 이용에 따른 본인 식별
          </TableColumn>
          <TableColumn>
            이름
            신분증/여권번호
            거주지 주소
            거주 국가
            시민권 국가
            연락처
            이메일 주소
          </TableColumn>
          <TableColumn>
            본인 동의
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>
            서비스 이용 시
          </TableColumn>
          <TableColumn>
            서비스   이용   처리   과정에서
            자동생성되어 수집됨
          </TableColumn>
          <TableColumn>
            접속로그
            쿠키
            접속 IP 정보
          </TableColumn>
          <TableColumn>
            본인 동의
          </TableColumn>
        </TableRow>
      </DescTable>
      <DescHeader>
        3. 개인정보의 처리 및 보유기간
      </DescHeader>
      <TermsDescription>
        ① 회사는 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유·이용기간 또는 법령에 따른 개인정보 보유·이용기간
        내에서 개인정보를 처리·보유합니다.
      </TermsDescription>
      <TermsDescription>
        ② 회사는 사용목적이 달성되면 개인정보를 바로 폐기합니다.
      </TermsDescription>
      <TermsDescription>
        ③ 기타 기록은 법령상 근거에 따라 아래와 같이 처리합니다.
      </TermsDescription>
      <TermsList>
        가. 계약 또는 청약철회 등에 관한 기록
      </TermsList>
      <TermsList>
        보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
      </TermsList>
      <TermsDescription>
        보존 기간 : 5 년
      </TermsDescription>
      <TermsList>
        나. 대금결제 및 재화 등의 공급에 관한 기록
      </TermsList>
      <TermsList>
        보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
      </TermsList>
      <TermsDescription>
        보존 기간 : 5 년
      </TermsDescription>
      <TermsList>
        다. 소비자의 불만 또는 분쟁처리에 관한 기록
      </TermsList>
      <TermsList>
        보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률
      </TermsList>
      <TermsDescription>
        보존 기간 : 3 년
      </TermsDescription>
      <TermsList>
        라. 본인확인에 관한 기록
      </TermsList>
      <TermsList>
        보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률
      </TermsList>
      <TermsDescription>
        보존 기간 : 6 개월
      </TermsDescription>
      <TermsList>
        마. 방문에 관한 기록
      </TermsList>
      <TermsList>
        보존 이유 : 통신비밀보호법
      </TermsList>
      <TermsDescription>
        보존 기간 : 3 개월
      </TermsDescription>
      <DescHeader>
        4. 개인정보의 제3자 제공
      </DescHeader>
      <TermsDescription>
        회사는 정보주체의 개인정보를 개인정보의 처리목적에서 명시한 범위 내에서만 처리하며, 정보주체의 별도 동의, 법률의 특별한
        규정, 수사 목적으로 법령에 정해진 절차와 방법에 따른 수사 기관의 협조 요청 등 개인정보 보호법 제17 조에 해당하는 경우
        외에는 개인정보를 제3 자에게 제공하지 않습니다.
      </TermsDescription>
      <DescHeader>
        5. 개인정보 처리의 위탁
      </DescHeader>
      <TermsDescription>
        ① 회사는 원활한 업무 처리를 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따른 위탁계약 시 개인정보가
        안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
      </TermsDescription>
      <DescTable>
        <TableHeader>
          <TableColumn>
            구분
          </TableColumn>
          <TableColumn>
            제공 목적
          </TableColumn>
          <TableColumn>
            제공하는 개인정보 항목
          </TableColumn>
          <TableColumn>
            개인정보 보유 및 이용 기간
          </TableColumn>
        </TableHeader>
        <TableRow>
          <TableColumn>
            ZignSec
          </TableColumn>
          <TableColumn>
            안면   인식  API(Microsoft
            Azure Face) 접속 및 비교
          </TableColumn>
          <TableColumn>
            신분증 사본상의 사진부, 안면 정보
            (사진)
          </TableColumn>
          <TableColumn>
            개인정보 2 주간 보관 후 파기
          </TableColumn>
        </TableRow>
      </DescTable>
      <TermsDescription>
        ② 회사는 위탁계약 체결 시 개인정보보호법 제26 조에 따라 위탁업무 수행목적 외 개인정보 처리 금지, 기술적‧관리적 보호조치,
        재위탁 제한, 수탁자에 대한 관리‧감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 회사 또는 회사의 대리인을
        통하여 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </TermsDescription>
      <TermsDescription>
        ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
      </TermsDescription>
      <DescHeader>
        6. 개인정보의 국외 이전
      </DescHeader>
      <TermsDescription>
        ① 회사는 신분증 사본의 개인정보 식별 업무를 국외 법인인 ZignSEC 에 아래와 같이 위탁하고 있습니다.
      </TermsDescription>
      <DescTable>
        <TableHeader>
          <TableColumn>
            이전되는 개인정보 항목
          </TableColumn>
          <TableColumn>
            이전   국가,  이전일시   및
            이전방법
          </TableColumn>
          <TableColumn>
            이전받는 업체 및
            보안 담당자 연락처
          </TableColumn>
          <TableColumn>
            이전받는 자의 이용목적 및 보유
            이용기간
          </TableColumn>
        </TableHeader>
        <TableRow>
          <TableColumn>
            신분증 사본 사진
          </TableColumn>
          <TableColumn>
            이전 국가  :  스웨덴(서버
            소재지 네덜란드)
            이전 일시 및 방법 :  기기
            카메라를 통해 신분증 촬영
            즉시 네트워크를 통한 전송
          </TableColumn>
          <TableColumn>
            ZignSEC,
            jason.coombes@zignsec.co
            m
          </TableColumn>
          <TableColumn>
            개인정보   식별   및   서비스
            제공자측에 유저 개인정보 제공
            목적,  개인정보 2 주간 보관 후
            파기
          </TableColumn>
        </TableRow>
      </DescTable>
      <TermsDescription>
        ② 회사는 개인정보보호법 제39 조의12 에 따라 개인정보보호를 위한 안전성 확보 조치, 개인정보 침해에 대한 고충처리및
        분쟁해결에 관한 조치 등 개인정보를 국외로 이전하는 경우 필요한 조치를 취하고 있습니다.
      </TermsDescription>
      <DescHeader>
        7. 개인정보의 파기
      </DescHeader>
      <TermsDescription>
        ①  회사는 개인정보 보유기간의 경과,  처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
        파기합니다.
      </TermsDescription>
      <TermsDescription>
        ②  정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라
        개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스로 옮기거나 보관장소를 달리하여 보존합니다.
      </TermsDescription>
      <TermsList>
        ③ 회사는 다음의 방법으로 개인정보를 파기합니다.
      </TermsList>
      <TermsList>
        - 전자적 파일 형태인 경우 : 복원이 불가능한 방법으로 영구삭제
      </TermsList>
      <TermsDescription>
        - 전자적 파일의 형태 외의 기록물, 인쇄물, 서면, 그 밖의 기록매체인 경우 : 파쇄 또는 소각
      </TermsDescription>
      <DescHeader>
        8. 미이용자의 개인정보의 파기 등에 관한 조치
      </DescHeader>
      <TermsDescription>
        ①  회사는 1 년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고,  개인정보를 별도로 분리하여 보관합니다.  분리
        보관된 개인정보는 1 년간 보관 후 지체없이 파기합니다.
      </TermsDescription>
      <TermsDescription>
        ② 회사는 휴면전환 30 일 전까지 휴면예정 이용자에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보의
        항목을 이메일 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
      </TermsDescription>
      <TermsDescription>
        ③  휴면계정으로 전환을 원하지 않으시는 경우,  휴면계정 전환 전 서비스 로그인을 하시면 됩니다.  또한,  휴면계정으로
        전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.
      </TermsDescription>
      <DescHeader>
        9. 정보주체와 법정대리인의 권리‧의무 및 행사방법
      </DescHeader>
      <TermsDescription>
        정보주체는 회사에 대해 언제든지 다음 각 항의 개인정보 보호 관련 권리를 행사할 수 있습니다.
      </TermsDescription>
      <TermsDescription>
        ① 정보주체(만14 세 미만인 경우에는 법정대리인을 말함)는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의
        권리를 행사할 수 있습니다.
      </TermsDescription>
      <TermsDescription>
        ② 제1 항에 따른 권리 행사는 회사에 대해 「개인정보 보호법」 시행령 제41 조제1 항에 따라 서면, 전자우편, 모사전송(FAX)
        등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.
      </TermsDescription>
      <TermsDescription>
        ③ 제1 항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
        개인정보 보호법 시행규칙 별지 제11 호 서식에 따른 위임장을 제출하셔야 합니다.
      </TermsDescription>
      <TermsDescription>
        ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35 조 제5 항, 제37 조 제2 항에 의하여 정보주체의 권리가 제한 될
        수 있습니다.
      </TermsDescription>
      <TermsDescription>
        ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
        없습니다.
      </TermsDescription>
      <TermsDescription>
        ⑥ 정보주체가 열람, 정정·삭제, 처리정지의 요구에 대한 거절 등 조치에 대하여 불복이 있는 경우 이의를 제기할 수 있습니다. 이
        경우 제 2 항과 동일한 절차를 통하여 하실 수 있으며, 이에 대해 지체 없이 조치하겠습니다.
      </TermsDescription>
      <TermsDescription>
        ⑦ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
        대리인인지를 확인합니다.
      </TermsDescription>
      <TermsDescription>
        ⑧ 개인정보의 열람, 정정·삭제, 처리정지 요구 접수·처리부서와 담당자는 아래와 같습니다.
      </TermsDescription>
      <TermsList>
        ▶ 개인정보 보호책임자
      </TermsList>
      <TermsList>
        성명 : 김형우        직책 : CEO
      </TermsList>
      <TermsDescription>
        연락처 : info@compassprotocol.io
      </TermsDescription>
      <DescHeader>
        10. 개인정보의 안전성 확보조치
      </DescHeader>
      <TermsDescription>
        회사는「개인정보 보호법」제29 조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.
      </TermsDescription>
      <TermsList>
        가. 내부관리계획의 수립 및 시행
      </TermsList>
      <TermsDescription>
        ·회사는 ‘개인정보의 안전성 확보조치 기준’에 의거하여 내부관리계획을 수립 및 시행합니다.
      </TermsDescription>
      <TermsList>
        나. 개인정보취급자 지정의 최소화 및 교육
      </TermsList>
      <TermsDescription>
        ·개인정보취급자의 지정을 최소화하고 정기적인 교육을 시행하고 있습니다.
      </TermsDescription>
      <TermsList>
        다. 개인정보에 대한 접근 제한
      </TermsList>
      <TermsDescription>
        ·개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근을 통제하고,
        침입차단시스템과 침입방지 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
      </TermsDescription>
      <TermsList>
        라. 접속기록의 보관 및 위변조 방지
      </TermsList>
      <TermsList>
        ·개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1 년 이상 보관, 관리하고 있습니다.
      </TermsList>
      <TermsDescription>
        ※  다만,   5 만   명이상의   정보주체에   관하여   개인정보를   처리하거나,  고유식별정보   또는   민감정보를   처리하는
        개인정보처리시스템의 경우에는 2 년 이상 보관·관리하고 있습니다.
      </TermsDescription>
      <TermsList>
        마. 개인정보의 암호화
      </TermsList>
      <TermsDescription>
        ·이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는
        등의 별도 보안기능을 사용하고 있습니다.
      </TermsDescription>
      <TermsList>
        바. 해킹 등에 대비한 기술적 대책
      </TermsList>
      <TermsDescription>
        ·회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·
        점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.
      </TermsDescription>
      <TermsList>
        사. 비인가자에 대한 출입 통제
      </TermsList>
      <TermsDescription>
        ·개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
        있습니다.(번호는 원숫자기호로 수정)
      </TermsDescription>
      <DescHeader>
        11. 개인정보 자동 수집 장치의 설치 운영 및 거부에 관한 사항
      </DescHeader>
      <TermsDescription>
        ①  회사는  이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
        사용합니다.
      </TermsDescription>
      <TermsDescription>
        ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
        컴퓨터내의 하드디스크에 저장되기도 합니다.
      </TermsDescription>
      <TermsList>
        가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
        파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
      </TermsList>
      <TermsList>
        나. 쿠키의 설치 운영 및 거부 ∙ : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할
        수 있습니다.
      </TermsList>
      <TermsDescription>
        다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
      </TermsDescription>
      <DescHeader>
        12. 개인정보 보호책임자
      </DescHeader>
      <TermsDescription>
        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기
        위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
      </TermsDescription>
      <TermsList>
        ▶ 개인정보 보호책임자
      </TermsList>
      <TermsList>
        성명 : 김형우        직책 : CEO
      </TermsList>
      <TermsDescription>
        연락처 : info@compassprotocol.io
      </TermsDescription>
      <DescHeader>
        13. 권익침해 구제 방법
      </DescHeader>
      <TermsDescription>
        ①  정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회,  한국인터넷진흥원 개인정보침해신고센터
        등에 분쟁해결이나 상담 등을 신청할 수 있습니다.  이 밖에 기타 개인정보침해의 신고,  상담에 대하여는 아래의 기관에
        문의하시기 바랍니다.
      </TermsDescription>
      <TermsList>
        가. 개인정보분쟁조정위원회 : (국번없이) 1833-6972(www.kopico.go.kr)
      </TermsList>
      <TermsList>
        나. 개인정보침해신고센터 : (국번없이) 118(privacy.kisa.or.kr)
      </TermsList>
      <TermsList>
        다. 대검찰청 : (국번없이) 1301(www.spo.go.kr)
      </TermsList>
      <TermsDescription>
        라. 경찰청 : (국번없이) 182(ecrm.cyber.go.kr)
      </TermsDescription>
      <TermsList>
        ② 개인정보보호법 제35 조(개인정보의 열람), 제36 조(개인정보의 정정‧삭제), 제37 조(개인정보의 처리정지 등)의 규정에
        의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익이 침해를 받은 자는 행정심판법이 정하는
        바에 따라 행정심판을 청구할 수 있습니다.
      </TermsList>
      <TermsDescription>
        · 중앙심판위원회 : (국번없이) 110(www.simpan.go.kr)
      </TermsDescription>
      <TermsDescription>
        ③ 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해구제를 위해 노력하고 있으며 신고나
        상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.
      </TermsDescription>
      <TermsList>
        ▶ 고충처리부서 담당자
      </TermsList>
      <TermsList>
        성명 : 김형우          직책 : CEO
      </TermsList>
      <TermsDescription>
        연락처 : info@compassprotocol.io
      </TermsDescription>
      <TermsDescription>
        ④  정보주체는 회사의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의,  불만처리,  피해구제 등에 관한 사항을
        개인정보 보호책임자 및 담당부서로 문의할 수 있습니다.
      </TermsDescription>
      <DescHeader>
        14. 개인정보 처리방침 변경
      </DescHeader>
      <TermsDescription>
        이 개인정보 처리방침은 2022.부터 적용됩니다.
      </TermsDescription>
    </TermsContainer>
  )

}

export default Terms