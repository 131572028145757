import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  useHistory, useLocation,
} from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { Spinner } from 'react-bootstrap'
import { ethers } from 'ethers'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import BigNumber from 'bignumber.js'
import { AuthContext, MetamaskContext } from '../../contexts'

import EditImg from '../../asset/img/ic_edit_1.svg'
import ArrowImgB from '../../asset/img/ic_arrow_right_b.svg'
import DeleteImg from '../../asset/img/ic_delete_1.svg'
import SearchImg from '../../asset/img/ic_search.svg'
import AssetImg from '../../asset/img/template_asset.png'
import SwapImgB from '../../asset/img/ic_swap_b.svg'
import SwapImg from '../../asset/img/ic_swap_w.svg'
import AssetSelector from '../../component/AssetSelector'
import AssetDialog from '../../component/AssetDialog'
import { ERROR_TEXT, LightColors, tokenList, WindowSize } from '../../util'
import ConfirmDialog from '../../component/ConfirmDialog'
import { useWindowSize } from '../../hooks/useWindowSize'

const SwapContainer = styled.div(() => ({
    padding: '0 100px',
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '70px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [WindowSize.tablet]: {
        padding: '0 50px 50px',
    }
}))

const TableContainer = styled.div((props) => ({
    width: '570px',
    borderRadius: '6px',
    backgroundColor: props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.headerBg,
    boxShadow: `0 2px 20px 0 ${props.theme.currentTheme === 'light' ? props.theme.colors.boxShadow : props.theme.colors.transparent}`,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [WindowSize.tablet]: {
        width: '100%',
    }
}))

const TableHeader = styled.div`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.black1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px;
`

const TableDescription = styled.div`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin: 8px 0 38px 0;
`

const SwapBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
`

const SwapInnerContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: '100px',
    alignItems: 'center',
    borderRadius: '6px',
    backgroundColor: props.theme.currentTheme === 'light' ? props.theme.colors.white1 : props.theme.colors.walletBg,
    padding: '12px 24px',
    [WindowSize.mobile]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: '200px',
    }
}))

const SwapDetailsContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const SwapDetailFrom = styled.div`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    align-items: center;
`

const SwapDetailFromBlack = styled(SwapDetailFrom)`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.black1 : props.theme.colors.white};
`

const SwapDetailInput = styled.input`
    height: 30px;
    width: 100%;
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.black1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    border: none;
    outline: none;
    background-color: ${props => props.theme.colors.transparent};
    margin: 2px 0 8px;
`

const SwapCenterContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.white1 : props.theme.colors.walletBg};
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: center;
    align-self: center;
    cursor: pointer;
`

const SwapCenterIcon = styled.img`
    width: 22px;
    height: 22px;
`

const SwapButton = styled.div`
    height: 64px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.blueButton};
    width: 100%;
    margin-top: 80px;
    color: ${props => props.theme.colors.white};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SwapPercentContainer = styled.div(() => ({
    height: '28px',
    margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [WindowSize.tablet]: {
        height: '60px',
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
}))

const PercentLeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const PercentLeftItem = styled.div`
    height: 28px;
    width: 50px;
    border-radius: 16px;
    background-color: ${props => props.theme.currentTheme === 'light'
        ? props.active ? props.theme.colors.gray2 : props.theme.colors.white1
        : props.active ? props.theme.colors.white1 : props.theme.colors.gray2};
    margin-right: 2px;
    color: ${props => props.theme.currentTheme === 'light'
    ? props.active ? props.theme.colors.white : props.theme.colors.gray1
    : props.active ? props.theme.colors.gray1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const PercentRightContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '232px',
    [WindowSize.tablet]: {
        width: 'calc(100% - 10px)',
        margin: '0 5px'
    }
}))

const marks = [
    {
      value: 0,
    },
    {
      value: 25,
    },
    {
      value: 50,
    },
    {
      value: 75,
    },
    {
      value: 100,
    },
];

const Swap = () => {
    let history = useHistory()
    let location = useLocation()
    const [loading, setLoading] = useState(false)
    const [listVisible, showList] = useState(false)
    const [toListVisible, showToList] = useState(false)
    const [confirmVisible, showConfirmDialog] = useState(false)
    const [fromToken, setFromToken] = useState(location.state?.token || 'ETH')
    const [toToken, setToToken] = useState(tokenList[location.state?.token || 'ETH'].swapTokens[0])
    const [fromAmount, setFromAmount] = useState(0)
    const [toAmount, setToAmount] = useState(0)
    const [percent, setPercentage] = useState(0)
    const { isMobile, isTablet } = useWindowSize()
    const { user, updateUser, currentTheme } = useContext(AuthContext)
    const { walletAddr, web3ConnectProvider, walletBalance, connectWallet } = useContext(MetamaskContext)

    useEffect(() => {
        connectWallet()
    }, [])

    const handleFromToken = token => {
        if (token !== fromToken) {
            setToToken(tokenList[token].swapTokens[0])
        }
        setFromToken(token)
    }

    const handleChangePercentage = (event, newValue, tokenName) => {
        setPercentage(newValue)
        if (walletAddr.length > 0) {
            const startAmount = walletBalance[tokenName ?? fromToken].multipliedBy(new BigNumber(newValue / 100)).toString()
            setFromAmount(parseFloat(startAmount))
            setToAmount(parseFloat(startAmount) * 0.95)
        }
    }

    const handleFromAmountChange = (e) => {
        setFromAmount(parseFloat(e.target.value))
        setToAmount(parseFloat(e.target.value) * 0.95)
        if (walletAddr.length > 0) {
            const newPercent = new BigNumber(parseFloat(e.target.value)).dividedBy(walletBalance[fromToken]).multipliedBy(new BigNumber(100)).toNumber()
            setPercentage(newPercent)
        }
    }

    const handleToAmountChange = (e) => {
        setToAmount(parseFloat(e.target.value))
        setFromAmount(parseFloat(e.target.value) / 0.95)
        if (walletAddr.length > 0) {
            const newPercent = new BigNumber((parseFloat(e.target.value) / 0.95)).dividedBy(walletBalance[fromToken]).multipliedBy(new BigNumber(100)).toNumber()
            setPercentage(newPercent)
        }
    }
    
    const handleTokenListSwap = (e) => {
        setFromToken(toToken)
        if ([25, 50, 75, 100].findIndex(item => item === percent) >= 0) {
            handleChangePercentage(e, percent, toToken)
        }
        setToToken(tokenList[toToken].swapTokens[0])
    }

    const handleSwap = async () => {
        if (user.caraPending) {
            toast.error(ERROR_TEXT['0'], {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            return
        }
        if (!user.caraStatus) {
            toast.error(user.caraPending ?
                ERROR_TEXT['0']
                : user.caraIndexed ? ERROR_TEXT['1']
                : ERROR_TEXT['2'], {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClick: () => history.push('/dashboard')
            })
            return
        }
        if (!user.isZignSecVerified) {
            toast.error(ERROR_TEXT['3'], {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClick: () => window.open('/zignsec-verification')
            })
            return
        }
        if (fromAmount <= 0) {
            toast.error(ERROR_TEXT['4'], {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            return
        }

        if (walletAddr.length === 0) {
            toast.error(ERROR_TEXT['5'], {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClose: () => connectWallet()
            })
            return
        }

        if (walletBalance[fromToken].lt(new BigNumber(fromAmount))) {
            toast.error(ERROR_TEXT['6'].replace('<source>', fromToken), {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
            return
        }

        if (fromToken.indexOf('A') === 0) {
            withdrawToken()
        } else {
            if (fromToken === 'BTC') {
                const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/bitcoin/generate-wallet`, { ethAddress: walletAddr.toLowerCase(), testnet: true },
                        { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${user.authToken}` }})
                    history.push('/btc-deposit', {
                        address: walletAddr,
                        amount: fromAmount,
                        btcAddress: res.data.address
                    })
            } else {
                depositToken()
            }
        }
    }

    const withdrawToken = async () => {
        try {
            if(!web3ConnectProvider) {
                return;
            }
            setLoading(true)
            // Connect to the network
            const provider = new ethers.providers.Web3Provider(web3ConnectProvider)
            const currentNetwork = await provider.getNetwork()
            // if (currentNetwork.chainId != process.env.REACT_APP_CHAIN_ID) {
            //     toast.error(ERROR_TEXT['7'].replace('<source>', process.env.REACT_APP_CHAIN_ID == 1 ? 'Mainnet' : 'Kovan testnet'), {
            //       position: "top-right",
            //       autoClose: 10000,
            //       hideProgressBar: true,
            //       closeOnClick: true,
            //       pauseOnHover: false,
            //       draggable: true,
            //       progress: undefined,
            //     })
            //     setLoading(false)
            //     return
            // }
            const signer = provider.getSigner()

            const walletAddress = await signer.getAddress()
            if (walletAddress !== walletAddr) {
                toast.error(ERROR_TEXT['8'], {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                })
                setLoading(false)
                return
            }
            let coinType = 'teth'
            switch (toToken) {
                case 'USDT':
                    coinType = 'test'
                    break
                case 'WETH':
                    coinType = 'tweth'
                    break
                case 'ETH':
                    coinType = 'teth'
                    break
                case 'WBTC':
                    coinType = 'twbtc'
                    break
                default:
                    break
            }
            if (toToken === 'BTC') {
                toast.error(ERROR_TEXT['9'], {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                })
                setLoading(false)
                return
            }
            let realAmount = new BigNumber(fromAmount).multipliedBy(new BigNumber(10).pow(tokenList[fromToken].decimal))

            if (toToken === 'ETH') {
                let assuredContract = new ethers.Contract(tokenList[fromToken].address, tokenList[fromToken].abi, provider)
                const assuredContractWithSigner = assuredContract.connect(signer)
                const aRes = await (await assuredContractWithSigner.withdrawEth(realAmount.toString())).wait()
            } else {
                let assuredContract = new ethers.Contract(tokenList[fromToken].address, tokenList[fromToken].abi, provider)
                const assuredContractWithSigner = assuredContract.connect(signer)
                const aRes = await (await assuredContractWithSigner.withdraw(realAmount.toString())).wait()
            }
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/wallet/withdraw`, { address: walletAddress.toLowerCase(), amount: realAmount.toString(), coinType },
            { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${user.authToken}` }})
            setLoading(false)
            history.push('/dashboard')
        } catch (error) {
            console.log('----errr--------', error)
        }
        setLoading(false)
    }

    const depositToken = async (currentValue, currentAsset) => {
        try {
            if(!web3ConnectProvider) {
                return;
            }
            setLoading(true)
            // Connect to the network
            const provider = new ethers.providers.Web3Provider(web3ConnectProvider)
            const currentNetwork = await provider.getNetwork()
            // if (currentNetwork.chainId != process.env.REACT_APP_CHAIN_ID) {
            //     toast.error(ERROR_TEXT['7'].replace('<source>', process.env.REACT_APP_CHAIN_ID == 1 ? 'Mainnet' : 'Kovan testnet'), {
            //       position: "top-right",
            //       autoClose: 10000,
            //       hideProgressBar: true,
            //       closeOnClick: true,
            //       pauseOnHover: false,
            //       draggable: true,
            //       progress: undefined,
            //     })
            //     setLoading(false)
            //     return
            // }
            const signer = provider.getSigner()

            const walletAddress = await signer.getAddress()
            if (walletAddress !== walletAddr) {
                toast.error(ERROR_TEXT['8'], {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                })
                setLoading(false)
                return
            }
            
            let realAmount = new BigNumber(fromAmount).multipliedBy(new BigNumber(10).pow(tokenList[fromToken].decimal))

            if (fromToken === 'ETH') {
                let assuredContract = new ethers.Contract(tokenList[toToken].address, tokenList[toToken].abi, provider)
                const assuredContractWithSigner = assuredContract.connect(signer)
                const aRes = await (await assuredContractWithSigner.depositEth({ from: walletAddress, value: realAmount.toFixed(0) })).wait()
                history.push('/dashboard')
                setLoading(false)
                return
            }

            let contract = new ethers.Contract(tokenList[fromToken].address, tokenList[fromToken].abi, provider)
            const aswapWithSigner = contract.connect(signer)
            const res = await (await aswapWithSigner.approve(tokenList[toToken].address, realAmount.toFixed(0))).wait()

            let assuredContract = new ethers.Contract(tokenList[toToken].address, tokenList[toToken].abi, provider)
            const assuredContractWithSigner = assuredContract.connect(signer)
            const aRes = await (await assuredContractWithSigner.deposit(realAmount.toFixed(0))).wait()
            
            history.push('/dashboard')
        } catch (error) {
            console.log('----errr--------', error)
        }
        setLoading(false)
    }

    const IOSSlider = withStyles({
        root: {
            color: currentTheme === 'light' ? LightColors.gray2 : LightColors.gray2,
            height: '0',
            padding: '9px 0'
        },
        thumb: {
            boxSizing: 'border-box',
            height: '14px',
            width: '14px',
            border: `4px solid ${currentTheme === 'light' ? LightColors.gray2 : LightColors.gray2}`,
            backgroundColor: currentTheme === 'light' ? LightColors.white1 : LightColors.white1,
            margin: '-4px 0 0 -7px'
        },
        active: {},
        valueLabel: {
        },
        track: {
            height: '6px',
            borderRadius: '6px',
            backgroundColor: currentTheme === 'light' ? LightColors.gray2 : LightColors.gray2,
        },
        rail: {
            height: '6px',
            borderRadius: '6px',
            backgroundColor: currentTheme === 'light' ? LightColors.white1 : LightColors.white1,
        },
        mark: {
            boxSizing: 'border-box',
            height: '8px',
            width: '8px',
            borderRadius: '8px',
            border: `2px solid ${currentTheme === 'light' ? LightColors.gray3 : LightColors.gray3}`,
            backgroundColor: currentTheme === 'light' ? LightColors.white : LightColors.white,
            margin: '-1px 0 0 -4px',
            opacity: 1
        },
        markActive: {
            border: `2px solid ${currentTheme === 'light' ? LightColors.white1 : LightColors.white1}`,
            backgroundColor: currentTheme === 'light' ? LightColors.gray2 : LightColors.gray2,
        },
    })(Slider);

    return (
        <SwapContainer>
            <TableContainer>
                <TableHeader>Swap</TableHeader>
                <TableDescription>Make virtual asset transactions safer.</TableDescription>
                <SwapBody>
                    <SwapInnerContainer>
                        <SwapDetailsContainer>
                            <SwapDetailFrom>From</SwapDetailFrom>
                            <SwapDetailInput type="number" value={fromAmount} onChange={handleFromAmountChange} />
                            <SwapDetailFrom>
                                {'balance : '}
                                <SwapDetailFromBlack>{walletBalance[fromToken].toString().substr(0, isMobile ? 10 : isTablet ? 15 : 99)}</SwapDetailFromBlack>
                            </SwapDetailFrom>
                        </SwapDetailsContainer>
                        <AssetSelector
                            token={fromToken}
                            margin="12px 0"
                            onClick={() => showList(true)}
                        />
                    </SwapInnerContainer>
                    <SwapPercentContainer>
                        <PercentLeftContainer>
                            <PercentLeftItem active={percent === 25} onClick={(e) => handleChangePercentage(e, 25)}>
                                25%
                            </PercentLeftItem>
                            <PercentLeftItem active={percent === 50} onClick={(e) => handleChangePercentage(e, 50)}>
                                50%
                            </PercentLeftItem>
                            <PercentLeftItem active={percent === 75} onClick={(e) => handleChangePercentage(e, 75)}>
                                75%
                            </PercentLeftItem>
                            <PercentLeftItem active={percent === 100} onClick={(e) => handleChangePercentage(e, 100)}>
                                100%
                            </PercentLeftItem>
                        </PercentLeftContainer>
                        <PercentRightContainer>
                            <IOSSlider
                                step={null}
                                valueLabelDisplay="off"
                                aria-label=""
                                value={percent}
                                onChange={handleChangePercentage}
                                marks={marks}
                            />
                        </PercentRightContainer>
                    </SwapPercentContainer>
                    <SwapCenterContainer onClick={handleTokenListSwap}>
                        <SwapCenterIcon src={currentTheme === 'light' ? SwapImgB : SwapImg}></SwapCenterIcon>
                    </SwapCenterContainer>
                    <SwapInnerContainer>
                        <SwapDetailsContainer>
                            <SwapDetailFrom>To</SwapDetailFrom>
                            <SwapDetailInput type="number" value={toAmount} onChange={handleToAmountChange} />
                            <SwapDetailFrom>
                                {'balance : '}
                                <SwapDetailFromBlack>{walletBalance[toToken].toString().substr(0, isMobile ? 10 : isTablet ? 15 : 99)}</SwapDetailFromBlack>
                            </SwapDetailFrom>
                        </SwapDetailsContainer>
                        <AssetSelector
                            token={toToken}
                            margin="12px 0"
                            onClick={() => showToList(true)}
                        />
                    </SwapInnerContainer>
                </SwapBody>
                <SwapButton onClick={() => showConfirmDialog(true)}>Swap</SwapButton>
            </TableContainer>
            <ConfirmDialog
                visible={confirmVisible}
                availableTokens={Object.keys(tokenList)}
                onAgree={() => {
                    showConfirmDialog(false)
                    handleSwap()
                }}
                closeModal={() => showConfirmDialog(false)}
            />
            <AssetDialog
                visible={listVisible}
                availableTokens={Object.keys(tokenList)}
                onSelect={(token) => handleFromToken(token)}
                closeModal={() => showList(false)}
            />
            <AssetDialog
                visible={toListVisible}
                availableTokens={tokenList[fromToken].swapTokens}
                onSelect={(token) => setToToken(token)}
                closeModal={() => showToList(false)}
            />
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </SwapContainer>
    )
}

export default Swap