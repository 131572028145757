import styled from 'styled-components';
import { WindowSize } from '../../util';

export const AddWalletDialogContainer = styled.div((props)=> ({
  display: 'flex',
  flexDirection: 'column',
  width: '650px',
  //height: '600px',
  borderRadius: '6px',
  backgroundColor: props.theme.colors.white,
  padding: '30px',
  [WindowSize.tablet]: {
    width: '500px',
  },
  [WindowSize.mobile]: {
    width: 'calc(100vw - 64px)',
  }
}))

export const DialogHeader = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const DialogTitle = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
`

export const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

export const InputContainer = styled.div(()=> ({
  // height: '56px',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  margin: '24px 0 20px'
}))

export const SelectDiv = styled.div(() =>({
  //width: '50%'
}))


export const InputDivContainer = styled.div(() => ({
  marginLeft: '10px',
  border: '1px solid #DEE2E6',
  padding: '7px 0',
  borderRadius: '3px',
  width: '100%'
}))

export const InputDiv = styled.input`
  //width: 50%;
  width: 95%;
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 14px;
  margin-left: 10px;
    letter-spacing: 0;
    line-height: 21px;
    outline: none;
    border: none;
    flex: 1;
    background-color: ${props => props.theme.colors.transparent};
`

export const SearchIcon = styled(CloseIcon)`
    margin-left: 16px;
    cursor: auto;
`

export const ListContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: scroll;
    flex-direction: column;
`

export const ListItem = styled.div`
    display: flex;
    height: 48px;
    margin-bottom: 4px;
    align-items: center;
    cursor: pointer;
`

export const AssetIcon = styled.img`
    width: 32px;
    height: 32px;
`

export const AssetDetailCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
`

export const AssetDetailSymbol = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
`

export const AssetDetailName = styled.div`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
`

export const AssetDetailAmount = styled(AssetDetailSymbol)`
    color: ${props => props.theme.colors.gray1};
    margin-left: auto;
  `

export const ButtonContainer = styled.div(() => ({
  width: '100%',
  padding: '0 7%',
  textAlign: 'center',
  marginBottom: '40px'
}))

export const AddWalletButton = styled.div((props)=> ({
  //height: '40px',
  width: '100px',
  float: 'right',
  borderRadius: '3px',
  backgroundColor: props.theme.colors.blueButton,
  padding: '8px',
  color: props.theme.colors.white,
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0',
  lineHeight: '21px',
  marginRight: 'auto',
  //marginBottom: '40px',
  //display: 'flex',
  //alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer'
}))