import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from 'react-bootstrap'
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { AuthContext, KaikasContext, MetamaskContext, Web3PluginContext } from '../../contexts';

import EditImg from '../../asset/img/ic_edit_1.svg'
import ArrowDown from '../../asset/img/ic_arrow_down_g.svg'
import ArrowUp from '../../asset/img/ic_arrow_up.svg'
import KlaytnImg from '../../asset/img/ic_klay_s.svg'
import EthImg from '../../asset/img/ic_eth.png'
import BtcImg from '../../asset/img/ic_btc.png'
import VerifiedIcon from '../../asset/img/ic_check_circle.svg'
import IssuedIcon from '../../asset/img/ic_issued.svg'
import DeleteImg from '../../asset/img/ic_delete_1.svg'
import { ERROR_TEXT, tokenList, WindowSize} from '../../util'
import { useWindowSize } from '../../hooks/useWindowSize'
import { toast } from 'react-toastify'
import axios from "axios";
import {useTranslation} from "react-i18next";
import * as WA from '../../reducers/wallets/walletsAction'
import { CheckWalletStatus, CheckWalletStatusFromWallet, SendWalletToCARA } from '../../util/walletUtils';
import { useSelector } from 'react-redux';
import AddWalletDialog from '../../component/AddWalletDialog';
import {
    CaraButton,
    CaraRowContainer,
    DashboardContainer, LoadingContainer,
    NetworkAddress,
    NetworkButtons,
    NetworkContainer,
    NetworkCopy,
    NetworkIcon,
    NetworkIconDiv,
    NetworkTitle, NetworkWrap, NetworkWrapType,
    NFTIssuedIcon,
    PageDescription,
    PageTitle,
    RowContainer,
    RowFlexInner,
    RowInnerContainer,
    RowTopDiv,
    TableHeader,
    UserContainer,
    UserDetailsRowContainer,
    UserWalletContainerHeadingOthers,
    UserWalletContainerRowAddress,
    UserWalletContainerRowImg, UserWalletContainerRowStatus,
    UserWalletContainerRowTime,
    UserWalletListContainer,
    UserWalletsContainer,
    UserWalletsContainerHeader,
    UserWalletsContainerHeadingWallet,
    UserWalletsContainerRow, UserWalletsNFTIssuedIcon, UserWalletsVerifiedIcon,
    WalletAddButton,
    WalletAddDescription,
    WalletAddressDiv,
    WalletAddressHeader,
    WalletBtnContainer,
    WalletButton,
    WalletContainer,
    WalletHistoryBody,
    WalletHistoryBodyStatus,
    WalletHistoryBodyTime,
    WalletHistoryBodyTitle,
    WalletHistoryContainer,
    WalletHistoryExpand,
    WalletHistoryHeader,
    WalletHistoryHeaderOthers,
    WalletHistoryHeaderTitle,
    WalletHistoryMobileTime,
    WalletHistoryMobileWrap,
    WalletHistoryTable,
    WalletHistoryTitle,
    WalletIcon,
    WalletVerifiedIcon,
} from './style';

const WalletImgMap = {
    klay: KlaytnImg,
    eth: EthImg,
    btc: BtcImg
}

const ChainNameMap = {
    klay: 'Klaytn',
    eth: 'Ethereum',
    btc: 'Bitcoin'
}

const ChainExplorerMap = {
    klay: 'https://scope.klaytn.com/account/',
    eth: 'https://etherscan.io/address/',
    btc: 'https://www.blockchain.com/btc/address/'
}

const Dashboard = () => {
    const [expand, setExpand] = useState(false)
    const [caraLoading, setCaraLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [wallets, setWallets] = useState([])
    const [primaryAddress, setPrimaryAddress] = useState('')
    const [kyc, setKyc] = useState({})
    const { updateUser, handleError } = useContext(AuthContext)
    const user = JSON.parse(localStorage.getItem('user-data'))
    const { connectKlaytnWallet, disconnectKlaytnWallet, setWalletsFromStorage, connectMetamaskWallet, disconnectMetamaskWallet, updateWalletStatus, expandWallet, removeWallet } = useContext(Web3PluginContext)
    const { isMobile, isTablet } = useWindowSize()
    let history = useHistory()
    const { t } = useTranslation()
    const connectedWallets = useSelector(state => state.wallets.connectedWallets)
    const klaytnWallet = useSelector(state => state.wallets.klaytnWallet)
    const metamaskWallet = useSelector(state => state.wallets.metamaskWallet)
    // const [pendingWallets, setPendingWallets] = useState([])
    const walletAddrRef = useRef(null)
    const userRef = useRef(null)
    const walletsRef = useRef(null)
    const networkRef = useRef(null)
    walletsRef.current = wallets
    // walletAddrRef.current = walletAddr
    userRef.current = user

    // networkRef.current = networkName

    const clickExpand = () => {
        setExpand(!expand)
    }

    const clickExpandHistory = (wallet) => {
        expandWallet(wallet)
    }


    const handleCaraButton = async (wallet) => {
        if (wallet.address && wallet.address.length > 0) {
            if (wallet.caraStatus === 'PENDING' || caraLoading) {
                return
            }
            setCaraLoading(true)
            const caraWallet = await SendWalletToCARA(wallet)
            updateWalletStatus(caraWallet)
            // const updatedWallets = pendingWallets
            // updatedWallets.push(caraWallet)
            // setPendingWallets(updatedWallets)
            setCaraLoading(false)
        } else {
            //connectKlaytnWallet()
        }
    }

    const handleMintNFT = async (address) => {
        try {
            const userStorage = JSON.parse(localStorage.getItem('user-data'))
            if (userStorage && userStorage.isKycVerified && address && address.length > 0) {
                const nftInfo = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/nft/mint/${userStorage._id}`, { address: walletAddrRef.current.toLowerCase() },
                { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                if (nftInfo.data) {
                    toast.success('NFT minted successfully', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    })
                    updateUser({
                        ...user,
                        tokenId: nftInfo.data.tokenId,
                        nftIssued: true,
                        isKycVerified: true,
                    })
                    setPrimaryAddress(walletAddrRef.current)
                }
            } else if ( !userRef.current.isKycVerified ) {
                toast.error('KYC is not verified', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                })
            }
        } catch (error) {
          await handleError(error)
        }
    }

    const updateWalletInfo = async () => {
        try {
            const connectedWallets = JSON.parse(localStorage.getItem('connectedWallets'))
            const pendingWallets = connectedWallets.filter(wallet => wallet.caraStatus === 'PENDING')
            //console.log(pendingWallets)
            if (pendingWallets.length > 0) {
                for (let i = 0; i < pendingWallets.length; i++) {
                    const wallet = pendingWallets[i]
                    const status = await CheckWalletStatus(wallet.address, wallet.chain)
                    wallet.caraStatus = status
                    if (status !== 'PENDING') {
                        await updateWalletStatus(wallet)
                    }
                }
            }
        } catch (error) {
            //console.log(error)
          await handleError(error)
        }
    }


    const copyWalletAddr = (address) => {
        const el = document.createElement('input')
        el.value = address
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        toast.success(`${address.substring(0,5)}...${address.substring(address.length - 4)} copied successfully`, {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        })
    }

    // useEffect(() => {
    //     const pendingWalletsUpdated = connectedWallets.filter(wallet => wallet.caraStatus === 'PENDING')
    //     setPendingWallets(pendingWalletsUpdated)
    //     console.log(pendingWallets)
    //     // console.log(connectedWallets)
    // }, [connectedWallets])

    useEffect(() => {
        async function updateWallet(){
            const interval = setInterval(() => {
                updateWalletInfo()
            }, [30000])

            return () => {
                clearInterval(interval)
            }
        }
        updateWallet().then()
        // connectWallet()

    }, [connectedWallets])

    const addWallet = () => {
        setVisible(true)
    }

    const closeModal = () => {
        setVisible(false)
    }

    const disconnectWallet = (wallet) => {
        if (wallet.address === klaytnWallet.address) {
            disconnectKlaytnWallet()
        } else if (wallet.address === metamaskWallet.address) {
            disconnectMetamaskWallet()
        } else {
            removeWallet(wallet)
        }
    }

    const updateWallets = async () => {
        try {
            const userStorage = JSON.parse(localStorage.getItem('user-data'))
            const walletArray = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/wallet/wallets`, { },
              { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
            // console.log(walletArray.data)
            let walletList = []
            let primary = ''
            let list = walletArray.data
            for (let i=0;i<list.wallets.length;i++){
                if (list.wallets[i].isPrimary) {
                    primary = list.wallets[i].address
                }
                walletList.push({
                    address: list.wallets[i].address,
                    caraIndexed: list.wallets[i].caraIndexed,
                    caraPending: list.wallets[i].caraPending,
                    //caraStatus: list.wallets[i].caraStatus,
                    caraStatus: CheckWalletStatusFromWallet(list.wallets[i]),
                    createdAt: list.wallets[i].createdAt,
                    nftId: list.wallets[i].nftId,
                    privateKey: list.wallets[i].privateKey,
                    publicKey: list.wallets[i].publicKey,
                    qrImage: list.wallets[i].qrImage,
                    updatedAt: list.wallets[i].updatedAt,
                    userId: list.wallets[i].userId,
                    isPrimary: list.wallets[i].isPrimary,
                    src: WalletImgMap[list.wallets[i].blockchain.toLowerCase()],
                    chain: ChainNameMap[list.wallets[i].blockchain.toLowerCase()],
                    explorer: ChainExplorerMap[list.wallets[i].blockchain.toLowerCase()]
                })
            }
            // walletList.push()
            setWallets(walletList)
            setPrimaryAddress(primary)
            // console.log(wallets)

        } catch (error) {
            await handleError(error)
        }
    }

    // useEffect(() => {
    //    // console.log(wallets)
    // }, [wallets])

    useEffect( () => {
        try {
            updateWallets().then()
        } catch (error) {
            //
        }
    }, [connectedWallets])

    useEffect(() => {
        try {
            const userStorage = JSON.parse(localStorage.getItem('user-data'))
            async function fetchKYCDetails() {
                const kycResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/kyc`, { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                // console.log(kycResponse.data)
                let kycDetails = {}
                if (kycResponse.data) {
                    kycDetails.result = kycResponse.data.result
                    kycDetails.details = kycResponse.data.resultDetails
                }
                setKyc(kycDetails)
            }

            async function fetchUserDetails() {
                const userResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile/${userStorage._id}`, { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                // console.log(kycResponse.data)
                // let userDetails = {}
                if (userResponse.data) {
                    updateUser({
                        ...user,
                      //  isPrimary: (userResponse.data.primaryWalletAddress && userResponse.data.primaryWalletAddress === walletAddr),
                        isKycVerified: userResponse.data.kycVerified,
                        nftIssued: userResponse.data.nftIssued
                    })
                }
            }
            fetchKYCDetails().then(updateWallets).then(fetchUserDetails)
        } catch (error) {
            handleError(error)
        }
    }, [])

    useEffect(() => {
        //console.log(klaytnWallet)
        setWalletsFromStorage()

    }, [])


    return (
        <DashboardContainer>
            <PageTitle>{t('dashboard.title')}</PageTitle>
            <PageDescription> {t('nft.user.head')} </PageDescription>
            <UserContainer>
                <UserDetailsRowContainer>
                    <WalletAddressHeader> NFT : </WalletAddressHeader>
                    <WalletAddressHeader details={true}> {user.nftIssued ? t('nft.issued') : "Not Issued"} </WalletAddressHeader>
                </UserDetailsRowContainer>
                <UserDetailsRowContainer>
                    <WalletAddressHeader> KYC : </WalletAddressHeader>
                    <WalletAddressHeader details={true}> {user.isKycVerified ? t('nft.user.verified') : t('nft.user.not_verified')} </WalletAddressHeader>
                </UserDetailsRowContainer>
                {
                    kyc.details && kyc.details.length > 0 ?
                      <UserDetailsRowContainer>
                          <WalletAddressHeader> {t('dashboard.reason')} : </WalletAddressHeader>
                          <WalletAddressHeader details={true}> {kyc.details} </WalletAddressHeader>
                      </UserDetailsRowContainer>
                      :
                      <>
                      </>
                }
            </UserContainer>
            <PageDescription>{t('dashboard.subheading')}</PageDescription>
            <>
                { connectedWallets && connectedWallets.length > 0 ?
                    // console.log(walletAddr)
                    (

                      <>
                          {
                              connectedWallets.map((wallet, index) => {
                                  return(
                                    <WalletContainer>
                                        <WalletAddressHeader>{t('dashboard.wallet.address')}</WalletAddressHeader>
                                        <RowTopDiv>
                                            <WalletAddressDiv>{wallet.address}</WalletAddressDiv>
                                            {
                                                user.nftId ?
                                                  (
                                                    <NFTIssuedIcon src={IssuedIcon} />
                                                  ) : wallet.caraStatus === 'RELEASED' ? (
                                                    <>
                                                        <WalletVerifiedIcon src={VerifiedIcon} />
                                                    </>
                                                  ) : (
                                                    <>
                                                    </>
                                                  )
                                            }

                                            {/*<WalletIcon src={EditImg} />*/}
                                            <WalletIcon onClick={() => disconnectWallet(wallet)} src={DeleteImg} />
                                        </RowTopDiv>
                                        <WalletBtnContainer>
                                            <WalletButton uppercase>{wallet.networkName}</WalletButton>
                                            <WalletButton onClick={() => copyWalletAddr(wallet.address)}>{t('dashboard.wallet.copy')}</WalletButton>
                                            <WalletButton onClick={() => window.open(`${wallet.explorer}${wallet.address}`, "_blank")}>
                                                {t('dashboard.wallet.explorer')}
                                            </WalletButton>
                                        </WalletBtnContainer>
                                        <CaraRowContainer>
                                            {
                                                wallet.caraStatus === 'RELEASED' ?
                                                  (
                                                    <CaraButton
                                                      disabled={!(wallet.address) || user.nftIssued}
                                                      display={wallet.address}
                                                      onClick={() => { handleMintNFT(wallet.address) }}
                                                    >
                                                        {!user.nftIssued ? t('dashboard.buttons.mint') : "NFT Already Issued" }
                                                    </CaraButton>
                                                  ) : (
                                                    <CaraButton
                                                      disabled={wallet.address&& (wallet.caraStatus === 'PENDING' || wallet.caraStatus === 'FAILED' || caraLoading)}
                                                      display={wallet.address}
                                                      onClick={() => { handleCaraButton(wallet) }}
                                                    >
                                                        {wallet.caraStatus !== 'NOT_PRESENT' ? wallet.caraStatus === 'PENDING' ? t('dashboard.buttons.pending') : wallet.caraStatus === 'RELEASED' ? t('dashboard.buttons.mint') : t('dashboard.buttons.failed') : t('dashboard.buttons.verify') }
                                                    </CaraButton>
                                                  )
                                            }

                                        </CaraRowContainer>
                                        <WalletHistoryContainer>
                                            <WalletHistoryTitle>{t('dashboard.wallet.history')}</WalletHistoryTitle>
                                            <WalletHistoryExpand src={wallet.expanded ? ArrowUp : ArrowDown} onClick={() => clickExpandHistory(wallet)}/>

                                        </WalletHistoryContainer>
                                        <WalletHistoryTable expand={wallet.expanded}>
                                            <WalletHistoryHeader>
                                                <WalletHistoryHeaderTitle> {t('dashboard.table.title')} </WalletHistoryHeaderTitle>
                                                <WalletHistoryHeaderOthers> {t('dashboard.table.time')} </WalletHistoryHeaderOthers>
                                                <WalletHistoryHeaderOthers> {t('dashboard.table.status')} </WalletHistoryHeaderOthers>
                                            </WalletHistoryHeader>
                                            {
                                                wallet.caraStatus === 'RELEASED' || wallet.caraStatus === 'PENDING' || caraLoading ?
                                                  (
                                                    <>
                                                        <>
                                                            {
                                                                wallet.caraStatus === 'PENDING' ?
                                                                  (
                                                                    <>
                                                                        {
                                                                            isMobile ?
                                                                              (
                                                                                <WalletHistoryBody>
                                                                                    <WalletHistoryMobileWrap>
                                                                                        {t('dashboard.message.started')}
                                                                                        <WalletHistoryMobileTime>
                                                                                            {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''}
                                                                                        </WalletHistoryMobileTime>
                                                                                    </WalletHistoryMobileWrap>
                                                                                    <WalletHistoryBodyStatus> {t('dashboard.message.progress')} </WalletHistoryBodyStatus>
                                                                                </WalletHistoryBody>

                                                                              ) : (
                                                                                <WalletHistoryBody>
                                                                                    <WalletHistoryBodyTitle> {t('dashboard.message.started')} </WalletHistoryBodyTitle>
                                                                                    <WalletHistoryBodyTime> {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                    <WalletHistoryBodyStatus> {t('dashboard.message.progress')} </WalletHistoryBodyStatus>
                                                                                </WalletHistoryBody>

                                                                              )
                                                                        }

                                                                    </>
                                                                  ) : wallet.caraStatus === 'RELEASED' ?
                                                                    (
                                                                      <>
                                                                          {
                                                                              isMobile ?
                                                                                (
                                                                                  <>
                                                                                      {
                                                                                          wallet.address === primaryAddress ?
                                                                                            (
                                                                                              <>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryMobileWrap>
                                                                                                          NFT Issued
                                                                                                          <WalletHistoryMobileTime>
                                                                                                              {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''}
                                                                                                          </WalletHistoryMobileTime>
                                                                                                      </WalletHistoryMobileWrap>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryMobileWrap>
                                                                                                          {t('dashboard.message.finish')}
                                                                                                          <WalletHistoryMobileTime>
                                                                                                              {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''}
                                                                                                          </WalletHistoryMobileTime>
                                                                                                      </WalletHistoryMobileWrap>
                                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.verified')} </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryMobileWrap>
                                                                                                          {t('dashboard.message.started')}
                                                                                                          <WalletHistoryMobileTime>
                                                                                                              {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''}
                                                                                                          </WalletHistoryMobileTime>
                                                                                                      </WalletHistoryMobileWrap>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                              </>
                                                                                            ) : (
                                                                                              <>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryMobileWrap>
                                                                                                          {t('dashboard.message.finish')}
                                                                                                          <WalletHistoryMobileTime>
                                                                                                              {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''}
                                                                                                          </WalletHistoryMobileTime>
                                                                                                      </WalletHistoryMobileWrap>
                                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.verified')} </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryMobileWrap>
                                                                                                          {t('dashboard.message.started')}
                                                                                                          <WalletHistoryMobileTime>
                                                                                                              {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''}
                                                                                                          </WalletHistoryMobileTime>
                                                                                                      </WalletHistoryMobileWrap>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                              </>
                                                                                            )
                                                                                      }
                                                                                  </>

                                                                                ) : (
                                                                                  <>
                                                                                      {
                                                                                          wallet.address === primaryAddress ?
                                                                                            (
                                                                                              <>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryBodyTitle> NFT Issued </WalletHistoryBodyTitle>
                                                                                                      <WalletHistoryBodyTime> {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryBodyTitle> {t('dashboard.message.finish')} </WalletHistoryBodyTitle>
                                                                                                      <WalletHistoryBodyTime> {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.verified')} </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryBodyTitle> {t('dashboard.message.started')} </WalletHistoryBodyTitle>
                                                                                                      <WalletHistoryBodyTime> {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                              </>
                                                                                            ) : (
                                                                                              <>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryBodyTitle> {t('dashboard.message.finish')} </WalletHistoryBodyTitle>
                                                                                                      <WalletHistoryBodyTime> {wallet.updatedTime && wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[0] : ''} {wallet.updatedTime !== '' ? wallet.updatedTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.verified')} </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                                  <WalletHistoryBody>
                                                                                                      <WalletHistoryBodyTitle> {t('dashboard.message.started')} </WalletHistoryBodyTitle>
                                                                                                      <WalletHistoryBodyTime> {wallet.createdTime && wallet.createdTime !== '' ? wallet.createdTime.split('T')[0] : ''} {wallet.createdTime !== '' ? wallet.createdTime.split('T')[1].substring(0,5) : ''} </WalletHistoryBodyTime>
                                                                                                      <WalletHistoryBodyStatus> Done </WalletHistoryBodyStatus>
                                                                                                  </WalletHistoryBody>
                                                                                              </>
                                                                                            )
                                                                                      }
                                                                                  </>
                                                                                )
                                                                          }
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                          {
                                                                              isMobile ?
                                                                                (
                                                                                  <>
                                                                                      <WalletHistoryMobileWrap>
                                                                                          {t('dashboard.message.fail')}
                                                                                          <WalletHistoryMobileTime>
                                                                                              2022.02.11 15:03
                                                                                          </WalletHistoryMobileTime>
                                                                                      </WalletHistoryMobileWrap>
                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.failMessage')} </WalletHistoryBodyStatus>
                                                                                  </>

                                                                                ) : (
                                                                                  <>
                                                                                      <WalletHistoryBodyTitle> {t('dashboard.message.fail')} </WalletHistoryBodyTitle>
                                                                                      <WalletHistoryBodyTime> 2022.02.11 15:03 </WalletHistoryBodyTime>
                                                                                      <WalletHistoryBodyStatus> {t('dashboard.message.failMessage')} </WalletHistoryBodyStatus>
                                                                                  </>

                                                                                )
                                                                          }
                                                                      </>
                                                                    )

                                                            }
                                                        </>
                                                    </>
                                                  ) : (
                                                    <>
                                                    </>
                                                  )
                                            }
                                        </WalletHistoryTable>
                                    </WalletContainer>
                                  )

                              })
                          }
                          {
                              klaytnWallet.address === '' && metamaskWallet.address === ''?
                                (
                                  <WalletContainer nowallet={true}>
                                      <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>
                                      <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>
                                      <WalletAddButton onClick={() => connectMetamaskWallet()}>Connect Metamask Wallet</WalletAddButton>
                                      <WalletAddButton onClick={() => connectKlaytnWallet()}>Connect Klaytn Wallet</WalletAddButton>
                                      <WalletAddButton onClick={() => addWallet()}>Add Wallet Manually </WalletAddButton>
                                  </WalletContainer>
                                ) :
                                <>
                                    {
                                        klaytnWallet.address === '' ?
                                          (
                                            <WalletContainer nowallet={true}>
                                                <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>
                                                <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>
                                                <WalletAddButton onClick={() => connectKlaytnWallet()}>Connect Klaytn Wallet</WalletAddButton>
                                                <WalletAddButton onClick={() => addWallet()}>Add Wallet Manually </WalletAddButton>
                                            </WalletContainer>
                                          ) :
                                          <>
                                              {
                                                  metamaskWallet.address === '' ?
                                                    (
                                                      <WalletContainer nowallet={true}>
                                                          <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>
                                                          <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>
                                                          <WalletAddButton onClick={() => connectMetamaskWallet()}>Connect Metamask Wallet</WalletAddButton>
                                                          <WalletAddButton onClick={() => addWallet()}>Add Wallet Manually </WalletAddButton>
                                                      </WalletContainer>
                                                    ) :
                                                    <>
                                                        <WalletContainer nowallet={true}>
                                                            <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>
                                                            <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>
                                                            <WalletAddButton onClick={() => addWallet()}>Add Wallet Manually </WalletAddButton>
                                                        </WalletContainer>
                                                        {/*<WalletContainer nowallet={true}>*/}
                                                        {/*    <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>*/}
                                                        {/*    <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>*/}
                                                        {/*    <WalletAddButton>{t('dashboard.wallet.connect')}</WalletAddButton>*/}
                                                        {/*</WalletContainer>*/}
                                                    </>
                                              }
                                          </>
                                    }
                                </>
                          }
                          {/*<WalletContainer nowallet={true}>*/}
                          {/*    <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>*/}
                          {/*    <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>*/}
                          {/*    <WalletAddButton onClick={() => connectMetamaskWallet()}>{t('dashboard.wallet.connect')}</WalletAddButton>*/}
                          {/*</WalletContainer>*/}
                      </>

                    ) :
                  (
                    <WalletContainer nowallet>
                        <TableHeader nowallet>{t('dashboard.wallet.add')}</TableHeader>
                        <WalletAddDescription>{t('dashboard.wallet.description')}</WalletAddDescription>
                        <WalletAddButton onClick={() => connectMetamaskWallet()}>Connect Metamask Wallet</WalletAddButton>
                        <WalletAddButton onClick={() => connectKlaytnWallet()}>Connect Klaytn Wallet</WalletAddButton>
                        <WalletAddButton onClick={() => addWallet()}>Add Wallet Manually </WalletAddButton>
                    </WalletContainer>
                )}

            </>
            <PageDescription nowallet={wallets.length === 0}>{t('dashboard.wallet.list')}</PageDescription>
            {
                !isMobile ? (
                        <UserWalletListContainer nowallet={wallets.length === 0}>
                            <RowContainer>

                                <RowInnerContainer>

                                    <RowFlexInner>
                                        {wallets.map((wallet, index) => {
                                            return(
                                                <NetworkContainer>
                                                    <NetworkIconDiv>
                                                        <NetworkIcon src={wallet.src}></NetworkIcon>
                                                    </NetworkIconDiv>
                                                    <NetworkTitle>{wallet.chain}</NetworkTitle>
                                                    <NetworkAddress>{wallet.address.substring(0,3)}...{wallet.address.substring(wallet.address.length-4)}</NetworkAddress>
                                                    <NetworkButtons>
                                                        <NetworkCopy onClick={() => copyWalletAddr(wallet.address)}>{t('dashboard.wallet.copy')}</NetworkCopy>
                                                        <NetworkCopy onClick={() => window.open(`${wallet.explorer}${wallet.address}`, "_blank")}>{t('dashboard.wallet.explorer')}</NetworkCopy>
                                                    </NetworkButtons>
                                                </NetworkContainer>
                                            )
                                        })}


                                    </RowFlexInner>
                                </RowInnerContainer>
                            </RowContainer>
                            <UserWalletsContainer hasWallets={wallets.length > 0}>
                                <UserWalletsContainerHeader>
                                    <UserWalletsContainerHeadingWallet> {t('dashboard.wallet.address')} </UserWalletsContainerHeadingWallet>
                                    <UserWalletContainerHeadingOthers> {t('dashboard.table.time')} </UserWalletContainerHeadingOthers>
                                    <UserWalletContainerHeadingOthers> {t('dashboard.table.status')} </UserWalletContainerHeadingOthers>
                                </UserWalletsContainerHeader>
                                {
                                    wallets.map((wallet, index) => {
                                        return (
                                            <UserWalletsContainerRow>
                                                <UserWalletContainerRowAddress>
                                                    <UserWalletContainerRowImg src={wallet.src} />
                                                    {wallet.address}
                                                </UserWalletContainerRowAddress>
                                                <UserWalletContainerRowTime> {wallet.updatedAt.split('T')[0]} {wallet.updatedAt.split('T')[1].substring(0,5)} </UserWalletContainerRowTime>
                                                <UserWalletContainerRowStatus> {wallet.isPrimary ? t('nft.issued') : wallet.caraStatus === 'RELEASED' ? t('nft.user.verified') : wallet.caraStatus === 'PENDING' ? t('dashboard.buttons.pending') : t('dashboard.buttons.failed')} </UserWalletContainerRowStatus>
                                            </UserWalletsContainerRow>
                                        )
                                    })
                                }
                            </UserWalletsContainer>
                        </UserWalletListContainer>
                ) : (
                    <RowFlexInner>
                        {wallets.map((wallet, index) => {
                            return(
                                <NetworkContainer>

                                    {/*<NetworkTitle>Klaytn</NetworkTitle>*/}
                                    <NetworkAddress>
                                        <NetworkIcon src={wallet.src} />
                                        <NetworkWrap>
                                            <NetworkWrapType>
                                                {wallet.chain}
                                            </NetworkWrapType>
                                            {wallet.address.substring(0,4)}...{wallet.address.substring(wallet.address.length-10)}
                                        </NetworkWrap>
                                        {
                                            wallet.isPrimary ?
                                                (
                                                    <UserWalletsNFTIssuedIcon src={IssuedIcon} />
                                                ) : wallet.caraStatus ?
                                                    (
                                                        <UserWalletsVerifiedIcon src={VerifiedIcon} />
                                                    ) : (
                                                        <>
                                                        </>
                                                    )
                                        }
                                    </NetworkAddress>

                                </NetworkContainer>
                            )
                        })}


                    </RowFlexInner>
                )
            }
            <AddWalletDialog
              visible={visible}
              closeModal={closeModal}
              //addManualWallet={addManualWallet}
                />

            {(loading || caraLoading) && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </DashboardContainer>
    )
}

export default Dashboard