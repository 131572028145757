import { combineReducers } from 'redux';
import usersReducer from '../reducers/user/userReducer'
import wallets from '../reducers/wallets/walletsReducer'

const appReducer = combineReducers({
  usersReducer,
  wallets
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
