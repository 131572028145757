import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route} from 'react-router-dom'
import {toast, ToastContainer} from 'react-toastify'
import { useHistory } from 'react-router'
import styled, { ThemeProvider } from 'styled-components'
import { Spinner } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import Home from './container/Home'
import SignUp from './container/SignUp'
import Profile from './container/Profile'
import {AuthContext, KaikasContext, Web3PluginContext} from './contexts'
import Header from './component/Header'
import Dashboard from './container/Dashboard'
import NotFound from './container/NotFound'
import CodeVerification from './container/CodeVerification'
import ResetPassword from './container/ResetPassword'
import { useMetamask } from './hooks/useMetamask'
import Swap from './container/Swap'
import {DarkColors, ERROR_RESPONSE, ERROR_TEXT, LightColors} from './util'
import ZignSec from './container/ZignSec'
import SessionTimeout from './hooks/useSessionTimeout'
import axios from 'axios'
import SignIn from './container/SignIn'
import SearchNFT from './container/SearchNFT'
import KYCDefi from './container/KYCDefi'
import ComingSoon from './container/ComingSoon'
import { useWindowSize } from './hooks/useWindowSize'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import {useKaikas} from "./hooks/useKaikas";
import IdentityCertificate from "./container/IdentityCertificate";
import KYC from "./container/KYC";
import { useTranslation } from 'react-i18next';
import ZignSecComplete from './container/ZignSecComplete';
import useSessionTimeout from './hooks/useSessionTimeout';
import { useWeb3Plugin } from './hooks/useWeb3Plugin';
import { RemoveAllWallets } from './reducers/wallets/walletsAction';
import { useSelector } from 'react-redux';
import ZignSecInstructions from './container/ZignSecInstructions';
import Terms from './container/Terms';

const NEW_USER = {
  firstName: '',
  lastName: '',
  username: '',
  authToken: '',
  email: '',
  password: '',
  token: '',
  nftIssued: false,
  isVerified: false,
  isZignSecVerified: false,
  isKycVerified: false,
  address: '',
  caraStatus: false,
  caraPending: false,
  caraIndexed: false,
  tokenId: '',
  createdTime: '',
  updatedTime: '',
  isPrimary: false,
}

const AppContainer = styled.div`
  background-color: ${props => props.theme.currentTheme === 'light' ? "#F8F9FA" : props.theme.colors.walletBg};
  min-height: 100vh;
  position: absolute;
  left: 0;
  right: 0;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

function App() {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
  const [user, setUser] = useState(NEW_USER)
  const [wallets, setWallets] = useState([])
  const [Colors, setColors] = useState(LightColors)
  const [currentTheme, setThemeTitle] = useState('light')
  const [scrollPosition, setScrollPosition] = useState(0);
  // const { walletAddr, networkId, networkName, walletBalance, connecting, updateWalletAddr, connectWallet, disconnectWallet, updateBalance, updateNetwork } = useKaikas()
  const { connecting, connectKlaytnWallet, disconnectKlaytnWallet, setWalletsFromStorage, connectMetamaskWallet, disconnectMetamaskWallet, updateWalletStatus, expandWallet, addManualWallet, removeWallet } = useWeb3Plugin()
  let history = useHistory()
  const connectedWallets = useSelector(state=>state.connectedWallets)
  const { t } = useTranslation()
  let location = useLocation()
  //console.log(user)

  const handleScroll = () => {
      const position = window.pageYOffset
      setScrollPosition(position)
     // console.log(`${position} \  ${window.innerHeight}`)
  }

  const updateUser = (val) => {
    setUser(val)
    localStorage.setItem('user-data', JSON.stringify(val))
    // console.log(localStorage)
  }

  const logoutUser = async (val) => {
    setUser(NEW_USER)
    localStorage.setItem('user-data', JSON.stringify(NEW_USER))
    localStorage.setItem('connectedWallets', '[]')
    localStorage.setItem('klaytnWallet', '')
    localStorage.setItem('metamaskWallet', '')
    setColors(LightColors)
    setThemeTitle('light')
    await RemoveAllWallets(connectedWallets)
    // sessionStorage.setItem('connected_wallet', '')
    // sessionStorage.setItem('balance', '0')
    // sessionStorage.setItem('network', '')
    history.replace('/')
  }

  const handleError = async  (error) => {
    if (error.response.data && error.response.data.message) {
      if (error.response.data.message === 'jwt expired') {
        await logoutUser()
        toast.error(ERROR_RESPONSE['23'], {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(t(`error.${error.response.data.message}`), {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error(ERROR_TEXT['10'], {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }

  }

  const toggleTheme = () => {
    if (currentTheme === 'light') {
      setColors(DarkColors)
      setThemeTitle('dark')
    } else {
      setColors(LightColors)
      setThemeTitle('light')
    }
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user-data')) || NEW_USER)
  }, [])


  return (
    <AuthContext.Provider value={{ user, updateUser, logoutUser, toggleTheme, currentTheme, wallets, handleError, useSessionTimeout }}>
      <Web3PluginContext.Provider value={{ connecting, connectKlaytnWallet, disconnectKlaytnWallet, setWalletsFromStorage, connectMetamaskWallet, disconnectMetamaskWallet, updateWalletStatus, expandWallet, addManualWallet, removeWallet }}>
        <ThemeProvider theme={{ colors: Colors, currentTheme }}>
          <Header scrollPosition={location.pathname !== '/' ? 1000 : scrollPosition} />
          <AppContainer>
            <Switch>
              {/*<Route exact path='/'>*/}
              {/*  <Home scrollPosition={scrollPosition}></Home>*/}
              {/*</Route>*/}
              <Route exact path='/'>
                <SignIn></SignIn>
              </Route>
              <Route path='/identity-certificate'>
                <IdentityCertificate></IdentityCertificate>
              </Route>
              <Route exact path='/search-nft'>
                <SearchNFT></SearchNFT>
              </Route>
              <Route exact path='/kyc'>
                <KYC></KYC>
              </Route>
              <Route exact path='/zignsec-complete'>
                <ZignSecComplete></ZignSecComplete>
              </Route>
              <Route exact path='/zignsec-instructions'>
                <ZignSecInstructions></ZignSecInstructions>
              </Route>
              <Route exact path='/sign-in'>
                <SignIn></SignIn>
              </Route>
              <Route exact path='/verify'>
                <CodeVerification></CodeVerification>
              </Route>
              <Route exact path='/reset-password'>
                <ResetPassword></ResetPassword>
              </Route>
              <Route exact path='/sign-up'>
                <SignUp></SignUp>
              </Route>
              <Route exact path='/terms'>
                <Terms></Terms>
              </Route>
              {user.isVerified && (
                <Route exact path='/dashboard'>
                  <Dashboard></Dashboard>
                </Route>
              )}
              {user.isVerified && (
                <Route exact path='/profile'>
                  <Profile></Profile>
                </Route>
              )}
              {user.isVerified && (
                <Route exact path='/swap'>
                  <Swap></Swap>
                </Route>
              )}
              {user.isVerified && (
                <Route exact path='/zignsec-verification'>
                  <ZignSec></ZignSec>
                </Route>
              )}
              <Route path='/coming-soon'>
                <ComingSoon></ComingSoon>
              </Route>
              <Route path='*'>
                <NotFound></NotFound>
              </Route>
            </Switch>
            {connecting && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
            <SessionTimeout />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
          </AppContainer>
        </ThemeProvider>
      </Web3PluginContext.Provider>
    </AuthContext.Provider>
  )
}

export default App
