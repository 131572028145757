import React, { useContext, useState, useRef } from 'react';
import sha256 from 'crypto-js/sha256';
import styled, { css } from 'styled-components';
import { Spinner } from 'react-bootstrap'
import {
  useHistory,
} from 'react-router-dom'
import Axios from 'axios'
import { AuthContext } from '../../contexts';
import HomeImg from '../../asset/img/img_signin.png'
import { emailValidator, ERROR_TEXT, passwordValidator, WindowSize } from '../../util';
import InputField from '../../component/InputField';
import SelectField from '../../component/SelectField';
import { toast } from 'react-toastify';
import { countries } from '../../util/country';

const SignUpContainer = styled.div(() => ({
    padding: '100px 100px 0',
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [WindowSize.tablet]: {
        flexDirection: 'column',
        padding: '0 50px 50px',
    }
}))

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 440px;
    align-self: center;
`

const SignInBtn = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 5px 0 5px 20px;
    margin-left: auto;
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
`

const SignInLink = styled.div`
    color: ${props => props.theme.colors.blueHeader};
    margin-left: 5px;
`

const PageTitle = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    position: relative;
`

const PageDescription = styled.div`
    color: ${props => props.theme.colors.gray2};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 12px 0 16px;
    text-align: center;
`

const SubmitBtn = styled.div`
    box-sizing: border-box;
    height: 49px;
    border: 1px solid ${props => props.theme.colors.blueHeader};
    border-radius: 6px;
    background-color: ${props => props.transparent ? props.theme.colors.white : props.theme.colors.blueHeader};
    color: ${props => props.transparent ? props.theme.colors.blueHeader : props.theme.colors.white};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-bottom: 15px;
`

const BasisWidget = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const SignUp = () => {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    const [basisVisible, showBasisVisible] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [residentialCountry, setResidentialCountry] = useState('')
    const [citizenshipCountry, setCitizenshipCountry] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const { user, updateUser } = useContext(AuthContext)
    let history = useHistory()
    const userRef = useRef(null)
    userRef.current = user

    const handleSubmit = async event => {
        if (!emailValidator(email)) {
            setValidated(false)
            toast.error(ERROR_TEXT['12'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (!passwordValidator(password)) {
            setValidated(false)
            toast.error(ERROR_TEXT['13'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (password !== confirmPassword) {
            setValidated(false)
            toast.error(ERROR_TEXT['16'], {
              position: "top-right",
              autoClose: 10000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return
        }
        if (name.length === 0
            || residentialCountry.length === 0
            || citizenshipCountry.length === 0) {
            setValidated(false);
            return;
        }
        setValidated(true);
        setLoading(true)
        try {
            const names = (name || '').split(' ')
            let params = {
                firstName: names[0],
                lastName: names.length > 1 ? names[1] : '',
                email: email || '',
                password: password || '',
                username: name || '',
            }
            updateUser(params)
            // showBasisVisible(true)
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/register`, {
                ...params,
            }, { headers: { 'Content-Type': 'Application/json' } });

            if (res && res.data) {
                if (res.data.status === 0) {
                    toast.error(res.data.message || ERROR_TEXT['10'], {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                    setLoading(false)
                } else {
                    updateUser({
                        email
                    });
                    history.push('/verify')
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }

    return (
        <SignUpContainer>
            <FormContainer>
                <PageTitle>
                    Sign Up
                    <SignInBtn onClick={() => history.push('/sign-in')}>
                        or
                        <SignInLink>Sign In</SignInLink>
                    </SignInBtn>
                </PageTitle>
                <PageDescription>Welcome to Compass</PageDescription>
                <InputField
                    label="Legal Name"
                    type="text"
                    placeholder="e.g. Joe"
                    valid={validated || name.length > 0}
                    error="Invalid name"
                    value={name}
                    updateValue={setName}
                />
                <InputField
                    label="Email address"
                    type="text"
                    placeholder="e.g. Joe@gmail.com"
                    valid={validated || emailValidator(email)}
                    error="Invalid email"
                    value={email}
                    updateValue={setEmail}
                />
                <SelectField
                    label="Residential Country"
                    data={countries}
                    type="text"
                    placeholder=" "
                    valid={validated || residentialCountry.length > 0}
                    error="Please select the country"
                    value={residentialCountry}
                    updateValue={setResidentialCountry}
                />
                <SelectField
                    label="Citizenship Country "
                    data={countries}
                    type="text"
                    placeholder=" "
                    valid={validated || citizenshipCountry.length > 0}
                    error="Please select the country"
                    value={citizenshipCountry}
                    updateValue={setCitizenshipCountry}
                />
                <InputField
                    label="Password"
                    type="password"
                    placeholder="******"
                    valid={validated || passwordValidator(password)}
                    error="Invalid password"
                    value={password}
                    updateValue={setPassword}
                />
                <InputField
                    label="Confirm Password"
                    type="password"
                    placeholder="******"
                    valid={validated || (password === confirmPassword)}
                    error="Invalid password"
                    value={confirmPassword}
                    updateValue={setConfirmPassword}
                />
                <SubmitBtn onClick={handleSubmit}>Sign Up</SubmitBtn>
                {/* <SubmitBtn transparent>Sign Up with Google</SubmitBtn> */}
            </FormContainer>
            {/* {basisVisible && (<BasisWidget id="bas-widget-container" />)} */}
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </SignUpContainer>
    )
};

export default SignUp;