import { Dialog } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SearchImg from '../../asset/img/ic_search.svg'
import CloseImgB from '../../asset/img/ic_close_b.svg'
import CloseImgW from '../../asset/img/ic_close_w.svg'
import AssetImg from '../../asset/img/template_asset.png'
import { tokenList, WindowSize } from '../../util'

const AssetDialogContainer = styled.div((props)=> ({
    display: 'flex',
    flexDirection: 'column',
    width: '512px',
    height: '600px',
    borderRadius: '6px',
    backgroundColor: props.theme.colors.white,
    padding: '30px',
    [WindowSize.tablet]: {
        width: '500px',
    },
    [WindowSize.mobile]: {
        width: 'calc(100vw - 64px)',
    }
}))

const DialogHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const DialogTitle = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
`

const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const InputContainer = styled.div`
    height: 56px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white1};
    display: flex;
    align-items: center;
    padding: 0 16px 0 20px;
    margin: 24px 0 40px;
`

const InputDiv = styled.input`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    outline: none;
    border: none;
    flex: 1;
    background-color: ${props => props.theme.colors.transparent};
`

const SearchIcon = styled(CloseIcon)`
    margin-left: 16px;
    cursor: auto;
`

const ListContainer = styled.div`
    display: flex;
    flex: 1;
    overflow: scroll;
    flex-direction: column;
`

const ListItem = styled.div`
    display: flex;
    height: 48px;
    margin-bottom: 4px;
    align-items: center;
    cursor: pointer;
`

const AssetIcon = styled.img`
    width: 32px;
    height: 32px;
`

const AssetDetailCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
`

const AssetDetailSymbol = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
`

const AssetDetailName = styled.div`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
`

const AssetDetailAmount = styled(AssetDetailSymbol)`
    color: ${props => props.theme.colors.gray1};
    margin-left: auto;
`

const AssetDialog = ({ visible = false, closeModal = () => {}, availableTokens = [], onSelect = () => {} }) => {

    const [keyword, setKeyword] = useState('')

    const handleClose = () => {
        closeModal()
    }

    const handleSelect = (token) => {
        onSelect(token)
        closeModal()
    }

    return (
        <Dialog onClose={handleClose} open={visible}>
            <AssetDialogContainer>
                <DialogHeader>
                    <DialogTitle>Select a token</DialogTitle>
                    <CloseIcon onClick={handleClose} src={CloseImgB} />
                </DialogHeader>
                <InputContainer>
                    <InputDiv
                        placeholder="Search Token Name, Symbol or Address"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <SearchIcon src={SearchImg} />
                </InputContainer>
                <ListContainer>
                    {Object.keys(tokenList)
                    .filter(item => availableTokens.indexOf(item) >= 0)
                    .filter(item => `${tokenList[item].name}\n${tokenList[item].symbol}`.toLowerCase().indexOf(keyword.toLowerCase()) >= 0)
                    .map((item) => (
                        <ListItem onClick={() => handleSelect(item)} key={item}>
                            <AssetIcon src={tokenList[item].icon} />
                            <AssetDetailCol>
                                <AssetDetailSymbol>{tokenList[item].symbol}</AssetDetailSymbol>
                                <AssetDetailName>{tokenList[item].name}</AssetDetailName>
                            </AssetDetailCol>
                            <AssetDetailAmount>0.0</AssetDetailAmount>
                        </ListItem>
                    ))}
                </ListContainer>
            </AssetDialogContainer>
        </Dialog>
    )
}

export default AssetDialog
