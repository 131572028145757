import { Dialog } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import SearchImg from '../../asset/img/ic_search.svg'
import CloseImgB from '../../asset/img/ic_close_b.svg'
import CloseImgW from '../../asset/img/ic_close_w.svg'
import AssetImg from '../../asset/img/template_asset.png'
import { codeValidator, tokenList, WindowSize } from '../../util'
import { AuthContext } from '../../contexts'

const ConfirmDialogContainer = styled.div((props)=> ({
    display: 'flex',
    flexDirection: 'column',
    width: '512px',
    height: '600px',
    borderRadius: '6px',
    backgroundColor: props.theme.colors.white,
    padding: '30px',
    [WindowSize.tablet]: {
        width: '500px',
    },
    [WindowSize.mobile]: {
        width: 'calc(100vw - 64px)',
    }
}))

const DialogHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const DialogTitle = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
`

const CloseIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const DialogBody = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    flex: 1;
`

const SwapButton = styled.div`
    height: 64px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.blueButton};
    width: 100%;
    margin-top: 20px;
    color: ${props => props.theme.colors.white};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:center;
    cursor: pointer;
`

const SendButton = styled.div`
    height: 64px;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white};
    width: 100%;
    margin-top: 80px;
    color: ${props => props.theme.colors.blueButton};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content:flex-end;
    cursor: pointer;
`

const InputContainer = styled.div`
    height: 49px;
    border: 1px solid ${props => props.valid ? props.theme.colors.gray3 : props.theme.colors.red};
    border-radius: 6px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${props => props.second ? '24px' : '10px'};
`

const InputDiv = styled.input`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    border: none;
    outline: none;
    width: 100%;
`

const ConfirmDialog = ({ visible = false, closeModal = () => {}, onAgree = () => {} }) => {
    const [validated, setValidated] = useState(true)
    const { user, updateUser } = useContext(AuthContext)
    const [code, setCode] = useState('')

    const handleClose = () => {
        closeModal()
    }

    const onConfirm = async () => {
        onAgree()
    }

    return (
        <Dialog onClose={handleClose} open={visible}>
            <ConfirmDialogContainer>
                <DialogHeader>
                    <DialogTitle>Confirm the details</DialogTitle>
                    <CloseIcon onClick={handleClose} src={CloseImgB} />
                </DialogHeader>
                <DialogBody>
                    Below are the details of the swap
                </DialogBody>
                <SwapButton onClick={() => onConfirm()}>Swap</SwapButton>
            </ConfirmDialogContainer>
        </Dialog>
    )
}

export default ConfirmDialog
