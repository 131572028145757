import styled from 'styled-components';
import { WindowSize } from '../../util';

export const DashboardContainer = styled.div(() => ({
  padding: '50px 0 0',
  maxWidth: '1100px',
  marginTop: '80px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  [WindowSize.tablet]: {
    padding: '0 50px 50px',
  },
  [WindowSize.mobile]: {
    padding: '0 24px 24px',
  }
}))

export const PageTitle = styled.div((props) => ({
  color: props.theme.currentTheme === 'light' ? props.theme.colors.gray2 : props.theme.colors.white,
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '0',
  lineHeight: '25px',
  [WindowSize.mobile]: {
    fontSize: '15px',
    lineHeight: '23px',
  }
}))


export const PageDescription = styled.div((props) => ({
  color: props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.white,
  fontFamily: 'Poppins',
  fontSize: '20px',
  letterSpacing: '0',
  lineHeight: '30px',
  marginTop: '10px',
  marginBottom: '20px',
  display: props.nowallet ? 'none' : 'block',
  [WindowSize.mobile]: {
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: '2px',
  }
}))


export const TableContainer = styled.div`
    width: 100%;
    max-height: 788px;
    border-radius: 6px;
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.headerBg};
    box-shadow: 0 2px 20px 0 ${props => props.theme.currentTheme === 'light' ? props.theme.colors.boxShadow : props.theme.colors.transparent};
    padding: 28px 27px 40px 27px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`

export const TableHeader = styled.div`
    color: ${props => props.nowallet ? props.theme.colors.blueHeader : props.theme.currentTheme === 'light' ? props.theme.colors.black : props.theme.colors.white};
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 27px;
    margin: ${props => props.nowallet ? '20px 0 10px' : '0'}
`

export const TableDescription = styled.div`
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.white};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    margin: 8px 0 16px 0;
`

export const TableHeaderButton = styled.div((props)=> ({
  height: '40px',
  borderRadius: '3px',
  backgroundColor: props.theme.colors.blueButton,
  padding: '0 16px',
  color: props.theme.colors.white,
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '0',
  lineHeight: '21px',
  marginRight: 'auto',
  marginBottom: '40px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

export const Separator = styled.div`
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray3 : props.theme.colors.walletBg};
    height: 1px;
    width: 100%;
`

export const RowContainer = styled.div(() => ({
  height: '162px',
  width: '100%',
  [WindowSize.mobile]: {
    // eslint-disable-next-line no-restricted-globals
    height: 'auto',
    paddingBottom: '20px'
  }
}))


export const RowInnerContainer = styled.div`
    height: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`

export const RowFlexInner = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'scroll',
  [WindowSize.mobile]: {
    padding: '10px',
    display: 'block',
    marginBottom: '20px',
    overflow: 'scroll'
  }
}))


export const CaraRowContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '30px',
  right: '30px',

  [WindowSize.mobile]: {
    flexDirection: 'column',
    position: 'static',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',

  }
}))

export const ListHeader = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.gray4};
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
`

export const WalletContainer = styled.div((props) => ({
  marginBottom: '50px',
  minHeight: '220px',
  borderRadius: '6px',
  backgroundColor: props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.headerBg,
  boxShadow: `0 2px 20px 0 ${props.theme.currentTheme === 'light' ? props.theme.colors.boxShadow : props.theme.colors.transparent}`,
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  alignItems: props.nowallet ? 'center': 'flex-start',
  [WindowSize.tablet]: {
    width: '100%',
    minHeight: 'unset',
  },
  [WindowSize.mobile]: {
    padding: '20px 10px',
  }
}))

export const UserContainer = styled(WalletContainer)((props) => ({
  minHeight: '0'
}))

export const NetworkContainer = styled.div((props) => ({
  height: '162px',
  width: '248px',
  borderRadius: '6px',
  backgroundColor: props.theme.colors.white3,
  marginRight: '16px',
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  alignItems: props.newNetwork ? 'center' : 'flex-start',
  justifyContent: props.newNetwork ? 'center' : 'flex-start',
  [WindowSize.mobile]: {

    backgroundColor: props.theme.colors.white,
    boxShadow: '0 2px 10px 0 #DEE2E6',
    marginBottom: '10px',
    height: '75px',
    width: '100%',
    display: 'flex'
  }
}))

export const NetworkIconDiv = styled.div`
    display: flex;
    height: ${props => props.newNetwork ? '30px' : '36px'};
    width: 36px;
    border-radius: 6px;
    // background-color: ${props => props.newNetwork ? props.theme.colors.transparent : props.theme.colors.white};
    justify-content: center;
    align-items: center;
`

export const NetworkIcon = styled.img`
    width: 32px;
    height: 32px;
`

export const NewNetworkIcon = styled.img`
    width: 18px;
    height: 18px;
`

export const NetworkTitle = styled.div`
    color: ${props => props.theme.colors.gray1};
    font-size: 14px;
    // font-weight: bold;
    font-family: Poppins;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: 8px;
`

export const NetworkAddress = styled.div`
    color: ${props => props.theme.colors.black};
    font-size: 18px;
    font-weight: 500;
    font-family: Poppins;
    letter-spacing: 0;
    line-height: 27px;
    display: flex;
    width: 100%;
    // margin-top: 8px;
`

export const NetworkWrap = styled.div`
      margin-left: 12px;
      color: #212529;
      font-family: Poppins;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
`

export const NetworkWrapType = styled.div`
      color: #868E96;
      font-family: Poppins;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
`

export const NetworkButtons = styled.div`
    display: flex;
    // margin-top: 8px;
`

export const NetworkCopy = styled.div`
      // height: 30px;
      // width: 56px;
      cursor: pointer;
      border-radius: 3px;
      background-color: ${props => props.theme.colors.white1};
      padding-top: 5px;
      padding-bottom: 8px;
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 17px;
      margin-right: 5px;
      color: ${props => props.theme.colors.gray2};
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
`

export const NetworkDescription = styled.div`
    color: ${props => props.newNetwork ? props.theme.colors.gray1 : props.theme.colors.white4};
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 5px;
`

export const WalletAddressHeader = styled(ListHeader)((props) => ({
  lineHeight: '23px',
  fontSize: '15px',
  textAlign: 'left',
  margin: props.details ? '0 5px 5px' : '0 0 5px',
  [WindowSize.mobile]: {
    lineHeight: '18px',
    fontSize: '12px',
  }
}))

export const UserDetailsRowContainer = styled.div({
  display: 'flex'
})

export const RowTopDiv = styled.div`
    display: flex;
`

export const WalletAddressDiv = styled(TableHeader)({
  fontSize: '20px',
  fontWeight: 'normal',
  lineHeight: '30px',
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  wordBreak: 'break-all',
  [WindowSize.mobile]: {
    fontSize: '15px',
    lineHeight: '23px',
  }
})

export const WalletVerifiedIcon = styled.img(props => ({
  height: '24px',
  width: '24px',
  marginLeft: '5px',
  [WindowSize.mobile]: {
    height: '14px',
    width: '14px',
    marginLeft: '2px',
    marginTop: '5px'
  }
}))

export const NFTIssuedIcon = styled.img(props => ({
  height: '20px',
  width: '70px',
  marginLeft: '2px',
  marginTop: '5px',
  fontFamily: 'Poppins',
  [WindowSize.mobile]: {
    height: '11.5px',
    width: '40.5px',
  }
}))

export const UserWalletsNFTIssuedIcon = styled(NFTIssuedIcon)({
  [WindowSize.mobile]: {
    marginTop: '17px',
    marginLeft: '5px',
  }
})

export const UserWalletsVerifiedIcon = styled(WalletVerifiedIcon)({
  [WindowSize.mobile]: {
    marginTop: '17px',
    marginLeft: '5px',
  }
})

export const WalletIcon = styled.img({
  height: '20px',
  width: '20px',
  margin: '4px 0 4px 8px',
  objectFit: 'contain',
  cursor: 'pointer',
  [WindowSize.mobile]: {
    height: '12px',
    width: '12px',
  }
})


export const WalletBtnContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0 16px;
`

export const WalletButton = styled.div(props => ({
  border: 'none',
  borderRadius: '3px',
  padding: '0 12px',
  display: 'flex',
  alignItems: 'center',
  color: props.theme.currentTheme === 'light' ? props.uppercase ? props.theme.colors.blueText : props.theme.colors.gray2 : props.theme.colors.orange,
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: props.uppercase ? 'bold' : 'none',
  backgroundColor: props.uppercase ? props.theme.colors.blueBackground : props.theme.colors.white1,
  letterSpacing: '0',
  lineHeight: '20px',
  textAlign: 'center',
  height: '30px',
  marginRight: '8px',
  cursor: 'pointer',
  textTransform: props.uppercase ? 'uppercase' : 'none',
  [WindowSize.mobile]: {
    fontSize: '8px',
    lineHeight: '12px',
    height: '21px',
  }
}))

export const WalletHistoryContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 14px;
`

export const WalletHistoryTitle = styled.div(props => ({
  color: props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.gray2,
  fontFamily: 'Poppins',
  fontSize: '15px',
  letterSpacing: '0',
  lineHeight: '23px',
  [WindowSize.mobile]: {
    fontSize: '10px',
    lineHeight: '16px',
  }
}))

export const WalletHistoryExpand = styled.img({
  marginTop: '1px',
  padding: '2px',
  height: '20px',
  width: '20px',
  [WindowSize.mobile]: {
    height: '14px',
    width: '14px',
  }
})

export const WalletHistoryTable = styled.div(props => ({
  width: '100%',
  display: props.expand ? 'block' : 'none',
  marginTop: '20px',
  [WindowSize.mobile]: {
    marginTop: '0',
  }
}))

export const WalletHistoryHeader = styled.div({
  width: '100%',
  display: 'flex',
  padding: '5px 0',
  borderBottom: '1px solid #CED4DA',
  [WindowSize.mobile]: {
    display: 'none',
  }
})

export const WalletHistoryHeaderTitle = styled.div`
      width: 80%;
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: left;
      color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.gray2};
`

export const WalletHistoryHeaderOthers = styled.div`
      width: 10%;
      font-family: Poppins;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.gray2};
`

export const WalletHistoryBody = styled.div({
  width: '100%',
  margin: '10px 0',
  padding: '10px 0',
  display: 'flex',
  // padding-top: ${props => props.isMobile? '10px' : '10px' };
  [WindowSize.mobile]: {
    borderTop: '1px solid #F1F3F5'
  }
})

export const WalletHistoryBodyTitle = styled.div`
      width: 80%;
      color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray2 : props.theme.colors.gray2};
      text-align: left;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
`

export const WalletHistoryMobileWrap = styled.div`
      width: 80%;
      color: #868E96;
      font-family: Poppins;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
`

export const WalletHistoryMobileTime = styled.div`
      color: #CED4DA;
      font-family: Poppins;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 16px;
`

export const WalletHistoryBodyTime = styled.div`
      width: 13%;
      color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.gray2};
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
`

export const WalletHistoryBodyStatus = styled.div(props => ({
  width: '7%',
  padding: '5px',
  boxSizing: 'border-box',
  margin: '-5px 0px 0',
  border: '1px solid #4C6EF5',
  borderRadius: '12px',
  color: props.theme.currentTheme === 'light' ? props.theme.colors.blueHeader : props.theme.colors.gray2,
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontWeight: '500',
  fontSize: '10px',
  letterSpacing: '0',
  lineHeight: '16px',
  [WindowSize.mobile]: {
    width: '15%',
    height: '21px',
    fontSize: '8px',
    lineHeight: '12px',
  }
}))

export const UserWalletListContainer = styled(WalletContainer)`
    display: ${props => props.nowallet ? 'none' : 'block'};
`

export const UserWalletsContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    display: ${props => props.hasWallets && !props.isMobile ? 'block' : 'none'};
`

export const UserWalletsContainerHeader = styled.div`
    padding: 10px 0;
    border-top: 1px solid #868E96;
    border-bottom: 1px solid #868E96;
    display: flex;
`

export const UserWalletsContainerHeadingWallet = styled.div`
    width: 70%;
    text-align: left;
    color: #868E96;
    font-family: Poppins;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
`

export const UserWalletContainerHeadingOthers = styled(UserWalletsContainerHeadingWallet)`
    width: 15%;
    text-align: center;
`

export const UserWalletsContainerBody = styled.div`
    width: 100%;
    margin-top: 10px;
`

export const UserWalletContainerRowImg = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 2px;
`

export const UserWalletsContainerRow = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0;
`

export const UserWalletContainerRowAddress = styled.div`
    display: flex;
    width: 70%;
    text-align: left;
    color: #212529;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
`

export const UserWalletContainerRowTime = styled(UserWalletContainerRowAddress)`
    width: 15%;
    display: block;
    color: #868E96;
    text-align: center;
`

export const UserWalletContainerRowStatus = styled(UserWalletContainerRowTime)`
    color: #4C6EF5;
`

export const CaraButton = styled(TableHeaderButton)((props) => ({
  padding: '10px 42px',
  display: props.display ? 'block' : 'none',
  fontSize: '13px',
  fontWeight: '500',
  marginBottom: '0',
  backgroundColor: props.disabled ? props.theme.colors.gray1 : props.theme.colors.pink,
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  userSelect: 'none',
  [WindowSize.mobile]: {
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '16px'
  }
}))

export const SettingArrowIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-left: auto;
`

export const WalletAddDescription = styled(TableDescription)`
    margin: 0 0 30px;
    font-size: 16px;
    line-height: 25px;
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.gray1 : props.theme.colors.white};
`

export const WalletAddButton = styled.div`
    padding: 10px 30px;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    font-family: Poppins;
    margin-bottom: 20px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 0;
    color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.white : props.theme.colors.black};
    background-color: ${props => props.theme.currentTheme === 'light' ? props.theme.colors.pink : props.theme.colors.white};
`

export const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const UserStatus = styled.div((props) => ({
  fontSize: '12px',
  lineHeight: '40px',
  marginTop: '14px',
  color: props.pending ? props.theme.colors.orange : props.verified ? props.theme.colors.blueButton : props.theme.colors.warning,
}))
