import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import {
  useHistory,
} from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Axios from 'axios'
import { AuthContext } from '../../contexts'

import { codeValidator, ERROR_TEXT, WindowSize } from '../../util'
import WaitTimer from '../../component/WaitTimer'
import { toast } from 'react-toastify'

const CodeVerificationContainer = styled.div(() => ({
    padding: '0 100px',
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
    [WindowSize.tablet]: {
        padding: '0 50px 50px',
    }
}))

const FormContainer = styled.div`
    flex: 1;
    flex-direction: column;
    text-align: left;
`

const PageTitle = styled.div`
    color: ${props => props.theme.colors.black};
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
`

const PageDescription = styled.div`
    color: ${props => props.theme.colors.gray2};
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 12px 0 16px;
`

const LabelText = styled.div`
    color: ${props => props.theme.colors.black1};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 8px 0;
    display: flex;
    flex-direction: row;
`

const InputContainer = styled.div`
    height: 49px;
    border: 1px solid ${props => props.valid ? props.theme.colors.gray3 : props.theme.colors.red};
    border-radius: 6px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${props => props.second ? '24px' : '10px'};
`

const SubmitBtn = styled.div`
    box-sizing: border-box;
    height: 49px;
    border: 1px solid ${props => props.disabled ? props.theme.colors.gray1 : props.theme.colors.blueHeader};
    border-radius: 6px;
    background-color: ${props => props.transparent ? props.theme.colors.white : props.disabled ? props.theme.colors.gray1 : props.theme.colors.blueHeader};
    color: ${props => props.transparent ? props.disabled ? props.theme.colors.gray1 : props.theme.colors.blueHeader : props.theme.colors.white};
    font-family: Poppins;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    user-select: none;
`

const OrTag = styled.div`
    box-sizing: border-box;
    height: 42px;
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const InputDiv = styled.input`
    color: ${props => props.theme.colors.gray1};
    font-family: Poppins;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    border: none;
    outline: none;
    width: 100%;
    background-color: #0000;
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const CodeVerification = () => {
    const [loading, setLoading] = useState(false)
    const [validated, setValidated] = useState(true)
    const { user, updateUser } = useContext(AuthContext)
    const [code, setCode] = useState('')
    const [lastTime, setLastTime] = useState(moment().add(2, 'minutes'))
    let history = useHistory()

    const handleSubmit = async () => {
        if (loading) {
            return
        }
        if (!codeValidator(code)) {
            setValidated(false)
            return
        }
        setValidated(true)
        
        setLoading(true)
        try {
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/verify-code`, {
                email: user.email,
                code
            }, { headers: { 'Content-Type': 'Application/json' } })
            if (res.data) {
                if (res.data.status === 0) {
                    toast.error(res.data.message || ERROR_TEXT['10'], {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                    setLoading(false)
                    return
                }
                updateUser(res.data)
                if (res.data.isVerified) {
                    setLoading(false)
                    history.push('/dashboard')
                }
            }
        } catch (error) {
        }
        setLoading(false)
    }

    const handleResend = async () => {
        if (moment().isBefore(lastTime) || loading) {
            return
        }
        setLoading(true)
        try {
            const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/send-code`, {
                    email: user.email,
                },
                { headers: { 'Content-Type': 'Application/json' }
            })

            if (res.data) {
                if (res.data.status === 0) {
                    toast.error(res.data.message || ERROR_TEXT['11'], {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                    setLoading(false)
                    return
                }
                setLastTime(moment().add(2, 'minutes'))
                setTimeout(() => {
                    setLastTime(moment().subtract(5, 'seconds'))
                }, 120000)
            }
        } catch (error) {
        }
        setLoading(false)
    }

    useEffect(() => {
        setTimeout(() => {
            setLastTime(moment().subtract(5, 'seconds'))
        }, 120000)
    }, [])

    return (
        <CodeVerificationContainer>
            <FormContainer>
                <PageTitle>Confirmation</PageTitle>
                <PageDescription>Enter your code below</PageDescription>
                <LabelText>Verification Code</LabelText>
                <InputContainer valid={validated || codeValidator(code)}>
                    <InputDiv
                        type="normal"
                        placeholder="******"
                        value={code}
                        type="password"
                        onChange={(e) => setCode(e.target.value)}
                    />
                </InputContainer>
                <WaitTimer time={lastTime} />
                <SubmitBtn disabled={loading} onClick={handleSubmit}>Verify</SubmitBtn>
                <OrTag>Or</OrTag>
                <SubmitBtn disabled={loading || moment().isBefore(lastTime)} transparent onClick={handleResend}>Resend</SubmitBtn>
            </FormContainer>
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </CodeVerificationContainer>
    )
}

export default CodeVerification