import { useDispatch } from 'react-redux';
import { use } from 'i18next';

export const SET_CONNECTED_WALLETS = 'SET_CONNECTED_WALLETS'
export const SET_KLAYTN_WALLET = 'SET_KLAYTN_WALLET'
export const SET_METAMASK_WALLET = 'SET_METAMASK_WALLET'
export const ADD_WALLET = 'ADD_WALLET'
export const ADD_KLAYTN_WALLET = 'ADD_KLAYTN_WALLET'
export const ADD_METAMASK_WALLET = 'ADD_METAMASK_WALLET'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_KLAYTN_STATUS = 'UPDATE_KLAYTN_STATUS'
export const UPDATE_METAMASK_STATUS = 'UPDATE_METAMASK_STATUS'
export const REMOVE_WALLET = 'REMOVE_WALLET'
export const REMOVE_KLAYTN_WALLET = 'REMOVE_KLAYTN_WALLET'
export const REMOVE_METAMASK_WALLET = 'REMOVE_METAMASK_WALLET'
export const REMOVE_ALL_WALLETS = 'REMOVE_ALL_WALLETS'
export const EXPAND_WALLET = 'EXPAND_WALLET'



export const WalletActions = () => {
  const dispatch = useDispatch()

  const SetConnectedWallets = (wallets) => {
    return dispatch({
      type: SET_CONNECTED_WALLETS,
      payload: wallets
    })
  }

  const SetKlaytnWallet = (wallet) => {
    return dispatch({
      type: SET_KLAYTN_WALLET,
      payload: wallet
    })
  }

  const SetMetamaskWallet = (wallet) => {
    return dispatch({
      type: SET_METAMASK_WALLET,
      payload: wallet
    })
  }

  const AddKlaytnWallet = (wallet) => {
    return dispatch({
      type: ADD_KLAYTN_WALLET,
      payload: wallet
    })
  }

  const RemoveKlaytnWallet = (wallet) => {
    return dispatch({
      type: REMOVE_KLAYTN_WALLET,
      payload: wallet
    })
  }

  const RemoveAllWallets = (wallets) => {
    return dispatch({
      type: REMOVE_ALL_WALLETS,
      payload: wallets
    })
  }

  const AddMetamaskWallet = (wallet) => {
    return dispatch({
      type: ADD_METAMASK_WALLET,
      payload: wallet
    })
  }

  const RemoveMetamaskWallet = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
      type: REMOVE_METAMASK_WALLET,
      payload: wallet
    })
  }

  const UpdateKlaytnWalletStatus = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
      type: UPDATE_KLAYTN_STATUS,
      payload: wallet
    })
  }

  const UpdateWalletStatus = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
      type: UPDATE_STATUS,
      payload: wallet
    })
  }

  const UpdateMetamaskWalletStatus = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
      type: UPDATE_METAMASK_STATUS,
      payload: wallet
    })
  }

  const ExpandWallet = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
      type: EXPAND_WALLET,
      payload: wallet
    })
  }

  const AddWallet = (wallet) => {
    return dispatch({
        type: ADD_WALLET,
        payload: wallet
      })
    }

  const RemoveWallet = (wallet) => {
    // const data = { ...wallet, web3: web3module}
    return dispatch({
        type: REMOVE_WALLET,
        payload: wallet
      })
    }

  return {
    AddKlaytnWallet,
    RemoveKlaytnWallet,
    RemoveAllWallets,
    SetConnectedWallets,
    SetKlaytnWallet,
    AddMetamaskWallet,
    RemoveMetamaskWallet,
    SetMetamaskWallet,
    UpdateKlaytnWalletStatus,
    UpdateMetamaskWalletStatus,
    ExpandWallet,
    AddWallet,
    RemoveWallet,
    UpdateWalletStatus
  }
}








export const updateWalletStatus = (wallet) => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: UPDATE_STATUS,
      payload: wallet
    })
  }
}

export const updateKlaytnWalletStatus = (wallet) => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: UPDATE_KLAYTN_STATUS,
      payload: wallet
    })
  }
}


export const updateMetamaskWalletStatus = (wallet) => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: UPDATE_METAMASK_STATUS,
      payload: wallet
    })
  }
}

export const removeWallet = (wallet) => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: REMOVE_WALLET,
      payload: wallet
    })
  }
}

export const removeKlaytnWallet = (wallet) => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: REMOVE_KLAYTN_WALLET,
      payload: wallet
    })
  }
}



export const RemoveAllWallets = () => {
  // const data = { ...wallet, web3: web3module}
  return(dispatch) => {
    dispatch({
      type: REMOVE_ALL_WALLETS,
      payload: ''
    })
  }
}