import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    Fragment,
    useContext,
} from 'react'
import moment from 'moment'
import { AuthContext } from '../contexts'

const SessionTimeout = () => {
    const [events, setEvents] = useState(['click', 'scroll'])
    const { user, logoutUser } = useContext(AuthContext)

    let timeStamp
    let warningInactiveInterval = useRef()
    let startTimerInterval = useRef()

    // start inactive check
    let timeChecker = () => {
        if (sessionStorage.getItem('lastTimeStamp')) {
            startTimerInterval.current = setTimeout(() => {
                let storedTimeStamp = sessionStorage.getItem('lastTimeStamp')
                warningInactive(storedTimeStamp)
            }, 60000)
        }
    }

    // warning timer
    let warningInactive = (timeString) => {
        clearTimeout(startTimerInterval.current)

        warningInactiveInterval.current = setInterval(() => {
            const maxTime = 500

            const diff = moment.duration(moment().diff(moment(timeString)))
            const minPast = diff.minutes()
            if (minPast >= maxTime) {
                clearInterval(warningInactiveInterval.current)
                sessionStorage.removeItem('lastTimeStamp')
                logoutUser();
            }
        }, 1000)
    }

    // reset interval timer
    let resetTimer = () => {
        clearTimeout(startTimerInterval.current)
        clearInterval(warningInactiveInterval.current)
        if (user.isVerified) {
            timeStamp = moment()
            sessionStorage.setItem('lastTimeStamp', timeStamp)
        } else {
            clearInterval(warningInactiveInterval.current)
            sessionStorage.removeItem('lastTimeStamp')
        }
        timeChecker()
    }

    // handle close popup
    const handleClose = () => {
        resetTimer()
    }

    useEffect(() => {
        events.forEach((event) => {
            window.addEventListener(event, resetTimer)
        })

        resetTimer()

        return () => {
            clearTimeout(startTimerInterval.current)
        //   resetTimer()
        }
    }, [user.isVerified])


    return <Fragment />
}

export default SessionTimeout
