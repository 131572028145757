import React, { useEffect, useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { ifProp } from 'styled-tools'
import ReactPlayer from 'react-player'
import { slideInDown, slideOutUp } from 'react-animations'

import { WindowSize } from '../../util'
import Header from '../../component/Header'
import Product1Img from '../../asset/img/img_product_1.png'
import Product2Img from '../../asset/img/img_product_2.png'
import Product3Img from '../../asset/img/img_product_3.png'
import ProductMImg from '../../asset/img/img_product_m.png'
import KYCImg from '../../asset/img/img_kyc.png'
import NFTImg from '../../asset/img/img_nft.png'
import AMLImg from '../../asset/img/img_aml.png'
import Footer from '../../component/Footer'
import { useHistory } from 'react-router'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useTranslation } from 'react-i18next'

// const slideInKeyframe = keyframes`
//     0% {
//         transform: translate3d(0, '-100%', 0);
//         visibility: visible;
//     }
//     100% {
//         transform: translate3d(0, 0, 0);
//     }
// `
const slideInKeyframe = keyframes`
    0% {
        transform: translate3d(0, 300px, 0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`
const slideOutKeyframe = keyframes`
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 300px, 0);
        opacity: 0;
    }
`

const slideInAnimation = 
  css`
    ${slideInKeyframe} 1s;
  `
const slideOutAnimation = 
    css`
        ${slideOutKeyframe} 1s;
    `


const HomeContainer = styled.div(() => ({
    padding: '80px 0 0',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
}))

const FormContainer = styled.div`
    height: 720px;
    flex-direction: column;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 1;
    max-width: 1140px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    ${WindowSize.ipad} {
        height: 598px;
        padding: 0 24px;
    }
`

const PageTitle = styled.div`
    color: ${props => props.theme.colors.white};
    font-family: Poppins;
    font-size: 45px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 68px;
    text-align: center;
    margin-top: 140px;
    max-width: 600px;
    ${WindowSize.ipad} {
        font-size: 25px;
        line-height: 38px;
        margin-top: 30px;
    }
`

const PageDescription = styled.div`
    color: ${props => props.theme.colors.white};
    font-family: Poppins;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    margin: 20px 0 40px;
    max-width: 600px;
    ${WindowSize.ipad} {
        font-size: 15px;
        line-height: 24px;
        margin: 25px 0 30px;
    }
`

const HeaderRoundButton = styled.div((props) => ({
    height: '50px',
    width: '240px',
    borderRadius: '25px',
    backgroundColor: props.theme.colors.white,
    color: props.theme.colors.pink,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ['&:hover']: {
        backgroundColor: props.theme.colors.whiteHover
    },
    [WindowSize.ipad]: {
        width: '200px'
    }
}))

const SectionContainer = styled.div`
    padding: 120px 0;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: ${props => props.row ? 'row' : 'column'};
    ${WindowSize.ipad} {
        padding: 80px 24px 0;
        flex-direction: column;
    }
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    animation: ${props => props.isActive ? slideInAnimation : slideOutAnimation};
    opacity: ${props => props.isActive ? 1 : 0};
    ${WindowSize.ipad} {
        flex-direction: column;
    }
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DecentTitle = styled.div`
    color: #000000;
    font-family: Poppins;
    font-size: 38px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 57px;
    text-align: ${props => props.left ? 'left' : 'center'};
    margin-bottom: ${props => props.marginBottom ? '80px' : '0'};
    ${WindowSize.ipad} {
        font-size: 25px;
        line-height: 38px;
        text-align: center;
        margin-bottom: ${props => props.marginBottom ? '50px' : '0'};
    }

    span {
        color: ${props => props.theme.colors.pink};
        margin: ${props => props.leftMargin ? '0 0 0 10px' : '0 10px 0 0'};
    }
`

const DecentDescription = styled.div`
    color: #495057;
    font-family: Poppins;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 35px;
    text-align: ${props => props.left ? 'left' : 'center'};
    margin: ${props => props.left ? '40px 0' : '40px 100px 70px'};
    ${WindowSize.ipad} {
        font-size: 15px;
        line-height: 23px;
        margin: ${props => props.left ? '20px 0 50px' : '20px 0 50px'};
        text-align: center;
    }

    span {
        color: ${props => props.theme.colors.pink};
        margin: ${props => props.left ? '0 5px 0 0' : '0 5px'};
    }
`

const DecentImg = styled.img`
    width: 100%;
    border-radius: 30px;
    ${WindowSize.ipad} {
        border-radius: 15px;
    }
`

const EcoSystemOuterContainer = styled.div`
    margin-top: 120px;
    background-color: #653EFF;
    ${WindowSize.ipad} {
        margin-top: 80px;
    }
`

const EcoSystemContainer = styled.div`
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    ${WindowSize.ipad} {
        padding: 80px 24px;
    }
`

const EcoSystemTitle = styled.div`
    font-family: Poppins;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 48px;
    ${WindowSize.ipad} {
        font-size: 25px;
        line-height: 38px;
    }
`

const EcoSystemDescription = styled.div`
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    margin: ${props => props.margin || '0'};
    ${WindowSize.ipad} {
        font-size: 15px;
        line-height: 23px;
    }
`

const BackgroundVideo = styled.div(() => ({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    backgroundColor: '#0000',
    height: '800px',
    [WindowSize.ipad]: {
        height: '670px'
    },
    ['video'] : {
        objectFit: 'cover'
    }
}))

const SectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props => props.padding || '0'};
    flex: ${props => props.image ? '1.3' : '1'};
    opacity: ${props => props.hide ? 0.01 : 1};
`

const SectionImg = styled.img`
    width: 100%;
    ${WindowSize.ipad} {
        height: ${props => props.full ? 'unset' : '180px'};
        object-fit: cover;
    }
`

const SectionImgDiv = styled.div`
    width: 100%;
    height: 465px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.center ? 'center' : 'flex-end'};
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 2px 20px 0 #DEE2E6;
    position: relative;
    margin-bottom: ${props => props.last ? '0' : '40px'};
    padding: ${props => props.padding || '0'};
    &:hover {
        box-shadow: 0 2px 20px 0 #ADB5BD;
    }
    ${WindowSize.ipad} {
        border-radius: 15px;
        margin-bottom: ${props => props.subLast ? '40px' : props.last ? '0' : '30px'};
        padding: 15px 10px 20px 10px;
        height: unset;
    }
`

const SectionTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 40px 40px 0;
    height: 195px;
    background-color: #FFF;
    ${WindowSize.ipad} {
        position: relative;
        height: unset;
        padding: 30px 20px;
        min-height: 180px;
    }
`

const SectionTextTitle = styled.div`
    color: #343A40;
    font-family: Poppins;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 42px;
    ${WindowSize.ipad} {
        font-size: 23px;
        line-height: 34px;
    }
`

const SectionTextDescription = styled.div`
    color: #495057;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    margin-top: 20px;
    ${WindowSize.ipad} {
        margin-top: 10px;
        font-size: 13px;
        line-height: 20px;
    }
`

const NFTSectionTitle = styled.div`
    color: #495057;
    font-family: Poppins;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 42px;
    ${WindowSize.ipad} {
        font-size: 18px;
        line-height: 27px;
    }
`

const NFTSectionDescription = styled.div`
    color: #495057;
    font-family: Poppins;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    ${WindowSize.ipad} {
        font-size: 13px;
        line-height: 20px;
    }
`

const Separator = styled.div`
    background-color: #E9ECEF;
    height: 1px;
    width: 100%;
    margin: ${props => props.margin || '0'};
`

const LearnMoreButton = styled.div`
    height: 40px;
    width: 120px;
    border-radius: 20px;
    background-color: #E9ECEF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin: ${props => props.margin || '0'};
    cursor: pointer;
    &:hover {
        background-color: #CED4DA;
    }
`

const EcoSystemTagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const EcoSystemTag = styled.div`
    box-sizing: border-box;
    height: 84px;
    border: 2px solid #FFFFFF;
    border-radius: 42px;
    padding: 25px 50px;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 34px;
    margin: 0 8px 10px 0;
    cursor: pointer;
    &:hover {
        color: #653EFF;
        background-color: #FFF;
    }
    ${WindowSize.ipad} {
        height: 50px;
        border-radius: 25px;
        padding: 12px 26px;
        font-size: 16px;
        line-height: 26px;
    }
`

const Home = ({ scrollPosition = 0 }) => {
    const [loading, setLoading] = useState(false)
    const { isMobile, isPad, isTablet } = useWindowSize()
    let history = useHistory()
    const { t } = useTranslation()

    return (
        <HomeContainer>
            <BackgroundVideo>
                <ReactPlayer url="videos/video_compass.mp4" width="100%" height="100%" muted playing loop />
            </BackgroundVideo>
            <FormContainer>
                <PageTitle>
                    {t('home.title1')}
                    <br />
                    {t('home.title2')}
                </PageTitle>
                <PageDescription>
                    {t('home.description1')}
                    <br />
                    {t('home.description2')}
                </PageDescription>
                <HeaderRoundButton onClick={() => history.push('/sign-in')}>{t('general.getStarted')}</HeaderRoundButton>
            </FormContainer>
            <SectionContainer>
                <DecentTitle>{t('home.decentral.title')}</DecentTitle>
                <DecentDescription>
                    {t('home.decentral.description1')}
                    <span>{t('general.keyword')}</span>
                    {t('home.decentral.description2')}
                    <br />
                    {t('home.decentral.description3')}
                </DecentDescription>
                <DecentImg src={isPad ? ProductMImg : Product1Img}></DecentImg>
            </SectionContainer>
            <SectionContainer row>
                {!isPad && scrollPosition > 1820 && scrollPosition < 3080 && (
                    <SectionColumn padding="0 60px 0 0" style={{ position: 'fixed', top: '90px', left: 'calc(50% - 550px)', width: '512px' }}>
                        <DecentTitle left>{t('home.def.title')}</DecentTitle>
                        <DecentDescription left>
                            <span>{t('general.keyword')}</span>
                            {t('home.def.description')}
                        </DecentDescription>
                    </SectionColumn>
                )}
                {!isPad && scrollPosition > 3080 && (
                    <SectionColumn padding="0 60px 0 0" style={{ position: 'absolute', top: '3165px', left: 'calc(50% - 550px)', width: '512px' }}>
                        <DecentTitle left>{t('home.def.title')}</DecentTitle>
                        <DecentDescription left>
                            <span>{t('general.keyword')}</span>
                            {t('home.def.description')}
                        </DecentDescription>
                    </SectionColumn>
                )}
                <SectionColumn padding={isPad ? "0" : "0 60px 0 0"} hide={!isPad && scrollPosition > 1820}>
                    <DecentTitle left>{t('home.def.title')}</DecentTitle>
                    <DecentDescription left>
                        <span>{t('general.keyword')}</span>
                        {t('home.def.description')}
                    </DecentDescription>
                </SectionColumn>
                <SectionColumn image padding="0">
                    <SectionImgDiv>
                        <SectionTextDiv>
                            <SectionTextTitle>
                                {t('home.def.kyc')}
                            </SectionTextTitle>
                            <SectionTextDescription>
                                {t('home.def.kycDescription1')}
                                <br />
                                {t('home.def.kycDescription2')}
                            </SectionTextDescription>
                        </SectionTextDiv>
                        <SectionImg src={KYCImg}></SectionImg>
                    </SectionImgDiv>
                    <SectionImgDiv>
                        <SectionTextDiv>
                            <SectionTextTitle>
                                {t('home.def.aml')}
                            </SectionTextTitle>
                            <SectionTextDescription>
                                {t('home.def.amlDescription')}
                            </SectionTextDescription>
                        </SectionTextDiv>
                        <SectionImg src={AMLImg}></SectionImg>
                    </SectionImgDiv>
                    <SectionImgDiv last>
                        <SectionTextDiv>
                            <SectionTextTitle>
                                {t('home.def.nft')}
                            </SectionTextTitle>
                            <SectionTextDescription>
                                {t('home.def.nftDescription')}
                            </SectionTextDescription>
                        </SectionTextDiv>
                        <SectionImg src={NFTImg}></SectionImg>
                    </SectionImgDiv>
                </SectionColumn>
            </SectionContainer>
            <SectionContainer>
                <DecentTitle marginBottom>
                    <span>{t('general.keyword')}</span>
                    {t('general.nft')}
                </DecentTitle>
                <RowContainer isActive={(scrollPosition + window.innerHeight) > (isPad ? 3000 : 4019)}>
                    <SectionColumn image>
                        <SectionImgDiv center padding="40px" subLast>
                            <SectionImg full src={Product2Img}></SectionImg>
                        </SectionImgDiv>
                    </SectionColumn>
                    <SectionColumn padding={isPad ? "0" : "0 0 0 60px"}>
                        <NFTSectionTitle>
                            {t('home.qr.kycPass')}
                            <br/>
                            {t('home.qr.title')}
                        </NFTSectionTitle>
                        <Separator margin={isPad ? "15px 0" : "40px 0"} />
                        <NFTSectionDescription>
                            {t('home.qr.description1')}
                            <br />
                            {t('home.qr.description2')}
                        </NFTSectionDescription>
                        <LearnMoreButton onClick={() => history.push('/coming-soon')} margin={isPad ? "20px 0 0" : "25px 0 0"}>
                            {t('general.learnmore')}
                        </LearnMoreButton>
                    </SectionColumn>
                </RowContainer>
            </SectionContainer>
            <SectionContainer>
                <DecentTitle marginBottom leftMargin>
                    {t('general.wallet')}
                    <span>{t('general.management')}</span>
                </DecentTitle>
                <RowContainer isActive={(scrollPosition + window.innerHeight) > (isPad ? 3700 : 4899)}>
                    {isPad && (
                        <SectionColumn image>
                            <SectionImgDiv center padding="20px 20px 40px" subLast>
                                <SectionImg full src={Product3Img}></SectionImg>
                            </SectionImgDiv>
                        </SectionColumn>
                    )}
                    <SectionColumn padding={isPad ? "0" : "0 60px 0 0"}>
                        <NFTSectionTitle>
                            {t('home.wallet.title')}
                        </NFTSectionTitle>
                        <Separator margin={isPad ? "15px 0" : "40px 0"} />
                        <NFTSectionDescription>
                            {t('home.wallet.description1')}
                            <br />
                            {t('home.wallet.description2')}
                        </NFTSectionDescription>
                        <LearnMoreButton onClick={() => history.push('/coming-soon')} margin={isPad ? "20px 0 0" : "25px 0 0"}>
                            {t('general.learnmore')}
                        </LearnMoreButton>
                    </SectionColumn>
                    {!isPad && (
                        <SectionColumn image>
                            <SectionImgDiv center padding="20px 20px 40px" subLast>
                                <SectionImg src={Product3Img}></SectionImg>
                            </SectionImgDiv>
                        </SectionColumn>
                    )}
                </RowContainer>
            </SectionContainer>
            <EcoSystemOuterContainer>
                <EcoSystemContainer>
                    <EcoSystemTitle>{t('home.eco.title')}</EcoSystemTitle>
                    <EcoSystemDescription margin={isPad ? "20px 0 50px" : "16px 0 32px"}>{t('home.eco.description')}</EcoSystemDescription>
                    <EcoSystemTagContainer>
                        <EcoSystemTag>{t('general.wallet')}</EcoSystemTag>
                        <EcoSystemTag>{t('general.defi')}</EcoSystemTag>
                        <EcoSystemTag>{t('general.keyword')}</EcoSystemTag>
                        <EcoSystemTag>{t('general.apps')}</EcoSystemTag>
                        <EcoSystemTag>{t('general.security')}</EcoSystemTag>
                        <EcoSystemTag>{t('general.kyc')}</EcoSystemTag>
                    </EcoSystemTagContainer>
                </EcoSystemContainer>
            </EcoSystemOuterContainer>
            <Footer />
            {loading && (
                <LoadingContainer>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </LoadingContainer>
            )}
        </HomeContainer>
    )
}

export default Home