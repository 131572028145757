import { useState, useEffect, useRef, useContext } from 'react'
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import detectEthereumProvider from '@metamask/detect-provider';
import Fortmatic from 'fortmatic'
import Portis from '@portis/web3'
import { WalletLink } from 'walletlink'
import { toast } from 'react-toastify'
import BigNumber from 'bignumber.js'
import Axios from 'axios'
import { ERROR_TEXT, tokenList } from '../util'
import { AuthContext } from '../contexts'

const balance_template = {
  'ETH': new BigNumber(0),
  'WETH': new BigNumber(0),
  'AETH': new BigNumber(0),
  'USDT': new BigNumber(0),
  'AUSDT': new BigNumber(0),
  'BTC': new BigNumber(0),
  'WBTC': new BigNumber(0),
  'ABTC': new BigNumber(0),
}

const coingecko_data = {
  "USDT": {
    "usd": 1,
    "usd_market_cap": 65970380204.35499,
    "usd_24h_vol": 57295771198.874344,
    "usd_24h_change": -0.028092567144173627
  },
  "AUSDT": {
    "usd": 1,
    "usd_market_cap": 65970380204.35499,
    "usd_24h_vol": 57295771198.874344,
    "usd_24h_change": -0.028092567144173627
  },
  "ETH": {
    "usd": 3364.15,
    "usd_market_cap": 394371633749.10986,
    "usd_24h_vol": 24047995481.588768,
    "usd_24h_change": 5.571819836902806
  },
  "AETH": {
    "usd": 3364.15,
    "usd_market_cap": 394371633749.10986,
    "usd_24h_vol": 24047995481.588768,
    "usd_24h_change": 5.571819836902806
  },
  "WETH": {
    "usd": 3364.15,
    "usd_market_cap": 394371633749.10986,
    "usd_24h_vol": 24047995481.588768,
    "usd_24h_change": 5.571819836902806
  },
  "BTC": {
    "usd": 47855,
    "usd_market_cap": 899778830697.9833,
    "usd_24h_vol": 31594065643.74485,
    "usd_24h_change": -0.3182066441998838
  },
  "ABTC": {
    "usd": 47855,
    "usd_market_cap": 899778830697.9833,
    "usd_24h_vol": 31594065643.74485,
    "usd_24h_change": -0.3182066441998838
  },
  "WBTC": {
    "usd": 47855,
    "usd_market_cap": 899778830697.9833,
    "usd_24h_vol": 31594065643.74485,
    "usd_24h_change": -0.3182066441998838
  }
}

// Hook
export const useMetamask = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const [connecting, setConnecting] = useState(false)
  const [walletAddr, setWalletAddr] = useState('')
  const [networkName, setNetworkName] = useState('')
  const [networkId, setNetworkId] = useState(1)
  const [web3ConnectProvider, setWeb3ConnectProvider] = useState()
  const [walletBalance, setWalletBalance] = useState(balance_template)
  const [marketData, setMarketData] = useState(coingecko_data)
  const walletAddrRef = useRef(null)
  const networkNameRef = useRef(null)
  const networkIdRef = useRef(null)
  const walletBalanceRef = useRef(null)
  const web3ConnectProviderRef = useRef(null)
  walletAddrRef.current = walletAddr
  networkNameRef.current = networkName
  networkIdRef.current = networkId
  walletBalanceRef.current = walletBalance
  web3ConnectProviderRef.current = web3ConnectProvider

  const providerOptions = {
    "custom-metamask": {
      display: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg",
        name: "MetaMask Wallet",
        description: "Connect to your MetaMask Wallet"
      },
      package: true,
      connector: async () => {
          if (!window?.ethereum?.isMetaMask) {
            window.location = "https://metamask.app.link/dapp/www.ethbox.org/app/"; // <-- LOOK HERE
              return;
          }
  
          let provider = null;
          if (typeof window.ethereum !== 'undefined') {
            let providers = window.ethereum.providers;
            try {
              provider = providers.find(p => p.isMetaMask);
              await provider.request({ method: 'eth_requestAccounts' });
            } catch (error) {
              try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                provider = await detectEthereumProvider();
              } catch (error) {
                throw new Error("User Rejected");
              }
            }
          } else {
              throw new Error("No MetaMask Wallet found");
          }
          return provider;
      }
    },
    "Kaikas": {
      display: {
        logo: "https://www.gitbook.com/cdn-cgi/image/width=40,hei…png%3Fgeneration%3D1602748674315338%26alt%3Dmedia",
        name: "Kaikas Wallet",
        description: "Connect to your Kaikas Wallet"
      },
      package: true,
      connector: async () => {
        if (!window?.klaytn?.isKaikas) {
          window.location = "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi"; // <-- LOOK HERE
            return;
        }

        let provider = null;
        if (typeof window.klaytn !== 'undefined') {
          try {
              await window.klaytn.enable()
              provider = window['klaytn']
              //console.log(provider);
              provider = await detectEthereumProvider();
              //console.log(provider);
          } catch (error) {
              throw new Error("User Rejected");
          }
        } else {
          throw new Error("No Kaikas Wallet found");
        }
        return provider;
      }
    },
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: process.env.REACT_APP_INFURA_ID // required
      }
    },
    fortmatic: {
      package: Fortmatic, // required
      options: {
        key: process.env.REACT_APP_FORTMATIC_KEY // required
      }
    },
    portis: {
      package: Portis, // required
      options: {
        id: process.env.REACT_APP_PORTIS_ID // required
      }
    },
    'custom-walletlink': {
      display: {
        logo: 'https://raw.githubusercontent.com/walletlink/walletlink/4ebd71b9bd53b89c1a0e52a3a05148eb981c0afa/web/src/images/wallets/coinbase-wallet.svg', // Path to wallet link logo. Source 
        name: 'WalletLink',
        description: 'Scan with WalletLink to connect',
      },
      options: {
        appName: 'Compass', // Your app name
        networkUrl: `https://${process.env.REACT_APP_CHAIN_ID == 42 ? 'kovan' : 'mainnet'}.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
        chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
      },
      package: WalletLink,
      connector: async (_, options) => {
        const { appName, networkUrl, chainId } = options
        const walletLink = new WalletLink({
          appName
        })
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId)
        await provider.enable()
        return provider
      },
    },
  }

  const calculateWalletBalance = async (address) => {
    let prevWalletBalance = {...walletBalanceRef.current}
    if(!web3ConnectProviderRef.current) {
        return
    }
    const provider = new ethers.providers.Web3Provider(web3ConnectProviderRef.current)
    const balance = await provider.getBalance(address)
    const precision = new BigNumber('1000000000000000000')
    prevWalletBalance['ETH'] = new BigNumber(balance.toString()).dividedBy(precision)

    const tokens = Object.keys(tokenList)
    for (const token of tokens) {
      // if (token !== 'ETH' && token !== 'BTC') {
      //   let targetContract = new ethers.Contract(tokenList[token].address, tokenList[token].abi, provider)
      //   const balance = (await targetContract.balanceOf(address)).toString()
      //   prevWalletBalance[token] = new BigNumber(balance).dividedBy(new BigNumber(10).pow(tokenList[token].decimal))
      // }
    }

    setWalletBalance(prevWalletBalance)
  }

  const disconnectWallet = async() => {
    setWalletAddr('')
    setWalletBalance(balance_template)
  }

  const connectWallet = async () => {
    try {
      setConnecting(true)
      if (walletAddr.length > 0) {
        setConnecting(false)
        return
      }
      // await window.ethereum.enable()
      // Connect to the network
      const web3Modal = new Web3Modal({
        network: process.env.REACT_APP_CHAIN_ID == 42 ? 'kovan' : 'mainnet',
        cacheProvider: false,
        disableInjectedProvider: true,
        providerOptions
      })
      web3Modal.clearCachedProvider()
      const web3ModalProvider = await web3Modal.connect()
      setWeb3ConnectProvider(web3ModalProvider)

      // const provider = ethers.getDefaultProvider('kovan')
      const provider = new ethers.providers.Web3Provider(web3ModalProvider)

      // Subscribe to accounts change
      web3ModalProvider.on("accountsChanged", (accounts) => {
      })

      // Subscribe to chainId change
      web3ModalProvider.on("chainChanged", async (chainId) => {
        let realChainId = chainId;
        if (realChainId.indexOf('0x') >= 0) {
          realChainId = parseInt(chainId, 16);
        }
        const currentNetwork = await provider.getNetwork()
        setNetworkName(currentNetwork.name)
        setNetworkId(realChainId)
        // if (realChainId == process.env.REACT_APP_CHAIN_ID) {
          const signer = provider.getSigner()
          const walletAddress = await signer.getAddress()
          await calculateWalletBalance(walletAddress)
          setWalletAddr(walletAddress)
        // } else {
        //   toast.error(ERROR_TEXT['7'].replace('<source>', process.env.REACT_APP_CHAIN_ID == 1 ? 'Mainnet' : 'Kovan testnet'), {
        //     position: "top-right",
        //     autoClose: 10000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //   })
        //   setWalletAddr('')
        // }
      })

      // Subscribe to provider connection
      web3ModalProvider.on("connect", async (info) => {
        let realChainId = info.chainId;
        if (realChainId.indexOf('0x') >= 0) {
          realChainId = parseInt(info.chainId, 16);
        }
        setNetworkName(info.name)
        setNetworkId(realChainId)
        // if (realChainId == process.env.REACT_APP_CHAIN_ID) {
          const signer = provider.getSigner()
          const walletAddress = await signer.getAddress()
          await calculateWalletBalance(walletAddress)
          setWalletAddr(walletAddress)
        // } else {
        //   toast.error(ERROR_TEXT['7'].replace('<source>', process.env.REACT_APP_CHAIN_ID == 1 ? 'Mainnet' : 'Kovan testnet'), {
        //     position: "top-right",
        //     autoClose: 10000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //   })
        //   setWalletAddr('')
        // }
      })

      // Subscribe to provider disconnection
      web3ModalProvider.on("disconnect", (error) => {
        console.log('disconnect', error)
        setWalletAddr('')
        setNetworkName('')
        setNetworkId(1)
        setWeb3ConnectProvider(null)
      })
      
      const currentNetwork = await provider.getNetwork()
      setNetworkName(currentNetwork.name)
      setNetworkId(currentNetwork.chainId)
      // if (currentNetwork.chainId == process.env.REACT_APP_CHAIN_ID) {
        const signer = provider.getSigner()
        const walletAddress = await signer.getAddress()
        await calculateWalletBalance(walletAddress)
        setWalletAddr(walletAddress)
      // } else {
      //   toast.error(ERROR_TEXT['7'].replace('<source>', process.env.REACT_APP_CHAIN_ID == 1 ? 'Mainnet' : 'Kovan testnet'), {
      //     position: "top-right",
      //     autoClose: 10000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     pauseOnHover: false,
      //     draggable: true,
      //     progress: undefined,
      //   })
      //   setWalletAddr('')
      // }
    } catch (error) {
      console.log('main', error)
    } finally {
      setConnecting(false)
    }
  }

  const updateWalletAddr = (addr) => {
    setWalletAddr(addr)
  }

  const fetchCoingeckoAPI = async () => {
    const res = await Axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Ctether%2Cwrapped-bitcoin&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true`,
      { headers: { 'Content-Type': 'Application/json' }})
    if (res && res.data) {
      let market_data = {}
      market_data.USDT = res.data.tether
      market_data.AUSDT = res.data.tether
      market_data.ETH = res.data['ethereum']
      market_data.AETH = res.data['ethereum']
      market_data.WETH = res.data['ethereum']
      market_data.BTC = res.data['bitcoin']
      market_data.ABTC = res.data['bitcoin']
      market_data.WBTC = res.data['wrapped-bitcoin']
      setMarketData(market_data)
    }
  }

  useEffect(() => {
    // Handler to call on window resize
    // connectWallet()
    const interval = setInterval(async () => {
      if (walletAddrRef.current && walletAddrRef.current.length > 0 && web3ConnectProviderRef.current !== null) {
        try {
          // Connect to the network
          const provider = new ethers.providers.Web3Provider(web3ConnectProviderRef.current)
          const currentNetwork = await provider.getNetwork()
  
          if (currentNetwork.chainId == process.env.REACT_APP_CHAIN_ID) {
            await calculateWalletBalance(walletAddrRef.current)
          }
        } catch (error) {
          
        }
      }
      fetchCoingeckoAPI()
    }, 10000)

    return () => clearInterval(interval)
  }, []) // Empty array ensures that effect is only run on mount

  return {
    walletAddr,
    networkId,
    networkName,
    web3ConnectProvider,
    marketData,
    walletBalance,
    connecting,
    updateWalletAddr,
    connectWallet,
    disconnectWallet,
  }
}
