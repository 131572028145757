import { createContext } from 'react';

export const AuthContext = createContext(null);

export const MetamaskContext = createContext(null);

export const ThemeContext = createContext(null);

export const KaikasContext = createContext(null);

export const Web3PluginContext = createContext(null);