import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const WaitTimerContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelText = styled.div`
    color: ${props => props.theme.colors.warning};
    font-family: Poppins;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 6px 0 8px;
    display: flex;
    flex-direction: row;
`

const WaitTimer = ({ time }) => {
    const [timerText, setTimerText] = useState('')
    
    useEffect(() => {
        const interval = setInterval(() => {
            let diffSec = moment().diff(time, 'second', false)
            if (diffSec >= 0) {
                setTimerText(' ');
            } else {
                diffSec *= -1;
                const seconds = diffSec % 60;
                const minutes = (diffSec - seconds) / 60;

                setTimerText(`You can ask new code after ${minutes > 0 ? `${minutes} minutes and ` : ''} ${seconds} seconds`)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [time])

    return (
        <WaitTimerContainer>
            <LabelText>
                {timerText}
            </LabelText>
        </WaitTimerContainer>
    )
};

export default WaitTimer;

