import { useState, useEffect, useRef, useContext } from 'react'
import caver from '../util/caver'
import BigNumber from 'bignumber.js'
import Axios from 'axios'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { ERROR_RESPONSE, ERROR_TEXT } from '../util';

const CARA_STATUS = {
    0 : 'PENDING',
    1 : 'INDEXED',
    2 : 'RELEASED',
    3 : 'FAILED'
}

const NEW_WALLET = {
    address : '',
    network : '',
    caraStatus : '',
    balance : '',
    primary : false
}
// Hook
export const useKaikas = () => {


    const [connecting, setConnecting] = useState(false)
    const [walletAddr, setWalletAddr] = useState('')
    const [networkName, setNetworkName] = useState('')
    const [networkId, setNetworkId] = useState('')
    const [web3ConnectProvider, setWeb3ConnectProvider] = useState()
    const [walletBalance, setWalletBalance] = useState()
    const walletAddrRef = useRef(null)
    const networkNameRef = useRef(null)
    const networkIdRef = useRef(null)
    const walletBalanceRef = useRef(null)
    const web3ConnectProviderRef = useRef(null)
    const { klaytn } = window
    let location = useLocation()
    walletAddrRef.current = walletAddr
    networkNameRef.current = networkName
    networkIdRef.current = networkId
    walletBalanceRef.current = walletBalance
    web3ConnectProviderRef.current = web3ConnectProvider
    const userStorage = JSON.parse(localStorage.getItem('user-data'))

    useEffect(() => {
        if (sessionStorage.getItem('connected_wallet') === ''){
            sessionStorage.setItem('connected_wallet', walletAddr)
            sessionStorage.setItem('balance', '0')
            sessionStorage.setItem('network', networkName)
        } else {
            updateWalletAddr(sessionStorage.getItem('connected_wallet'))
            updateNetwork(sessionStorage.getItem('network'))
            updateBalance(sessionStorage.getItem('balance'))
        }
    }, [])

    useEffect(() => {
        async function checkValid() {
            try {
                if (location.pathname === '/dashboard' && walletAddr) {
                    const validResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/wallet/verify/${walletAddr}`,
                      { headers: { 'Content-Type': 'Application/json', Authorization: `Bearer ${userStorage.authToken}` }})
                    if (validResponse.data && validResponse.data.walletIsValid) {
                        toast.success('Wallet connected successfully', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        })
                    } else {
                        toast.error('This Wallet is associated with another user. Please connect your wallet.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        })
                        sessionStorage.setItem('connected_wallet', '')
                        sessionStorage.setItem('network', '')
                        sessionStorage.setItem('balance', '0')
                        updateWalletAddr('')
                        updateBalance(0)
                        updateNetwork('')
                    }
                }

            } catch (error) {
                await handleError(error)
            }


        }
        checkValid().then()
    }, [walletAddr])

    // useEffect(() => {
    //     async function enableKlaytn(){
    //         await klaytn.enable()
    //     }
    //     enableKlaytn().then(()=>{console.log("Enabled Klaytn")})
    // }, [])

    const handleError = async  (error) => {
        if (error.response && error.response.data.message) {
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }


    }

    const enableKlaytn = async () => {
        await klaytn.enable()
    }


    const loadAccountInfo = async () => {
        // const { klaytn } = window
        // console.log(klaytn)
        await enableKlaytn()
        if (klaytn) {
            try {
                // await klaytn.enable()
                await setAccountInfo(klaytn)
                klaytn.on('accountsChanged', () => setAccountInfo(klaytn))
            } catch (error) {
                console.log('User denied account access')
            }
        } else {
            console.log('Non-Kaikas browser detected. You should consider trying Kaikas!')
        }
    }

    const setAccountInfo = async () => {
        try{
            enableKlaytn().then(setNetworkInfo)
            // setNetworkInfo()
            // const { klaytn } = window
            // await klaytn.enable()
            if (klaytn === undefined) return

            // console.log("Setting info")
            const account = klaytn.selectedAddress
            // console.log(account)
            let balance = 0
            if (account) {
                balance = (parseInt(await caver.klay.getBalance(account))/1000000000000000000).toString()
                // console.log(balance)
            }
            // const balance = (parseInt(await caver.klay.getBalance(account))/1000000000000000000).toString()
            // console.log(balance)
            // const balance = 0
            // console.log(typeof account)
            setWalletAddr(account)
            setWalletBalance(balance)
            // setWalletBalance(caver.utils.fromPeb(balance, 'KLAY'))
            // console.log(balance)
            // console.log(walletAddrRef)
            // console.log(walletAddr)
            sessionStorage.setItem('connected_wallet', account)
            sessionStorage.setItem('balance', balance.toString())

        } catch (e) {
            console.log(e)
        }


    }

    const connectWallet = async () => {
        try {

            setConnecting(true)
            if (walletAddr && walletAddr.length > 0) {
                setConnecting(false)
                return
            }
            await enableKlaytn().then(loadAccountInfo)
        } catch (error) {
            console.log('main', error)
        } finally {
            setConnecting(false)
        }
    }

    const disconnectWallet = async() => {
        setWalletAddr('')
        setWalletBalance(0)
        sessionStorage.setItem('connected_wallet', '')
        sessionStorage.setItem('balance', '0')
        sessionStorage.setItem('network', '')
    }

    const updateWalletAddr = (addr) => {
        setWalletAddr(addr)
    }

    const updateNetwork = (network) => {
        setNetworkName(network)
    }

    const updateBalance = (balance) => {
        setWalletBalance(balance)
    }

    const setNetworkInfo = () => {
        // const { klaytn } = window
        if (klaytn === undefined) return
        setNetworkId(klaytn.networkVersion)
        if (klaytn.networkVersion === 1001) {
            setNetworkName('Baobab Test Network')
            sessionStorage.setItem('network', 'Baobab Test Network')
        } else if (klaytn.networkVersion === 8217) {
            setNetworkName('Cypress Main Network')
            sessionStorage.setItem('network', 'Cypress Main Network')
        }
        // this.setState({ network: klaytn.networkVersion })
        klaytn.on('networkChanged', () => setNetworkInfo(klaytn.networkVersion))
    }

    return {
        walletAddr,
        networkId,
        networkName,
        walletBalance,
        connecting,
        updateWalletAddr,
        connectWallet,
        disconnectWallet,
        updateBalance,
        updateNetwork,
    }
}