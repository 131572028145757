import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, KaikasContext } from '../../contexts';
import { useWindowSize } from '../../hooks/useWindowSize';
import styled from 'styled-components';

const Container = styled.div(()=>({
  height: '100%',
  width: '100%',
  //padding: '40%',
  // paddingLeft: '20%',
  textAlign: 'center',
  marginTop: '20%',

  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: 'bold'
}))

const ZignSecComplete = () => {

  const [status, setStatus] = useState('failed')

  useEffect(() => {
    const searchParams = window.location.search
    const params = new URLSearchParams(searchParams)
    if (params.get('status') === 'success') {
      setStatus('success')
    }
  }, []);

  return (
    <Container>
      {
        status === 'success' ?
          'KYC Request is successfully submitted. It can take upto 5 min for us to process the request. Please close this window'
          :
          'KYC Request Failed. Please close this window and verify again.'
      }
    </Container>
  )
};

export default ZignSecComplete;