/* eslint-disable no-control-regex */
import AUSDT_ABI from '../container/ABI/AUSDT.json'
import USDT_ABI from '../container/ABI/USDT.json'
import WBTC_ABI from '../container/ABI/WBTC.json'
import ABTC_ABI from '../container/ABI/ABTC.json'
import WETH_ABI from '../container/ABI/WETH.json'
import AETH_ABI from '../container/ABI/AETH.json'
import TEST_ABI from '../container/ABI/TEST.json'

import ABTCImg from '../asset/img/ic_abtc.png'
import USDTImg from '../asset/img/ic_usdt.png'
import AUSDTImg from '../asset/img/ic_ausdt.png'
import WBTCImg from '../asset/img/ic_wrappedbtc.png'
import ETHImg from '../asset/img/ic_eth.png'
import WETHImg from '../asset/img/ic_wrappedeth.png'
import AETHImg from '../asset/img/ic_aeth.png'
import BTCImg from '../asset/img/ic_btc.png'

export const emailValidator = (email) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);

export const codeValidator = (code) => code.length === 6;

export const passwordValidator = (password) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(password);

export const WindowSize = {
    tablet: '@media(max-width: 850px)',
    ipad: '@media(max-width: 750px)',
    mobile: '@media(max-width: 560px)',
}

export const LightColors = {
    black: '#000000',
    black1: '#212529',
    white: '#FFFFFF',
    white1: '#F1F3F5',
    white2: '#E9ECEF',
    white3: '#F8F9FA',
    white4: '#ADB5BD',
    whiteHover: '#E5DBFF',
    pinkHover: '#4421CE',
    pink: '#653EFF',
    boxShadow: '#DEE2E6',
    gray1: '#868E96',
    gray2: '#495057',
    gray3: '#CED4DA',
    gray4: '#ADB5BD',
    transparent: '#0000',
    blueButton: '#364FC7',
    blueHeader: '#4C6EF5',
    blueBorder: '#195DFB',
    blueText: '#3B5BDB',
    blueBackground: '#DBE4FF',
    red: '#F00',
    warning: '#FA5252'
}

export const DarkColors = {
    black: '#000000',
    black1: '#212529',
    white: '#FFFFFF',
    white1: '#F1F3F5',
    white2: '#E9ECEF',
    white3: '#F8F9FA',
    white4: '#ADB5BD',
    whiteHover: '#E5DBFF',
    pinkHover: '#4421CE',
    pink: '#653EFF',
    boxShadow: '#DEE2E6',
    gray1: '#868E96',
    gray2: '#495057',
    gray3: '#CED4DA',
    gray4: '#ADB5BD',
    transparent: '#0000',
    blueButton: '#364FC7',
    blueHeader: '#4C6EF5',
    blueBorder: '#195DFB',
    blueText: '#3B5BDB',
    blueBackground: '#DBE4FF',
    red: '#F00',
    warning: '#FA5252'
}

export const truncate = (fullStr, strLen, separator) => {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) + 
           separator + 
           fullStr.substr(fullStr.length - backChars);
};

export const tokenList = {
    'ETH': {
        address: '0x0000000000000000000000000000000000000000',
        abi: WETH_ABI,
        decimal: 18,
        symbol: 'ETH',
        name: 'Ethereum',
        icon: ETHImg,
        swapTokens: [
            'AETH'
        ]
    },
    'WETH': {
        address: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
        abi: WETH_ABI,
        decimal: 18,
        symbol: 'WETH',
        name: 'Wrapped Ethereum',
        icon: WETHImg,
        swapTokens: [
            'AETH'
        ]
    },
    'AETH': {
        address: '0x4AF127834e242d2cBf72e3Ae83Cf0F4FdD7db9E9',
        abi: AETH_ABI,
        decimal: 18,
        symbol: 'AETH',
        name: 'Assured Ethereum',
        icon: AETHImg,
        swapTokens: [
            'ETH',
            'WETH'
        ]
    },
    'USDT': {
        address: '0x13512979ADE267AB5100878E2e0f485B568328a4',
        abi: USDT_ABI,
        decimal: 6,
        symbol: 'USDT',
        name: 'Tether',
        icon: USDTImg,
        swapTokens: [
            'AUSDT'
        ]
    },
    'AUSDT': {
        address: '0xF350207672A7B8631e3035BF8d9f7fe73D54D589',
        abi: AUSDT_ABI,
        decimal: 6,
        symbol: 'AUSDT',
        name: 'Assured Tether',
        icon: AUSDTImg,
        swapTokens: [
            'USDT'
        ]
    },
    'BTC': {
        address: '',
        abi: AETH_ABI,
        decimal: 18,
        symbol: 'BTC',
        name: 'Bitcoin',
        icon: BTCImg,
        swapTokens: [
            'ABTC'
        ]
    },
    'WBTC': {
        address: '0xd3a691c852cdb01e281545a27064741f0b7f6825',
        abi: WBTC_ABI,
        decimal: 8,
        symbol: 'WBTC',
        name: 'Wrapped BTC',
        icon: WBTCImg,
        swapTokens: [
            'ABTC'
        ]
    },
    'ABTC': {
        address: '0x1b52f26360FfA81fBBC7be71f1CD0cf13e44669e',
        abi: ABTC_ABI,
        decimal: 8,
        symbol: 'ABTC',
        name: 'Assured BTC',
        icon: ABTCImg,
        swapTokens: [
            'WBTC',
            'BTC'
        ]
    },
    // 'TEST': {
    //     address: '0x1FB879581f31687B905653D4bBCBE3af507Bed37',
    //     abi: TEST_ABI,
    //     decimal: 0,
    //     swapTokens: [
    //         'AUSDT'
    //     ]
    // },
}

export const ERROR_RESPONSE = {
    0: "Session expired. Please login again"
}

export const ERROR_TEXT = {
    0: "Your Wallet address verification is still in progress. Please wait until it is completed.",
    1: "Your Wallet address identified as High-Risk from our verification.",
    2: "You need perform the Wallet verification before proceeding. Click Here to proceed for wallet verification.",
    3: "You need perform the Account verification before proceeding. Click here to proceed for account verification.",
    // 0 or negative amount to swap
    4: "Invalid amount. Please check if you have enter the correct amount.",
    5: "Please connect your wallet to the platform. Click here to connect your wallet.",

    // Wallet balance insufficient
    6: "Insufficient Balance in your wallet for the <source> token. Please check if you have enter the correct amount.",
    7: "Invalid network selected. Please select <source>.",
    // User changed wallet manually using his metamask or other providers
    8: "Invalid wallet selected. Please check if you have select the correct wallet.",
    9: "BTC Token withdrawal is not supported yet.",
    // Wrong route
    10: "We are sorry, the content you trying to access is not available.",
    // Verification code api failed
    11: "We are sorry that email Verification code did not send. Please try again.",
    // Sign In form
    12: "Invalid email address. Please check if you have the correct email address.",
    13: "Password is too weak. Must be at least 8 characters long with a capital letter, a digit and a special character",
    // Reset password
    14: "Invalid email address. Please check if you have the correct email address.",

    15: "Password reset failed. Please try again.",
    // Sign Up
    // password and confirm password compare
    16: "Password does not match. Please check if you have the correct password filled.",
    17: "The is a user already exists with the same email address. Please check if you have the correct email address or contact our support team.",
    18: "Email or password is wrong. Please check if you have the correct email address and/or password.",
    19: "User not found",
    20: "Email Verification Code did not match. Please check if you have the correct code.",
    // Cara verification
    21: "Wallet assigned to other user. This wallet has been associated with another email id. Please check if you have the correct email address or contact our support.",
    22: "Wallet verification failed. We are sorry that your wallet verification did not succeed. Please contact our support team.",
    23: "Session Timed out due to inactivity. Please login again"
}
